import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router';
import { HaftalikDersProgramiTumDersler } from 'src/app/models/HaftalikDersProgram.model';
import { ValidationService } from 'src/app/services/validation.service';
import { finalize } from 'rxjs';


@Component({
  selector: 'dersprogram-tumders',
  templateUrl: './dersprogram-tumders.component.html',
  styleUrls: ['./dersprogram-tumders.component.css']
})

export class DersProgramTumDersComponent implements OnInit {

  constructor(private dersprogramService: HaftalikDersProgramService,
              public yetkiService: YetkiService,
              private validationService: ValidationService,
              private route: ActivatedRoute
              ) {
    this.service = dersprogramService;
   }

  yukleniyor=false;
  service: HaftalikDersProgramService;
  dersprogramTumDersList: HaftalikDersProgramiTumDersler[] = [];
  haftaID: number = 0;
  yilhafta: string = '';
  donemID: number = 0;
  akademisyen_tc: string = '';
  hatalar: any = {};

  izinguncelleniyor = false;

  @ViewChild('grid') grid?: GridComponent;


  izinGuncelle(){

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['akademisyen_tc']) {
        this.akademisyen_tc = params['akademisyen_tc'];
        this.route.queryParams.subscribe(params => {
          if (params['hafta_id']) {
            this.haftaID = params['hafta_id'];
            this.yilhafta = params['yilhafta'];
            this.donemID = params['donem_id'];
            this.GetListByTcTumDersler(this.akademisyen_tc, params['hafta_id'], params['yilhafta'], params['donem_id']);
          }
        });
      }
    });
  }

  GetListByTcTumDersler(akademisyentc: string, haftaid: number, yilhafta: string ='', donem: number) {
    this.yukleniyor = true;
    /*this.service.getAllTumDersler(akademisyentc, haftaid, yilhafta, donem).subscribe(respone => {
      this.dersprogramTumDersList = respone.data;
      this.yukleniyor = false;
    });
    */

    this.service.getAllTumDersler(akademisyentc, haftaid, yilhafta, donem).pipe(finalize(() => {
      this.yukleniyor = false;
    })).subscribe({
      next: res => {
        this.dersprogramTumDersList = res.data;
        if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.error(err.error?.message, 1) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.error(err.error?.Message, 1) };
        }
      }
    });
  }

  exportexcel(fileName: string,  tableName: string): void {
    var element = document.getElementById(tableName);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
