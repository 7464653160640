import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { GenelService } from 'src/app/services/genel.service';
import { BirimService } from 'src/app/services/birim.service';
import { AkademikTakvimService } from 'src/app/services/akademiktakvim.service';
import { Cizelge, CizelgeParam } from 'src/app/models/Cizelge.model';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';
import { AkademikTakvimDto } from 'src/app/models/AkademikTakvim.model';
import { DatePipe } from '@angular/common';
import { HaftaDto } from 'src/app/models/Haftalar.model';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs';
import { ValidationService } from 'src/app/services/validation.service';
import { Router } from '@angular/router';


@Component({
  selector: 'cizelge',
  templateUrl: './cizelge.component.html',
  styleUrls: ['./cizelge.component.css']
})

export class CizelgeComponent implements OnInit {

  constructor(private cizelgeService: HaftalikDersProgramService,
              private birimService: BirimService,
              public yetkiService: YetkiService,
              private genelService: GenelService,
              private validationService: ValidationService,
              private akademiktakvimService: AkademikTakvimService,
              private formBuilder: FormBuilder,
              private router: Router,
              private datepipe: DatePipe
              ) {
    this.service = cizelgeService;
   }

   listForm = this.formBuilder.group({
    cizelgeForm: this.formBuilder.group(new Cizelge()),
  });

  cizelgeForm(): FormGroup {
    return this.listForm.get('cizelgeForm') as FormGroup;
  }

  yukleniyor = false;
  kaydediliyor = false;
  hatalar: any = {};
  service: HaftalikDersProgramService;
  cizelgeList: Cizelge[] = [];
  cizelgeParam: CizelgeParam = new CizelgeParam();
  birimListDto: BirimlerDto[] = [];
  birimParam: BirimParam = new BirimParam();
  birimDto: any[] = [];
  bolumDto: any[] = [];
  programDto: any[] = [];
  birimID: number = 0;
  birimAdi: string = '';
  birimBolumAd: string = '';
  donemAdi: string = '';
  donemID: number = 0;
  bolumID: number = 0;
  bolumAdi: string = '';
  programID: number = 0;
  haftaDto: any[] = [];
  haftaID: number = 0;
  yilhafta: string = '';
  haftaAciklama: string = '';
  akademikTakvimDto: AkademikTakvimDto[] = [];
  ilktarih : any; //'19.09.2022';
  sontarih : any; //'30.12.2022';
  onaySeclist : any[] = [];
  gridSeclist : any[] = [];
  selectedAll: boolean = false;

  birimHata: string | null = '';
  bolumHata: string | null = '';
  haftaHata: string | null = '';

  secilibirim : BirimlerDto |undefined;
  secilibolum : BirimlerDto |undefined;
  seciliprogram : BirimlerDto |undefined;
  secilihafta : HaftaDto |undefined;


  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.BirimleriGetir();
    this.onaySeclist = ['E','O','R'];
  }

  GetListByCizelge() {
    let hataBirim = this.secilibirim == undefined ? 'Lütfen Birim Seçiniz.' : null;
    let hataBolum = this.secilibolum == undefined ? 'Lütfen Bölüm Seçiniz.' : null;
    let hataHafta = this.secilihafta == undefined ? 'Lütfen Hafta Seçiniz.' : null;
    this.birimHata = hataBirim;
    this.bolumHata = hataBolum;
    this.haftaHata = hataHafta;
    if ((!this.birimHata) && (!this.bolumHata) && (!this.haftaHata)) {
      this.yukleniyor = true;
      this.cizelgeParam.birim_id = this.birimID.toString();
      this.cizelgeParam.bolum_id = this.bolumID.toString();
      this.cizelgeParam.program_id = this.programID.toString();
      this.cizelgeParam.onaylist = this.onaySeclist;
      this.cizelgeParam.yilhafta = this.yilhafta;
      this.cizelgeParam.donem_id = this.donemID.toString();
      //console.log(this.cizelgeParam);
      this.service.getAllCizelgeList(this.cizelgeParam).subscribe(respone => {
        this.cizelgeList = respone.data;
        this.gridSeclist = [];
        this.gridSeclist.push('0'); // Tekli çizelge hata verince geçici çözüm üretildi.

        this.cizelgeList.forEach(e => {
          this.gridSeclist.push(e.akademisyen_tc);
          this.donemAdi = e.donem_adi;
        });
        console.log(this.gridSeclist);
        this.yukleniyor = false;

      });
    }
  }

  Onayla(akademisyentc: string = '', donem: number = 0) {
    Swal.fire({
      title: 'Bu akademisyene ait ders programını onaylamak istediğinize emin misiniz ?',
      text: 'ONAY İŞLEMİ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Onayla',
      cancelButtonText: 'Vazgeç',
    }).then((result) => {
      if (result.value) {
        this.HaftalikDersProgramOnayByTC(akademisyentc, 'O', donem);
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Vazgeçildi", "Onay yapılmadı", 'error')
      }
    })
  }

  OnayKaldir(akademisyentc: string = '', donem: number = 0) {
    Swal.fire({
      title: 'Bu akademisyene ait ders programının onayını kaldırmak istediğinizden emin misiniz ?',
      text: 'ONAY KALDIR',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Kaldır',
      cancelButtonText: 'Vazgeç',
    }).then((result) => {
      if (result.value) {
        this.HaftalikDersProgramOnayByTC(akademisyentc, 'H', donem);
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Vazgeçildi", "Onay kaldırılmadı", 'error')
      }
    })
  }

  HaftalikDersProgramOnayByTC(akademisyenTC: string = '', onay: string = '', donem: number = 0) {
    this.yukleniyor = true;
    this.hatalar = {};
    this.service.multiUpdate(akademisyenTC, onay, donem).pipe(finalize(() => {
      this.yukleniyor = false;
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "" ) {this.yetkiService.success(res?.message,1)};
        this.GetListByCizelge();
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined ||err.error.Errors == null ||err.error.Errors?.length==0){
          if (err.error?.message && err.error?.message != "" ) {this.yetkiService.error(err.error?.message,1)};
          if (err.error?.Message && err.error?.Message != "" ) {this.yetkiService.error(err.error?.Message,1)};
        }
      }
    });
  }

  BirimleriGetir() {
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19,44,20]; //2,6,10,19,44,20 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul, Koordinatörlük, Formasyon
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      this.birimDto = respone.data;
      this.birimID = 0;
      this.bolumID = 0;
      this.programID = 0;
      this.haftaID = 0;
      this.donemID = 0;
    });
  }

  BolumGetir(item: BirimlerDto) {
    this.secilibirim = item;
    if (item!=undefined){
      this.birimAdi = item.birimadi;
      this.birimBolumAd = item.birimadi;
      this.birimID = item.birimid;
      this.birimParam.ustbirimid = item.birimid ?? 0;
      this.birimParam.tip = 'Bolum';
      this.birimParam.durum = [];
      this.birimParam.birimtur = [];
      this.HaftalariGetir(this.birimID);
      this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
        if (item.birimid != 0) {
          if (respone.data.length > 0) {
            this.bolumDto = respone.data;
          } else {
            this.bolumDto = [];
            this.programDto = [];
          }
        }
        else {
          this.bolumDto = [];
          this.programDto = [];
        }
        this.bolumID = 0;
        this.programID = 0;
        this.birimHata = null;
      });
    }
    else {
      this.bolumDto = [];
      this.bolumID = 0;
      this.bolumAdi = '';
      this.programDto = [];
      this.programID = 0;
      this.birimID = 0;
      this.birimAdi = '';
      this.birimBolumAd = '';
      this.haftaID = 0;
    }
    this.secilihafta = undefined;
    this.secilibolum = undefined;
  }

  AnabilimdaliGetir(item: BirimlerDto) {
    this.secilibolum = item;
    if (item!=undefined){
      this.bolumAdi = item.birimadi;
      this.birimBolumAd = this.birimAdi +' / '+ item.birimadi;
      this.bolumID = item.birimid;
      this.birimParam.ustbirimid = item.birimid ?? 0;
      this.birimParam.tip = 'Program';
      this.birimParam.durum = [];
      this.birimParam.birimtur = [];
      this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
        if (item.birimid != 0) {
          if (respone.data.length > 0) {
            this.programDto = respone.data;
          } else {
            this.programDto = [];
          }
        }
        else {
          this.programDto = [];
        }
        this.programID = 0;
        this.bolumHata = null;
      });
    }
    else {
      this.programDto = [];
      this.programID = 0;
      this.bolumID = 0;
      this.bolumAdi = '';
      this.birimBolumAd = this.birimAdi;
    }
    this.seciliprogram = undefined;
  }

  AnaBilimDaliSecim(item: BirimlerDto) {
    this.seciliprogram = item;
    if (item!=undefined){
      this.birimBolumAd = this.birimAdi +' / '+ this.bolumAdi +' / '+ item.birimadi;
      this.programID = item.birimid;
      this.bolumHata = null;
    }
    else {
      this.programID = 0;
      this.birimBolumAd = this.birimAdi +' / '+ this.bolumAdi;
    }
  }

  HaftalariGetir(birim_id: number = 0) {
    this.akademiktakvimService.getAkademikTakvim(birim_id,true,false).subscribe(respone => {
      this.akademikTakvimDto = respone.data;
      this.akademikTakvimDto.forEach(e => {
        this.ilktarih = this.datepipe.transform(e.baslamaTarihi, 'dd.MM.yyyy');
        if ( e.enstituUzmanlikBitisTarihi==null && e.enstituUzmanlikBitisTarihi==undefined){
          this.sontarih = this.datepipe.transform(e.bitisTarihi, 'dd.MM.yyyy');
        } else {
          this.sontarih = this.datepipe.transform(e.enstituUzmanlikBitisTarihi, 'dd.MM.yyyy');
        }
        this.donemID = e.donemID;
      });
      this.genelService.getHaftaList('T',this.ilktarih, this.sontarih).subscribe(respone => {
        this.haftaDto = respone.data;
        this.haftaID = 0;
        if (respone.data.length > 0) {
            this.haftaDto.forEach(e => {
            if (e.bu_hafta == true)
            {
              this.haftaID = e.id;
            }
          });
        }
        else {
          this.haftaID = 0;
        }
      });
    });

  }

  YilHafta(item: HaftaDto) {
    this.secilihafta = item;
    if (item!=undefined){
      this.yilhafta = item.yil.toString() + (item.hafta_id.toString().length == 1 ? '0'+ item.hafta_id.toString() : item.hafta_id.toString());
      this.haftaAciklama = item.aciklama;
      this.haftaID = item.id;
      this.haftaHata = null;
    }
    else {
      this.yilhafta = '';
      this.haftaAciklama = '';
      this.haftaID = 0;
    }
  }

  onaylistSec(event: any, deger: string) {
    if (event.currentTarget.checked) {
      this.onaySeclist.push(deger)
    } else {
      this.onaySeclist.splice(this.onaySeclist.indexOf(deger), 1);
    }
  }

  secimlist(event: any, item: any) {
    if (event.currentTarget.checked) {
      var tc = item.hasOwnProperty('akademisyen_tc') ? item.akademisyen_tc : "0";
      if (!this.gridSeclist.includes(tc)) {
        this.gridSeclist.push(item.akademisyen_tc);
        console.log(this.gridSeclist);

      }
    } else {
      this.gridSeclist.splice(this.gridSeclist.indexOf(item.akademisyen_tc), 1);
      this.selectedAll = false;
    }
  }

  selectAll() {
    if (this.selectedAll == false) {
      for (var i = 0; i < this.cizelgeList.length; i++) {
        if (!this.gridSeclist.includes(this.cizelgeList[i].akademisyen_tc)) {
          this.gridSeclist.push(this.cizelgeList[i].akademisyen_tc);
        }
      }
      this.selectedAll = true;
    }
    else {
      this.gridSeclist = [];
      this.gridSeclist.push('0'); // Tekli çizelge hata verince geçici çözüm üretildi.
      this.selectedAll = false;
    }
  }

  exportexcel(fileName: string,  tableName: string): void {
    var element = document.getElementById(tableName);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

  newTabOpenLink(routerlink: string='', routerID: number=0){
    const url = this.router.serializeUrl(this.router.createUrlTree([routerlink,routerID],
          {
            queryParams: {tc_list: this.gridSeclist,
                birim_adi: this.birimBolumAd,
                donem_adi: this.donemAdi,
                yil_hafta: this.yilhafta,
                hafta_tanim: this.haftaAciklama}
          })
        );

    window.open(url, '_blank');
  }

}
