import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BirimlerDto,BirimParam } from '../models/Birimler.model';
import { Response } from '../models/response.model';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class BirimService {
  apiUrl = '/api/genel'

  constructor(private http: HttpClient) { }  
  

  getAllBirimList(birimParam: BirimParam): Observable<Response<BirimlerDto[]>> {   
    return this.http.post<Response<BirimlerDto[]>>(this.apiUrl + '/birimlerlist', birimParam);
  }

  
}
