import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'giris',
  templateUrl: './giris.component.html',
  styleUrls: ['./giris.component.css']
})
export class GirisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
