import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Token } from '../models/token.model';
import { GirisService } from '../services/giris.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private girisService:GirisService) { }

  /* intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //const auth = localStorage.getItem('auth');
    if (document.cookie) {

     // const auth = document.cookie.split('=')[1];
      if (this.girisService?.cookieByName()) {
        const token = JSON.parse(this.girisService?.cookieByName()) as Token;
        request = request.clone({
          setHeaders: { 'Authorization': 'Bearer ' + (JSON.parse(this.girisService?.cookieByName()) as Token).token }
        });
      }
    }
    return next.handle(request);
  }
 */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const auth = localStorage.getItem('auth');
    if (auth) {
      request = request.clone({
        setHeaders: { 'Authorization': 'Bearer ' + (JSON.parse(auth) as Token).token }
      });
    }
    return next.handle(request);
  }


}
