<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-map me-2 text-success" style="font-size: large;"></i>Telafi ve İptal İşlemleri
                        ({{akademisyen_AdSoyadUnvan}})
                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.Telafi.Ekle']) && ((yetkiService.yetkiVar(['EkDers.Akademisyen.HepsiniGorme'])) || 
                (!yetkiService.yetkiVar(['EkDers.Akademisyen.HepsiniGorme']) && (akademisyen_tc == girisService.girisBilgi().tckimlikno)))" 
                class="d-flex align-items-center gap-2 gap-lg-3">
                <a [routerLink]="['yeni']" [queryParams]="{donem_id: donemID, adsoyadunvan: akademisyen_AdSoyadUnvan}"
                    class="btn btn-sm btn-primary">
                    <i class="fas fa-plus"></i>
                    Telafi Ekle</a>

            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <div class="card" style="border-top: 3px #77c2e9 solid; background-color:white;">
                <div class="card-body" style="padding:1.5rem">
                    <loading [loader]="yukleniyor">
                        <div style="overflow:auto;width: 100%;">
                            <table class="table table-striped" id="bl_subscriptions_table">
                                <thead>
                                    <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 " style="color:#009ef7;">
                                        <th class="min-w-125px">İptal Edilen Ders Adı</th>
                                        <th class="min-w-125px">İptal Ders Tarihi</th>
                                        <th class="min-w-125px">Telafi Tarihi</th>
                                        <th class="min-w-125px">Başlama Saati</th>
                                        <th class="min-w-125px">Ders Saati</th>
                                        <th class="min-w-125px">Resmi Yazı Tarihi</th>
                                        <th *ngIf="yetkiService.yetkiVar(['EkDers.Telafi.Degistir', 'EkDers.Telafi.Sil'])" 
                                            class="text-end min-w-70px">İşlemler</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-600 fw-bold">
                                    <tr *ngFor="let item of telafilist" style="height: 30px;">
                                        <td>
                                            {{ item.ders_adi }}
                                        </td>
                                        <td>
                                            {{ item.iptal_ders_tarihi | date:'dd.MM.yyyy':'IST'}}
                                        </td>
                                        <td>
                                            {{ item.telafi_tarihi | date:'dd.MM.yyyy':'IST'}}
                                        </td>
                                        <td>
                                            {{ item.baslama_saati}}
                                        </td>
                                        <td>
                                            {{ item.ders_saati_sayisi }}
                                        </td>
                                        <td>
                                            {{ item.resmi_yazi_tarihi | date:'dd.MM.yyyy':'IST'}}
                                        </td>
                                        <td *ngIf="yetkiService.yetkiVar(['EkDers.Telafi.Degistir', 'EkDers.Telafi.Sil'])" class="text-end">
                                            <div class="d-flex justify-content-end flex-shrink-0">
                                                <a [routerLink]="['/telafi/'+ akademisyen_tc, item.id]" 
                                                    *ngIf="yetkiService.yetkiVar(['EkDers.Telafi.Degistir']) && (yetkiService.yetkiVar(['EkDers.Akademisyen.HepsiniGorme']) || 
                                                    (!yetkiService.yetkiVar(['EkDers.Akademisyen.HepsiniGorme']) && (akademisyen_tc == girisService.girisBilgi().tckimlikno)))" 
                                                    [queryParams]="{donem_id: donemID, adsoyadunvan: akademisyen_AdSoyadUnvan}"
                                                    style="background-color:#dcdce1;"
                                                    class="btn btn-icon border border-primary btn-hover-rise  btn-sm me-1">
                                                    <i class="fas fa-edit text-primary"></i>
                                                </a> 
                                                <button type="button" (click)="silModal(item)" 
                                                    *ngIf="yetkiService.yetkiVar(['EkDers.Telafi.Sil']) && (yetkiService.yetkiVar(['EkDers.Akademisyen.HepsiniGorme']) || 
                                                    (!yetkiService.yetkiVar(['EkDers.Akademisyen.HepsiniGorme']) && (akademisyen_tc == girisService.girisBilgi().tckimlikno)))" 
                                                    style="background-color:#dcdce1;" data-bs-toggle="modal"
                                                    class="btn btn-icon btn-active-color-danger btn-sm border border-primary btn-hover-rise"
                                                    data-bs-target="#sil_modal">
                                                    <i class="far fa-trash-alt text-danger"></i>
                                                </button>
                                            </div>                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </loading>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="sil_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog  modal-dialog-centered mw-900px">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">Kayıt Sil</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p *ngIf="silinecekTelafi">
                    {{ silinecekTelafi.ders_adi }} telafi kaydını silmek istediğinizden emin misiniz?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">İptal</button>
                <button type="button" class="btn btn-danger" (click)="sil(silinecekTelafi!)">Sil</button>
            </div>
        </div>
    </div>
</div>