export class AkademisyenIzinDto {    
    tc: string='';
    ad: string='';
    soyad: string='';    
    izinbastar: Date = new Date();
    izinbittar: Date = new Date();
    izintipi: string=''; 
    personelTipi: string=''; 
    personelAltTipi: string=''; 
    akunvan: string=''; 
    akunvankisa: string=''; 
    unvan: string=''; 
    unvankisa: string=''; 
    kadrobirim: string=''; 
    kadrobirimuzun: string='';    
}

export class AkademisyenIzinParam {
    kadrobirimid: number = 0; 
    calistigibirimid: number = 0;
    personeltc: string = ''; 
    adsoyad: string = '';    
    izinbaslagic: string = ''; 
    izinbitis: string = '';    
}

