import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AkademisyenGuncelleDto, AkademisyenGuncelleParam, AkademisyenlerDto, AkademisyenParam } from '../models/Akademisyenler.model';
import { Response } from '../models/response.model';
import * as XLSX from 'xlsx';
import { AkademisyenIzinDto, AkademisyenIzinParam } from '../models/AkademisyenIzinler.model';

@Injectable({
  providedIn: 'root'
})
export class AkademisyenService {
  apiUrl = '/api/genel'

  constructor(private http: HttpClient) { }

  /* getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<AkademisyenlerDto[]>> {
    return this.http.get<Response<AkademisyenlerDto[]>>(this.apiUrl + '/birimlerList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  } */

  getAllAkademisyenList(akademisyenParam: AkademisyenParam): Observable<Response<AkademisyenlerDto[]>> {

    return this.http.post<Response<AkademisyenlerDto[]>>(this.apiUrl + '/akademisyenlerlist', akademisyenParam);
  }

  getAkademisyenData(akademisyenParam: AkademisyenParam): Observable<Response<AkademisyenlerDto[]>> {

    return this.http.post<Response<AkademisyenlerDto[]>>(this.apiUrl + '/akademisyendata', akademisyenParam);
  }


  AkademisyenBirimGuncelle(guncelleParam: AkademisyenGuncelleParam): Observable<Response<AkademisyenGuncelleDto[]>> {

    return this.http.post<Response<AkademisyenGuncelleDto[]>>(this.apiUrl + '/akademisyenbirimguncelle', guncelleParam);
  }

  getAllAkademisyenIzinList(akademisyenIzinParam: AkademisyenIzinParam): Observable<Response<AkademisyenIzinDto[]>> {

    return this.http.post<Response<AkademisyenIzinDto[]>>(this.apiUrl + '/akademisyenizinlist', akademisyenIzinParam);
  }

  getPbsPersonelGuncelle(akademisyentc: string): Observable<Response<any>> {

    return this.http.post<Response<any>>(this.apiUrl + '/pbspersonelguncelle?akademisyentc='+akademisyentc, {});
  }



}
