import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'alt-menu',
  templateUrl: './alt-menu.component.html',
  styleUrls: ['./alt-menu.component.css']
})
export class AltMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
