import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'yonetim',
  templateUrl: './yonetim.component.html',
  styleUrls: ['./yonetim.component.css']
})
export class YonetimComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
