import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { ResmiTatil } from 'src/app/models/ResmiTatil.model';
import { ResmiTatilService } from 'src/app/services/resmitatil.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';

declare var bootstrap: any;

@Component({
  selector: 'resmitatil',
  templateUrl: './resmitatil.component.html',
  styleUrls: ['./resmitatil.component.css']
})
export class ResmiTatilComponent implements OnInit {

  constructor(private resmiTatilService: ResmiTatilService, public yetkiService: YetkiService) {
    this.service = resmiTatilService;  
  }
  
  service: ResmiTatilService;
  siliniyor = false;

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
  }

  resmiTatiller: ResmiTatil[] = [];

  silinecekResmiTatil: ResmiTatil | undefined;  

  silModal(resmiTatil: ResmiTatil) {
    this.silinecekResmiTatil = resmiTatil;
    /* Swal.fire({
      title: 'Tatil bilgisini silmek istediğinize emin misiniz ?',
      text: 'TATİL SİL',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'Vazgeç',
    }).then((result) => {
      if (result.value) {
        this.siliniyor = true;
        this.service.delete(resmiTatil).subscribe(response => {
          this.resmiTatilList();
          this.yetkiService.success("Tatil bilgileri başarıyla silindi.", 1)
          this.siliniyor = false;
        }, error => {
          this.yetkiService.error("Hata oluştu.", 1)
          this.grid?.load();
          this.siliniyor = false;
        });
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("İptal edildi", "Silme işlemi iptal edildi", 'error')
      }
    }) */
  }

  sil(model: ResmiTatil) {
    this.siliniyor = true;
    this.service.delete(model).subscribe(response => {
      bootstrap.Modal.getInstance(document.getElementById('sil_modal')).hide();
      this.yetkiService.success("Tatil başarıyla silindi.", 1)
      this.grid?.load();
      this.siliniyor = false;
    }, error => {
      this.yetkiService.error("Hata oluştu. Silme işlemi yapılamadı.", 1)
      bootstrap.Modal.getInstance(document.getElementById('sil_modal')).hide();
      this.grid?.load();
      this.siliniyor = false;
    });
  }

}
