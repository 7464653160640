type Nullable<T> = T | null;
export class TelafiIptal {
    id:number=0;    
    akademisyen_tc:string='';
    akademik_takvim_id: number = 0;
    donem_id: number = 0;
    iptal_ders_id:number=0;
    birim_id: number=0; 
    bolum_id: number=0;
    program_id: number=0;
    ogrenim_turu: string='';
    iptal_ders_tipi: string='';
    iptal_ders_tarihi?:  Nullable<Date> = null;
    iptal_ders_saat: number=0;  
    telafi_tarihi?:  Nullable<Date> = null;
    baslama_saati:string='';
    ders_saati_sayisi?:number=0;
    derslik_id:number=0;    
    resmi_yazi_tarihi?:  Nullable<Date> = null;
    resmi_yazi_sayi:string='';   
    aktif:string='';   
    idy_tc: string='';
    idt?:  Nullable<Date> = null;
    sdy_tc: string='';
    sdt?:  Nullable<Date> = null; 
    ders_adi : string=''; 
    
}
