import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CizelgeRaporDto } from 'src/app/models/Cizelge.model';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';
import { GirisService } from 'src/app/services/giris.service';
import { YetkiService } from 'src/app/services/yetki.service';

@Component({
  selector: 'yonetim-anasayfa',
  templateUrl: './yonetim-anasayfa.component.html',
  styleUrls: ['./yonetim-anasayfa.component.css']
})
export class YonetimAnasayfaComponent implements OnInit {

  constructor(private haftalikDersProgramService: HaftalikDersProgramService, 
              private girisService: GirisService, 
              public yetkiService: YetkiService, 
              private router: Router) { 
                this.service = haftalikDersProgramService; }
  
  cizelgeRapor = new CizelgeRaporDto(); 
  service: HaftalikDersProgramService;
  yukleniyor : boolean = false;
  

  ngOnInit(): void {
    this.getRapor();    
  }

  getRapor() {
    this.yukleniyor = true;
    this.service.cizelgeRapor().subscribe(res => {
      this.cizelgeRapor = res.data; 
      this.yukleniyor = false;
    });        
  }
  
}
