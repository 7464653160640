<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-calendar-alt me-2 text-success" style="font-size: large;"></i>Akademisyen
                        Haftalık Ders Programı (Takvim)
                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.ExcelAktar'])" class="col-md-6" style="text-align: right;">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('DersProgramFormu.xlsx','ders_calendar')" style="color: green;">                    
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button>
            </div>
        </div>
    </div>

    <form [formGroup]="listForm">
        <div class="post d-flex flex-column-fluid" id="bl_post">
            <div id="bl_content_container" class="container-fluid">
                <loading [loader]="yukleniyor">
                    <div class="card" style="border-top: 3px #77c2e9 solid;">
                        <div class="card-body ">
                            <div class="row">

                                <div class="col-sm-6">
                                    <div class="row">
                                        <div class="form-floating mb-5 ">
                                            <select class="form-select" id="donemID" [(ngModel)]="secilidonem" name="donemID" 
                                                [ngModelOptions]="{standalone: true}"  (ngModelChange)="DonemSecim($event)">
                                                <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                                <option *ngFor="let item of akademikTakvimDto" [ngValue]="item">
                                                    {{item.birimAdi}} {{item.donemAdi}}
                                                </option>
                                            </select>
                                            <label style="color: #009ef7;" class="fw-bold" for="donemID">Dönem</label>
                                            <span class="form-text text-danger fs-6" *ngIf="donemHata">{{donemHata}}</span>
                                            
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-floating mb-5 ">
                                            <select class="form-select" id="haftaID" [(ngModel)]="secilihafta" name="haftaID" 
                                                [ngModelOptions]="{standalone: true}"  (ngModelChange)="HaftaSecim($event)">
                                                <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                                <option *ngFor="let item of haftaDto" [ngValue]="item">
                                                    {{item.aciklama}}
                                                </option>
                                            </select>
                                            <label style="color: #009ef7;" class="fw-bold" for="haftaID">Hafta</label>
                                            <!-- <span class="form-text text-danger fs-6" *ngIf="haftaHata">{{haftaHata}}</span> -->                                            
                                        </div>                                        
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.NebisGetir']) && (yetkiService.yetkiVar(['EkDers.Akademisyen.HepsiniGorme']) || 
                                                 (!yetkiService.yetkiVar(['EkDers.Akademisyen.HepsiniGorme']) && (akademisyen_tc == girisService.girisBilgi().tckimlikno)))" 
                                                class="form-floating mb-5" style="vertical-align: middle;">
                                                <button [disabled]="akademisyen_onay == 'O' || akademisyen_onay == 'E'"
                                                    class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                                    (click)="GetListPortalBilgiKaydet()"
                                                    style="color: #009ef7; width: 80%;">
                                                    <i class="fas fa-university text-white"></i>
                                                    NEÜ Bilgi Sisteminden Getir
                                                </button>                                                
                                            </div>
                                        </div>
                                        <div class="col-sm-6" style="text-align: right;">
                                            <div *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.OnayGonder']) && (yetkiService.yetkiVar(['EkDers.Akademisyen.HepsiniGorme']) || 
                                                (!yetkiService.yetkiVar(['EkDers.Akademisyen.HepsiniGorme']) && (akademisyen_tc == girisService.girisBilgi().tckimlikno)))" 
                                                class="form-floating mb-5" style="vertical-align: middle;">
                                                <button [disabled]="akademisyen_onay != 'H'"
                                                    class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary"
                                                    (click)="OnayGonder('E','Ders programını onaya göndermek istediğinize emin misiniz ?', 'ONAYA GÖNDER', 'Gönder')"
                                                    style="color: #009ef7; width: 80%;">
                                                    <i class="far fa-calendar-check text-white"></i>
                                                    Onaya Gönder (Kesinleştir)
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">

                                    
                                    <div class="row">
                                        <div class="col-sm-12 text-center">
                                            <div class="form-floating mb-5" style="vertical-align: middle;">
                                                <h4>{{akademisyen_AdSoyadUnvan}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div
                                            class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                            <img *ngIf="akademisyen_resim != null" src="data:image/png;base64,{{akademisyen_resim}}"
                                                alt="image"> 
                                            <img *ngIf="akademisyen_resim == null" src="assets/media/avatars/blank.png"                                            
                                                alt="image"> 

                                        </div>
                                        </div>
                                        <div class="col-md-3">
                                            <table style="width: 100%;">
                                                <tbody>
                                                    <tr *ngFor="let item of haftalikDersProgramOzet">
                                                        <!-- <td style="width: 50%;"></td> -->
                                                        <td style="width: 100%;">
                                                            <label [style.background-color]="item.renk"
                                                                [style.color]="item.renk">__</label>
                                                            {{item.tip}} :
                                                            {{item.ders_saat}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                     
                                        <div class="col-sm-6 col-md-6 col-xs-6 col-lg-6 text-center">
                                            <div *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.GunlukForm.Gorme'])" 
                                                class="form-floating mb-5" style="vertical-align: middle;">
                                                <a target="_blank" [routerLink]="['/dersprogram-form', akademisyen_tc]"
                                                    [queryParams]="{hafta_id: haftaID, yilhafta: yilhafta, donem_id: donemID, adsoyad_unvan: akademisyen_AdSoyadUnvan}"
                                                    class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                                    style="color: #009ef7; width: 80%;">
                                                    <i class="far fa-sticky-note text-white"></i>
                                                    Ders Program Günlük Form
                                                </a>
                                            </div>
                                            <div *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.TumDersForm.Gorme'])" 
                                                class="form-floating mb-5" style="vertical-align: middle;">
                                                <a target="_blank"
                                                    [routerLink]="['/dersprogram-tumders', akademisyen_tc]"
                                                    [queryParams]="{hafta_id: haftaID, yilhafta: yilhafta, donem_id: donemID}"
                                                    class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                                    style="color: #009ef7; width: 80%;">
                                                    <i class="fas fa-newspaper text-white"></i>
                                                    Verilen Tüm Dersler Form
                                                </a>
                                            </div>
                                            <div class="form-floating mb-5" style="vertical-align: middle;">                                              
                                                <label class="fw-bold me-4" 
                                                style=" width: 80%; font-weight: bold; font-size: medium;"                                                
                                                [ngStyle]="{'color': akademisyen_onay == 'R' ?'red':akademisyen_onay == 'O' ?'#0ebd1d': akademisyen_onay == 'E' ? '#ffcc00': akademisyen_onay == 'H' ? 'blue':'white'}">
                                                    {{ akademisyen_onay == 'R' ?'Reddedildi': akademisyen_onay == 'O' ?'Onaylandı': akademisyen_onay == 'E' ? 'Onay Bekliyor': akademisyen_onay == 'H' ? 'Onaya Gönderilmedi': akademisyen_onay }}</label>   
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body py-4" style="border-top: #f5c6c6 1px solid; background-color:white; ">
                        <div class="row" *ngIf="akademisyen_onay == 'E'">
                            <div class="col-sm-6">
                                <div class="row">
                                    <div *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.Onayla'])" class="col-md-2">
                                        <button type="button" data-theme="dark" title="Onaylamak için tıklayınız."
                                            (click)="OnayGonder('O','Ders programını onaylamak istediğinize emin misiniz ?', 'ONAY İŞLEMİ', 'Onayla')"
                                            class="btn  btn-hover-rise btn-sm min-w-100px p-1"
                                            style="color:rgb(38, 173, 38); float: left; border: 2px solid rgb(38, 173, 38)">
                                            <i class="far fa-check-circle text-success"></i> Onayla
                                        </button>
                                    </div>
                                    <div *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.Onayla'])" class="col-md-2">
                                        <button type="button" data-theme="dark" title="İptal etmek için tıklayınız."
                                            (click)="OnayGonder('R','Ders programını iptal etmek istediğinize emin misiniz ?', 'İPTAL İŞLEMİ', 'İptal Et')"
                                            class="btn  btn-hover-rise btn-sm min-w-100px p-1"
                                            style="color:rgba(255, 0, 0, 0.767); float: left; border: 2px solid rgba(255, 0, 0, 0.767)">
                                            <i class="far fa-times-circle text-danger"></i> İptal Et
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">

                            </div>
                        </div>
                        <div class="row">
                            <div style="overflow:auto;width: 100%;">
                                <br>
                                <full-calendar *ngIf="calendarOptions" #fullcalendar [options]="calendarOptions"
                                    id="ders_calendar">
                                </full-calendar>
                            </div>
                        </div>
                    </div>
                    <!-- <table class="table table-striped" id="bl_subscriptions_table">
                        <thead>
                            <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 " style="color:#009ef7;">
                                <th>#</th>
                                <th class="min-w-125px">Ders ID</th>                            
                                <th class="min-w-125px">Ders Adı</th>
                                <th class="min-w-125px">Fakülte Adı</th>    
                                <th class="min-w-125px">Başlama Saati</th>
                                <th class="min-w-125px">Bitiş Saati</th>
                                <th class="min-w-125px">Gün No</th>
                                <th class="min-w-125px">Kaç Saat</th>                                                                     
                            </tr>
                        </thead>  
                        <tbody class="text-gray-600 fw-bold">
                            <tr *ngFor="let item of dersProgramDto">
                                <td>
                                    {{item.id}}
                                </td>
                                <td>
                                    {{item.dersID}}                         
                                </td>                            
                                <td>
                                    {{item.dersAdi}}
                                </td>
                                <td>
                                    {{item.fakulteAdi}}
                                </td>
                                <td>
                                    {{item.baslamaSaati}}
                                </td>       
                                <td>
                                    {{item.normalBitisSaati}}
                                </td>                            
                                <td>
                                    {{item.gunNo}}
                                </td>
                                <td>
                                    {{item.kacSaat}}
                                </td>                                                                           
                            </tr>
                        </tbody>                
                    </table> -->
                </loading>
            </div>
        </div>
    </form>
</div>