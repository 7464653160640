export class Cizelge {
    sira_no: number = 0;
    akademisyen_id: number = 0;
    akademisyen_tc: string = '';
    akademisyen_adi: string = '';
    akademisyen_adi_unvan: string = '';
    birim_id: number = 0;
    bolum_id: number = 0;
    donem_id: number = 0;
    donem_adi: string = '';
    onay: string = '';
    onay_durumu: string = '';
    onay_tc: string = '';
    onaylayan: string = '';
    onay_tarihi?: Date;
    idari_gorev_sirano: number = 0;
    unvan_sirano: number = 0;
    idari_gorev: string = '';
}

export class CizelgeToplam {
    id: number = 0;
    sira_no: number = 0;
    yilhafta: string = '';    
    akademik_takvim_id: number = 0;
    donem_id: number = 0;
    akademisyen_id: number = 0;
    akademisyen_tc: string = '';
    akademisyen_adi: string = '';
    ucret_tipi: string = '';
    ucret_tipi_id: number = 0;
    toplam_teo: number = 0;
    toplam_uyg: number = 0;
    toplam_teo_uyg: number = 0;
    zorunlu_ders: number = 0;
    ucret_ekders: string = '';
    max_ders_sayi: number = 0;
    cizelgehesap: any[] = [];
    tarih_araligi: string = '';
    donem_adi: string = '';
    birim_adi: string = '';    
}

export class CizelgeHesap {
    id: number = 0;
    sira_no: number = 0;
    yilhafta: string = '';
    gun_tarih?: Date = new Date();
    akademik_takvim_id: number = 0;
    donem_id: number = 0;
    akademisyen_id: number = 0;
    akademisyen_tc: string = '';
    unvan_id: number = 0;
    idari_gorev_id: number = 0;
    ogrenim_turu: string = '';
    ders_tipi: string = '';
    sinif: string = '';
    birim_id: number = 0;
    birim_adi: string = '';
    ders_id: number = 0;
    ders_adi: string = '';
    ders_saat: number = 0;
    saat_durumu: string = '';
    kadro_durumu: number = 0;
    ucret_tipi: string = '';
    ucret_tipi_id: number = 0;
    max_ekders_saati_normal: number = 0;
    max_ekders_saati_io: number = 0;
    teo_saat: number = 0;
    uyg_saat: number = 0;
}

export class CizelgeParam {
    birim_id: string = '0';
    bolum_id: string = '0';
    program_id: string = '0';
    onaylist: any[] = [];
    yilhafta: string = '';
    donem_id: string = '0';
}

export class CizelgeToplamParam {
    birim_id: string = '0';
    yilhafta: string = '';
    tclist: any[] = [];
}

export class CizelgeGrupRaporDto {
    tanim_id: number = 0;
    tanim_adi: string = '';
    ders_saat_1: number = 0;
    ders_saat_2: number = 0;
    ders_saat_3: number = 0;
    ders_saat_4: number = 0;
}

export class CizelgeRaporDto {
    unvanlist: CizelgeGrupRaporDto[] = [];
    birimlist: CizelgeGrupRaporDto[] = [];
    ucrettiplist: CizelgeGrupRaporDto[] = [];
    gunlist: CizelgeGrupRaporDto[] = [];    
}

