<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-list me-2 text-success" style="font-size: large;"></i>Akademik Takvim Listesi
                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.AkademikTakvim.ExcelAktar'])" class="col-md-6" style="text-align: right;">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('AkademikTakvim.xlsx','el_subscriptions_table')" style="color: green;">                    
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button>

            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="el_post">
        <div id="el_content_container" class="container-fluid">
            <div class="card" style="border-top: 3px #77c2e9 solid; background-color:white;">
                <div class="card-body" style="padding:1.5rem">
                    <loading [loader]="yukleniyor">
                        <div style="overflow:auto;width: 100%;">
                            <table class="table table-striped" id="bl_subscriptions_table">
                                <thead>
                                    <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 " style="color:#009ef7;">
                                        <th>No</th>
                                        <th class="min-w-125px">Takvim Adı</th>
                                        <th class="min-w-125px">Birim</th>
                                        <th class="min-w-125px">Dönem</th>
                                        <th class="min-w-125px">Başlama Tarihi</th>
                                        <th class="min-w-125px">Bitiş Tarihi</th>
                                        <th class="min-w-125px">Ders Süresi</th>
                                        <th class="min-w-125px">Ders Arası</th>
                                        <th class="min-w-125px">Aktif Dönem</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-600 fw-bold">
                                    <tr *ngFor="let item of akademikTakvimDto">
                                        <td>
                                            {{ item.id }}
                                        </td>
                                        <td>
                                            {{ item.takvimAdi}}
                                        </td>
                                        <td>
                                            {{ item.birimAdi}}
                                        </td>
                                        <td>
                                            {{ item.donemAdi}}
                                        </td>
                                        <td>
                                            {{ item?.baslamaTarihi | date:'dd.MM.yyyy':'IST' }}
                                        </td>
                                        <td>
                                            {{ item?.bitisTarihi | date:'dd.MM.yyyy':'IST' }}
                                        </td>
                                        <td>
                                            {{ item.dersSuresi}}
                                        </td>
                                        <td>
                                            {{ item.dersArasi}}
                                        </td>
                                        <td>                                            
                                            {{ item.edowebAktifDonemMi == true? '[Aktif]' : '[Pasif]' }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </loading>
                </div>
            </div>
        </div>
    </div>
</div>