<div class="content d-flex flex-column flex-column-fluid">
  <div class="toolbar mb-5">
    <div class="container-fluid d-flex flex-stack">
      <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
        <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
          <span>
            <span class="me-4"></span>
            <i class="fas fa-list me-2 text-success" style="font-size: large;"></i>Akademisyenler Listesi
          </span>
        </h1>
      </div>
      <div *ngIf="yetkiService.yetkiVar(['EkDers.Akademisyen.ExcelAktar'])" class="col-md-6" style="text-align: right;">
        <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
          (click)="exportexcel('AkademisyenListesi.xlsx','bl_subscriptions_table')" style="color: green;">
          <i class="fas fa-file-excel " style="color: green;"></i>
          Excel
        </button>

      </div>
    </div>
  </div>
  <form [formGroup]="listForm">
    <div class="post d-flex flex-column-fluid" id="bl_post">
      <div id="bl_content_container" class="container-fluid">
        <div class="card" style="border-top: 3px #77c2e9 solid; ">
          <div class="card-body" style="padding:1.5rem">
            <div class="row">
              <div class="col-md-4">
                <div class="form-floating mb-5">
                  <input type="text" class="form-control" id="adSoyad" [ngModelOptions]="{standalone: true}"
                    placeholder="Adı Soyadı" name="adSoyad" [(ngModel)]="adSoyad" />
                  <label style="color: #009ef7;" class="fw-bold" for="adSoyad">Adı Soyadı</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-floating mb-5">
                  <select class="form-select" id="birimID" [(ngModel)]="birimID" name="birimID"
                    [ngModelOptions]="{standalone: true}" (change)="BolumGetir()">
                    <option [ngValue]=0>Lütfen Seçiniz... </option>
                    <option *ngFor="let item of birimDto" [ngValue]="item.birimid">
                      {{item.birimadi}}
                    </option>
                  </select>
                  <label style="color: #009ef7;" class="fw-bold" for="birimID">Birim Adı</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-floating mb-5">
                  <select class="form-select" id="unvanID" [(ngModel)]="unvanID" name="unvanID"
                    [ngModelOptions]="{standalone: true}">
                    <option [ngValue]=0>Lütfen Seçiniz... </option>
                    <option *ngFor="let item of unvanDto" [ngValue]="item.id">
                      {{item.unvanadi}}
                    </option>
                  </select>
                  <label style="color: #009ef7;" class="fw-bold" for="unvanID">Ünvan</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-floating mb-5">
                  <select class="form-select" id="bolumID" [ngModelOptions]="{standalone: true}" name="bolumID"
                    [(ngModel)]="bolumID">
                    <option [ngValue]=0>Lütfen Seçiniz... </option>
                    <option *ngFor="let item of bolumDto" [ngValue]="item.birimid">
                      {{item.birimadi}}
                    </option>
                  </select>
                  <label style="color: #009ef7;" class="fw-bold" for="bolumID">Bölüm Adı</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-left">
                <div class="form-floating mb-5" style="vertical-align: middle;">
                  <button
                    class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                    (click)="GetListByAkademisyen()" style="color: #009ef7;">
                    <i class="fas fa-list text-white"></i>
                    Akademisyenleri Listele
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body py-4" style="border-top: #f5c6c6 1px solid; background-color:white; ">
          <loading [loader]="yukleniyor">
            <div style="overflow:auto;width: 100%;">
              <table class="table table-striped" id="bl_subscriptions_table">
                <thead>
                  <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 " style="color:#009ef7;">
                    <th>No</th>
                    <th class="min-w-125px">Adı</th>
                    <th class="min-w-125px">Soyadı</th>
                    <th class="min-w-125px">Ünvan</th>
                    <th class="min-w-125px">Kadro Birimi</th>
                    <th class="min-w-125px">Kurum Sicil No</th>
                    <th class="min-w-125px">E-Mail</th>
                    <th class="min-w-125px">Cep Telefonu</th>
                    <th class="min-w-125px">Aktif</th>
                    <th *ngIf="yetkiService.yetkiVar(['EkDers.PBS.Guncelleme'])" class="">İşlem</th>
                  </tr>
                </thead>
                <tbody class="text-gray-600 fw-bold">
                  <tr *ngFor="let item of akademisyenListDto">
                    <td>
                      {{ item.personelid }}
                    </td>
                    <td>
                      {{ item.ad}}
                    </td>
                    <td>
                      {{ item.soyad}}
                    </td>
                    <td>
                      {{ item.akunvan == null ? item.unvan : item.akunvan}}
                    </td>
                    <td>
                      {{ item.kadrobirimuzun}}
                    </td>
                    <td>
                      {{ item.kurumsicilno}}
                    </td>
                    <td>
                      {{ item.kurummail }}
                    </td>
                    <td>
                      {{ item.ceptel}}
                    </td>
                    <td>
                      {{ item.kayitdurumu }}
                    </td>
                    <td>
                      <button type="button" title="Personel Bilgi Sisteminden Verileri Güncellemek için kullanılır."
                        data-bs-toggle="modal" data-bs-target="#basvuru_ayrinti_modal" data-toggle="modal"
                        (click)="akademisyenSecildi(item)"
                        class="btn btn-sm btn-light-info btn-hover-rise btn-sm mx-2"
                        style="border: 1px solid rgb(151, 151, 229);">
                        <span class="indicator-label fs-7"><i class="fa fa-info-circle"></i>Bilgi</span>

                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </loading>
        </div>
      </div>
    </div>
  </form>
</div>

<div id="basvuru_ayrinti_modal" data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <a class="text-gray-900 text-hover-primary fs-4 fw-bolder me-1">Personel Bilgileri</a>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <div class="" style="">
        <div class="card">
          <div class="d-flex flex-column flex-lg-row">
            <div class="flex-column flex-lg-row-auto w-lg-250px w-xl-350px">
              <div class="card mb-xl-8" >
                <loading [loader]="tekpersonelyukleniyor">
                <div class="card-body">
                  <div class="d-flex flex-center flex-column py-3">
                    <div class="symbol symbol-100px symbol-circle mb-7">
                      <img *ngIf="seciliAkademisyen.resim != null"  [src]="akademisyen_resim " alt="image">
                      <img *ngIf="seciliAkademisyen.resim == null" src="assets/media/avatars/blank.png" alt="image">
                    </div>
                    <a class="fs-3 text-gray-800 text-hover-primary fw-bolder mb-3">{{seciliAkademisyen.ad}} {{seciliAkademisyen.soyad}}</a>
                    <div *ngIf="seciliAkademisyen.akunvan!=null" class="mb-4">
                      <div class="badge badge-lg badge-light-primary d-inline">{{seciliAkademisyen.akunvan}}</div>
                    </div>
                    <div class="mb-4">
                      <div class="badge badge-lg badge-light-primary d-inline">{{seciliAkademisyen.kayitdurumu}}</div>
                    </div>
                  </div>

                  <div class="separator"></div>
                  <div id="kt_user_view_details" class="collapse show">
                    <div class="pb-5 fs-7">
                      <div class="fw-bolder mt-6">Personel Tipi</div>
                      <div class="text-gray-600">{{seciliAkademisyen.personelTipi}} - {{seciliAkademisyen.personelAltTipi}}</div>
                      <div class="fw-bolder mt-4">Kadro Birimi</div>
                      <div class="text-gray-600">{{seciliAkademisyen.kadrobirimuzun}}</div>
                      <div class="fw-bolder mt-2">Çalıştığı Birimi</div>
                      <div class="text-gray-600">{{seciliAkademisyen.calistigibirimidaduzun}}</div>
                      <div class="fw-bolder mt-4">Email</div>
                      <div class="text-gray-600">{{seciliAkademisyen.kurummail}}</div>
                      <div class="fw-bolder mt-2">Cep Telefonu</div>
                      <div class="text-gray-600">{{seciliAkademisyen.ceptel}}</div>
                    </div>
                  </div>

                </div>
                <div class="card-footer border-0 d-flex justify-content-center pt-0">
                  <button *ngIf="yetkiService.yetkiVar(['EkDers.PBS.Guncelleme'])" type="button" title="Personel Bilgi Sisteminden Verileri Güncellemek için kullanılır."
                  class="btn btn-sm btn-light-primary btn-hover-rise btn-sm mx-2"
                  style="border: 1px solid rgb(151, 151, 229);" [disabled]="seciliAkademisyen.pbsguncelleniyor"
                  (click)="pbsGuncelle(seciliAkademisyen)" [attr.data-kt-indicator]="seciliAkademisyen.pbsguncelleniyor ? 'on' : 'off'">
                  <span class="indicator-label fs-7"><i class="fas fa-sync fa-fw"></i> PBS'den Güncelle</span>
                  <span class="indicator-progress">Lütfen bekleyiniz...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
                </div>
               </loading>
              </div>
            </div>

            <div class="card" style="width: 100%;">
              <div class="card-body p-2" s>
                <div class="stepper stepper-links d-flex flex-column pt-0">
                  <loading [loader]="tekpersonelyukleniyor">

                  <div class="stepper-nav mb-0 text-center">
                    <div [ngClass]="{'current': aktifsayfa === 1}" class="stepper-item cursor-pointer"
                      data-kt-stepper-element="nav" (click)="aktifsayfa=1">
                      <h6 class="stepper-title" translate>İdari Görev</h6>
                    </div>
                    <div
                      [ngClass]="{'current': aktifsayfa === 2}" class="stepper-item cursor-pointer"
                      data-kt-stepper-element="nav" (click)="aktifsayfa=2">
                      <h6 class="stepper-title" translate>İzin</h6>
                    </div>
                    <div [ngClass]="{'current': aktifsayfa === 3}" class="stepper-item cursor-pointer"
                      data-kt-stepper-element="nav" (click)="aktifsayfa=3">
                      <h6 class="stepper-title" translate>Görevlendirme</h6>
                    </div>
                    <div *ngIf="yetkiService.yetkiVar(['EkDers.BirimGuncelle'])"  [ngClass]="{'current': aktifsayfa === 4}" class="stepper-item cursor-pointer"
                      data-kt-stepper-element="nav" (click)="aktifsayfa=4">
                      <h6 class="stepper-title" translate>Birim Değiştir</h6>
                    </div>
                  </div>

                  <div [ngClass]="{'current': aktifsayfa === 1}" class="table-responsive" data-kt-stepper-element="content" style="flex-direction: column ; height: 500px; overflow-y: auto;">
                    <div *ngIf="seciliAkademisyen.idarigorev.length<1" class="fw-bold text-gray-500 fs-5 text-center m-20">İdari Görev Kaydı Bulunamadı.</div>
                    <table *ngIf="seciliAkademisyen.idarigorev.length>0" class="table table-row-bordered mt-15" style="margin-bottom: 0px; overflow-y: auto;">
                      <thead>
                        <tr class="fw-bold text-gray-800 fs-7">
                          <th class="min-w-200px">Birim</th>
                          <th class="min-w-200px">Görev</th>
                          <th class="min-w-75px text-center">Başlama</th>
                          <th class="min-w-75px text-center">Bitiş</th>
                          <th class="w-50px text-center">Vek.</th>
                          <th class="w-50px text-center">Ted.</th>
                          <th class="min-w-100px">Seviye</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- {{ gorev.idarigorevbittar | date:'dd.MM.yyyy HH:mm'}} -->
                        <tr *ngFor="let gorev of seciliAkademisyen.idarigorev" class="text-gray-800 fs-7">
                          <td>{{gorev.birimadi}}</td>
                          <td>{{gorev.unvanadi}}</td>
                          <td class="text-center">{{ gorev.idarigorevbastar | date:'dd.MM.yyyy'}}</td>
                          <td class="text-center">{{ gorev.idarigorevbittar | date:'dd.MM.yyyy'}}</td>
                          <td class="text-center">
                            <input class="form-check" style="display: inline;" disabled type="checkbox" value="{{gorev.idarigorevisvekaleten==true?1:0}}">
                          </td>
                          <td class="text-center">
                            <input class="form-check"style="display: inline;" disabled type="checkbox" value="{{gorev.idarigorevIstedviren==true?1:0}}">
                          </td>
                          <td>{{gorev.idarigorevunvanseviye}}</td>
                        </tr>
                      </tbody>
                     </table>
                  </div>


                  <div [ngClass]="{'current': aktifsayfa === 2}" class="table-responsive" data-kt-stepper-element="content" style="flex-direction: column ; height: 500px; overflow-y: auto;">
                    <div *ngIf="seciliAkademisyen_izin.length<1" class="fw-bold text-gray-500 fs-5 text-center m-20">İzin Kaydı Bulunamadı.</div>
                    <table *ngIf="seciliAkademisyen_izin.length>0" class="table table-row-bordered mt-15" style="margin-bottom: 0px; overflow-y: auto; ">
                      <thead>
                        <tr class="fw-bold text-gray-800 fs-7">
                          <th class="min-w-300px">İzin Türü</th>
                          <th class="min-w-75px text-center">Başlama</th>
                          <th class="min-w-75px text-center">Bitiş</th>


                        </tr>
                      </thead>
                      <tbody>
                       <tr *ngFor="let izin of seciliAkademisyen_izin" class="text-gray-800 fs-7">
                          <td class="">{{ izin.izintipi}}</td>
                          <td class="text-center">{{ izin.izinbastar | date:'dd.MM.yyyy'}}</td>
                          <td class="text-center">{{ izin.izinbittar | date:'dd.MM.yyyy'}}</td>
                        </tr>
                      </tbody>
                     </table>
                  </div>


                  <div [ngClass]="{'current': aktifsayfa === 3}" class="table-responsive" data-kt-stepper-element="content" style="flex-direction: column ; height: 500px; overflow-y: auto;">
                    <div *ngIf="seciliAkademisyen_gorevizin.length<1" class="fw-bold text-gray-500 fs-5 text-center m-20">Görevli-İzinli Kaydı Bulunamadı.</div>
                    <table *ngIf="seciliAkademisyen_gorevizin.length>0" class="table table-row-bordered mt-15" style="margin-bottom: 0px; overflow-y: auto;">
                      <thead>
                        <tr class="fw-bold text-gray-800 fs-7">
                          <th class="min-w-75px text-center">Başlama</th>
                          <th class="min-w-75px text-center">Bitiş</th>
                          <th class="min-w-75px">Tipi</th>
                          <th class="min-w-200px">Açıklama</th>
                          <th class="min-w-100px">Ek Ders</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- {{ gorev.idarigorevbittar | date:'dd.MM.yyyy HH:mm'}} -->
                        <tr *ngFor="let izin of seciliAkademisyen_gorevizin" class="text-gray-800 fs-7">
                          <td class="text-center">{{ izin.izinbastar | date:'dd.MM.yyyy'}}</td>
                          <td class="text-center">{{ izin.izinbittar | date:'dd.MM.yyyy'}}</td>
                          <td class="">{{ izin.gorevlendirmeTipi}}</td>
                          <td class="">{{ izin.aciklama}}</td>
                          <td class="">{{ izin.ekdersalirmi}}</td>
                        </tr>
                      </tbody>
                     </table>
                  </div>


                  <div *ngIf="yetkiService.yetkiVar(['EkDers.BirimGuncelle'])" [ngClass]="{'current': aktifsayfa === 4}" class="table-responsive" data-kt-stepper-element="content" style="flex-direction: column ; height: 500px; overflow-y: auto;">
                    <div class="row m-10">
                        <div class="form-floating mb-5">
                              <select class="form-select" id="bg_birimID" [(ngModel)]="bg_birimID" name="bg_birimID"
                                  [ngModelOptions]="{standalone: true}" (change)="bg_BolumGetir()">
                                  <option [ngValue]=-1>Lütfen Seçiniz... </option>
                                  <option *ngFor="let item of bg_birimDto" [ngValue]="item.birimid">
                                      {{item.birimadi}}
                                  </option>
                              </select>
                              <label style="color: #009ef7;" class="fw-bold" for="bg_birimID">Birim Adı</label>
                              <span class="form-text text-danger fs-6" *ngIf="bg_birimHata">{{bg_birimHata}}</span>
                        </div>
                        <div class="form-floating mb-5">
                          <select class="form-select" id="bg_bolumID" [ngModelOptions]="{standalone: true}"
                              name="bg_bolumID" (change)="bg_AnabilimdaliGetir()" [(ngModel)]="bg_bolumID">
                              <option [ngValue]=-1>Lütfen Seçiniz... </option>
                              <option *ngFor="let item of bg_bolumDto" [ngValue]="item.birimid">
                                  {{item.birimadi}}
                              </option>
                          </select>
                          <label style="color: #009ef7;" class="fw-bold" for="bg_bolumID">Bölüm Adı</label>
                          <span class="form-text text-danger fs-6" *ngIf="bg_bolumHata">{{bg_bolumHata}}</span>
                      </div>
                        <div class="form-floating mb-5">
                              <select class="form-select" id="bg_abdID" [(ngModel)]="bg_abdID" name="bg_abdID"
                                  [ngModelOptions]="{standalone: true}">
                                  <option [ngValue]=0>Lütfen Seçiniz... </option>
                                  <option *ngFor="let item of bg_programDto" [ngValue]="item.birimid">
                                      {{item.birimadi}}
                                  </option>
                              </select>
                              <label style="color: #009ef7;" class="fw-bold" for="bg_abdID">Anabilimdalı Adı</label>
                        </div>
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.BirimGuncelle'])" class="form-floating mb-5" style="vertical-align: middle;">
                            <button
                                class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                (click)="bg_Guncelle()" style="color: #009ef7;">
                                <i class="fas fa-list text-white"></i>
                                  Kaydet
                            </button>
                        </div>
                    </div>
                  </div>
                 </loading>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>
</div>
