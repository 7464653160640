import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AkademikTakvimDto } from 'src/app/models/AkademikTakvim.model';
import { AkademisyenlerDto, AkademisyenParam } from 'src/app/models/Akademisyenler.model';
import { HaftaDto } from 'src/app/models/Haftalar.model';
import { AkademikTakvimService } from 'src/app/services/akademiktakvim.service';
import { AkademisyenService } from 'src/app/services/akademisyen.service';
import { GenelService } from 'src/app/services/genel.service';
import { GirisService } from 'src/app/services/giris.service';
import { YetkiService } from 'src/app/services/yetki.service';

@Component({
  selector: 'sol-menu',
  templateUrl: './sol-menu.component.html',
  styleUrls: ['./sol-menu.component.css']
})
export class SolMenuComponent implements OnInit {

  constructor(private akademisyenService: AkademisyenService, 
          private akademiktakvimService: AkademikTakvimService,
          private genelService: GenelService, 
          private _yetkiService: YetkiService, 
          public girisService: GirisService, 
          private router: Router,
          private datepipe: DatePipe) {
    this.yetkiService = _yetkiService;
  }
  yetkiService: YetkiService;

  tcList : any[] = [];
  birimID: number = 0;
  birimAdi: string = '';
  donemAdi: string = '';  
  donemID: number = 0;  
  ilktarih : any; 
  sontarih : any;
  yilhafta: string = '';
  haftaAciklama: string = '';
  haftaDto: HaftaDto[] = [];
  akademikTakvimDto: AkademikTakvimDto[] = [];  
  akademisyenListDto: AkademisyenlerDto[] = [];
  akademisyenParam: AkademisyenParam = new AkademisyenParam();

  ngOnInit(): void {
    this.BilgileriGetir(this.girisService.girisBilgi().tckimlikno);
  }

  BilgileriGetir(tc: string='') {
    this.akademisyenParam.personeltc = tc; 
    this.tcList.push(tc);        
    this.akademisyenService.getAllAkademisyenList(this.akademisyenParam).subscribe(res => {
      this.akademisyenListDto = res.data;    
      this.akademisyenListDto.forEach(e => {        
        this.birimID = e.kadrobirimid; 
        this.birimAdi = e.kadrobirimuzun; 
      });        
        
      this.akademiktakvimService.getAkademikTakvim(this.birimID).subscribe(respone => {
        this.akademikTakvimDto = respone.data;
        this.akademikTakvimDto.forEach(e => {        
          this.ilktarih = this.datepipe.transform(e.baslamaTarihi, 'dd.MM.yyyy');  
          this.sontarih = this.datepipe.transform(e.bitisTarihi, 'dd.MM.yyyy');   
          this.donemID = e.donemID; 
          this.donemAdi = e.donemAdi;  
        });  

        this.genelService.getHaftaList('T',this.ilktarih, this.sontarih).subscribe(respone => {
          this.haftaDto = respone.data;            
          if (respone.data.length > 0) { 
            this.yilhafta = this.haftaDto[0].yil.toString() + (this.haftaDto[0].hafta_id.toString().length == 1 ? '0'+ this.haftaDto[0].hafta_id.toString() : this.haftaDto[0].hafta_id.toString());   
            this.haftaAciklama = this.haftaDto[0].aciklama;      
          }            
        });
      });
    });
    
  }

}

