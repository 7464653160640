<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-table me-2 text-success" style="font-size: large;"></i>Tüm Çizelgeler

                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.Cizelge.ExcelAktar'])" class="col-md-6"
                style="text-align: right;">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('Cizelgeler.xlsx','bl_subscriptions_table')" style="color: green;">
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button>
            </div>
        </div>
    </div>
    <form [formGroup]="listForm">
        <div class="post d-flex flex-column-fluid" id="bl_post">
            <div id="bl_content_container" class="container-fluid">

                <div class="card" style="border-top: 3px #77c2e9 solid; ">
                    <div class="card-body" style="padding:1.5rem">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="birimID" [(ngModel)]="secilibirim" name="birimID"
                                        [ngModelOptions]="{standalone: true}" (ngModelChange)="BolumGetir($event)">
                                        <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of birimDto" [ngValue]="item">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="birimID">Birim Adı</label>
                                    <span class="form-text text-danger fs-6" *ngIf="birimHata">{{birimHata}}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="bolumID" [(ngModel)]="secilibolum" name="bolumID"
                                        [ngModelOptions]="{standalone: true}" (ngModelChange)="AnabilimdaliGetir($event)">
                                        <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of bolumDto" [ngValue]="item">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="bolumID">Bölüm Adı</label>
                                    <span class="form-text text-danger fs-6" *ngIf="bolumHata">{{bolumHata}}</span>
                                </div>

                                <!-- <div class="form-floating mb-5">
                                    <select class="form-select" id="bolumID" [ngModelOptions]="{standalone: true}"
                                        name="bolumID" [(ngModel)]="bolumID">
                                        <option [ngValue]=0>Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of bolumDto" [ngValue]="item.birimid">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="bolumID">Bölüm Adı</label>
                                    <span class="form-text text-danger fs-6" *ngIf="bolumHata">{{bolumHata}}</span>
                                </div> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="haftaID" [ngModelOptions]="{standalone: true}"
                                        name="haftaID" [(ngModel)]="secilihafta" (ngModelChange)="YilHafta($event)">
                                        <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of haftaDto" [ngValue]="item">
                                            {{item.aciklama}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="haftaID">Hafta</label>
                                    <span class="form-text text-danger fs-6" *ngIf="haftaHata">{{haftaHata}}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="programID" [(ngModel)]="seciliprogram" name="programID"
                                        [ngModelOptions]="{standalone: true}" (ngModelChange)="AnaBilimDaliSecim($event)">
                                        <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of programDto" [ngValue]="item">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="programID">Anabilim Dalı Adı</label>
                                    <!-- <span class="form-text text-danger fs-6" *ngIf="programHata">{{programHata}}</span> -->
                                </div>
                            </div>
                            <!-- <div class="form-floating mb-5 col-sm-4">
                                <input type="date" class="form-control" id="tarih1" [(ngModel)]="tarih1"
                                    placeholder="baslangicTarihi" name="tarih1" [ngModelOptions]="{standalone: true}" 
                                    style="border-color: #8fb9d5;" />
                                <label style="color: #009ef7;" class="fw-bold" for="tarih1">Başlangıç Tarihi</label>                                                            
                            </div>
                            <div class="form-floating mb-5 col-sm-4">
                                <input type="date" class="form-control" id="tarih2" [(ngModel)]="tarih2"
                                    placeholder="bitisTarihi" name="tarih2" [ngModelOptions]="{standalone: true}"
                                    style="border-color: #8fb9d5;" />
                                <label style="color: #009ef7;" class="fw-bold" for="tarih2">Bitiş Tarihi</label>                                                            
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <input type="checkbox" class="form-check-input me-4" (change)="onaylistSec($event,'E')"
                                    [checked]="true">
                                <label class="form-check-label checkbox-lg text-black text-justify"
                                    style="font-size:small;" for="Check1">Onay Bekleyen </label>
                            </div>
                            <div class="col-md-2">
                                <input type="checkbox" class="form-check-input me-4" (change)="onaylistSec($event,'O')"
                                    [checked]="true">
                                <label class="form-check-label checkbox-lg text-black text-justify"
                                    style="font-size:small;" for="Check2">Onaylanan </label>
                            </div>
                            <div class="col-md-2">
                                <input type="checkbox" class="form-check-input me-4" (change)="onaylistSec($event,'R')"
                                    [checked]="true">
                                <label class="form-check-label checkbox-lg text-black text-justify"
                                    style="font-size:small;" for="Check3">Reddedilen </label>
                            </div>
                            <div class="col-md-2">
                                <input type="checkbox" class="form-check-input me-4" (change)="onaylistSec($event,'H')"
                                    [checked]="false">
                                <label class="form-check-label checkbox-lg text-black text-justify"
                                    style="font-size:small;" for="Check4">Onaya Gönderilmeyen </label>
                            </div>
                        </div>
                        <div class="row"><br></div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-floating mb-5" style="vertical-align: middle;">
                                    <button
                                        class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                        (click)="GetListByCizelge()" style="color: #009ef7; width: 80%;">
                                        <i class="fas fa-list text-white"></i>
                                        Akademisyenleri Listele
                                    </button>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div *ngIf="yetkiService.yetkiVar(['EkDers.Cizelge.DetayGorme'])"
                                    class="form-floating mb-5" style="vertical-align: middle;">
                                    <!-- <a target="_blank"
                                        class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                        [routerLink]="['/cizelge-detay', birimID]"
                                        [queryParams]="{tc_list: gridSeclist, birim_adi: birimBolumAd, donem_adi: donemAdi, yil_hafta: yilhafta, hafta_tanim: haftaAciklama}"
                                        style="color: #009ef7; width: 80%;">
                                        <i class="fas fa-table text-white"></i>
                                        RP07-Toplu Özet Ders Raporu
                                    </a> -->
                                    <button (click)="newTabOpenLink('/cizelge-detay', birimID)"
                                        class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"                                        
                                        style="color: #009ef7; width: 80%;">                                                                              
                                         <i class="fas fa-table text-white"></i>
                                         RP07-Toplu Özet Ders Raporu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body py-4" style="border-top: #f5c6c6 1px solid; background-color:white; ">
                    <loading [loader]="yukleniyor">
                        <div style="overflow:auto;width: 100%;">
                            <table class="table table-striped" id="bl_subscriptions_table">
                                <thead>
                                    <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 " style="color:#009ef7; ">
                                        <th class="text-left">Sıra No</th>
                                        <th class="min-w-50px">
                                            <input type="checkbox" class="form-check-input border border-success"
                                                [(ngModel)]="selectedAll" [ngModelOptions]="{standalone: true}" (click)="selectAll()" [checked]="selectedAll">
                                            Tümünü Seç
                                        </th>
                                        <th class="min-w-125px">Onay Durumu</th>
                                        <th class="min-w-125px">İdari Görevi</th>
                                        <th class="min-w-125px">Öğretim Üyesi</th>                                        
                                        <th class="min-w-125px">Dönem</th>
                                        <th class="min-w-125px">Onaylayan</th>
                                        <th class="min-w-100px">Onay Tarihi</th>
                                        <th class="min-w-125px text-center">Onay İşlemleri</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-600 fw-bold">
                                    <tr *ngFor="let item of cizelgeList">
                                        <td>
                                            {{ item.sira_no}}
                                        </td>
                                        <td>
                                            <input type="checkbox" class="form-check-input border border-primary"
                                                (change)="secimlist($event,item)" [value]="item.akademisyen_tc"
                                                [checked]="gridSeclist.indexOf(item.akademisyen_tc)>=0">
                                        </td>
                                        <td
                                            [ngStyle]="{'color': item.onay == 'R' ?'red':item.onay == 'O' ?'#0ebd1d': item.onay == 'E' ? '#ffcc00': item.onay == 'H' ? 'blue':'black'}">
                                            {{ item.onay_durumu}}
                                        </td>
                                        <td>
                                            {{ item.idari_gorev}}
                                        </td>
                                        <td>
                                            <a target="_blank" [routerLink]="['/dersprogram-detay', item.akademisyen_tc]" 
                                                *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.Takvim']); else degil"
                                                [queryParams]="{birim_id: item.birim_id, adsoyadunvan: item.akademisyen_adi_unvan}"
                                                data-toggle="tooltip" data-theme="dark"
                                                title="Akademisyenin ders programını görmek için tıklayınız.">
                                                <i class="far fa-calendar-alt" style="font-size:small;"></i>
                                                <u> {{ item.akademisyen_adi_unvan}}</u>
                                            </a>          
                                            <ng-template #degil>
                                                <span> {{ item.akademisyen_adi_unvan}} </span>
                                            </ng-template>                                  
                                        </td>                                        
                                        <td>
                                            {{ item.donem_adi}}
                                        </td>
                                        <td>
                                            {{ item.onaylayan}}
                                        </td>
                                        <td>
                                            {{ item.onay_tarihi | date:'dd.MM.yyyy':'IST' }}
                                        </td>
                                        <td class="text-end" style="width: 125px;">
                                            <div class="row">
                                                <!-- <div class="col-md-6">
                                                    <button *ngIf="item.onay == 'E'" type="button" (click)="Onayla(item.akademisyen_tc)"
                                                        data-theme="dark" title="Onaylamak için tıklayınız."
                                                        class="btn  btn-hover-rise btn-sm min-w-100px p-1"
                                                        style="color:rgb(38, 173, 38); float: right; border: 2px solid rgb(38, 173, 38)">
                                                        <i class="far fa-check-circle text-success"></i> Onayla                                                        
                                                    </button>
                                                </div> -->
                                                <div *ngIf="yetkiService.yetkiVar(['EkDers.Cizelge.OnayKaldir'])"
                                                    class="col-md-12">
                                                    <button *ngIf="item.onay == 'O'" type="button"
                                                        (click)="OnayKaldir(item.akademisyen_tc, item.donem_id)"
                                                        data-theme="dark" title="Onay kaldırmak için tıklayınız."
                                                        class="btn  btn-hover-rise btn-sm min-w-125px p-1"
                                                        style="color:rgba(255, 0, 0, 0.767); float: right; border: 2px solid rgba(255, 0, 0, 0.767)">
                                                        <i class="far fa-times-circle text-danger"></i> Onay Kaldır
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </loading>
                </div>
            </div>
        </div>
    </form>
</div>