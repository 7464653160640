import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { CizelgeHesap, CizelgeToplamParam, CizelgeToplam } from 'src/app/models/Cizelge.model';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { RaporTanim } from 'src/app/models/HaftalikDersProgram.model';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs';
import { ValidationService } from 'src/app/services/validation.service';


@Component({
  selector: 'cizelge-detay',
  templateUrl: './cizelge-detay.component.html',
  styleUrls: ['./cizelge-detay.component.css']
})

export class CizelgeHesapComponent implements OnInit {

  constructor(private cizelgeService: HaftalikDersProgramService,
    public yetkiService: YetkiService,
    private validationService: ValidationService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    this.service = cizelgeService;
  }

  yukleniyor = false;
  ciktialiniyor: boolean = false;
  service: HaftalikDersProgramService;
  cizelgeHesapList: CizelgeHesap[] = [];
  cizelgeToplamList: CizelgeToplam[] = [];
  cizelgeToplamParam: CizelgeToplamParam = new CizelgeToplamParam();
  birimID: number = 0;
  birimAdi: string = '';
  donemAdi: string = '';
  yilhafta: string = '';
  haftatanim: string = '';
  tclist: any[] = [];
  dongu: number = 0;
  previewUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl("");
  rapor: RaporTanim = new RaporTanim();
  hatalar: any = {};

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    //this.router.getCurrentNavigation().extras.state
    this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(qparams => {
        if (qparams['tc_list']) {
          this.tclist = qparams['tc_list'];
          this.birimAdi = qparams['birim_adi'];
          this.donemAdi = qparams['donem_adi'];
          this.yilhafta = qparams['yil_hafta'];
          this.haftatanim = qparams['hafta_tanim'];
        }
      })

      if (params['birimID']) {
        this.birimID = params['birimID'];
        this.route.queryParams.subscribe(qryparams => {
          if (qryparams['tc_list']) {
            this.tclist = qryparams['tc_list'];
            this.yilhafta = qryparams['yil_hafta'];
            this.GetListByCizelgeToplam(params['birimID'], qryparams['yil_hafta'], qryparams['tc_list']);
          }
        })

      }
    });

  }

  counter(i: number) {
    return new Array(i);
  }

  GetListByCizelgeToplam(birimID: number = 0, yilhafta: string = '', tclist: any[]) {
    this.yukleniyor = true;
    //this.onaySeclist = ['E','O','R'];
    this.dongu = 0;
    this.cizelgeToplamParam.birim_id = birimID.toString();
    this.cizelgeToplamParam.yilhafta = yilhafta;
    this.cizelgeToplamParam.tclist = tclist;
    this.service.getAllCizelgeToplam(this.cizelgeToplamParam).subscribe(respone => {
      this.cizelgeToplamList = respone.data;
      this.cizelgeToplamList.forEach(e => {
        if (this.dongu < e.max_ders_sayi) {
          this.dongu = e.max_ders_sayi;
        }
        e.tarih_araligi = this.haftatanim;
        e.donem_adi = this.donemAdi;
        e.birim_adi = this.birimAdi;
      });
      //this.cizelgeHesapList = this.cizelgeToplamList[].cizelgehesap;
      this.yukleniyor = false;
    });

  }

  cizelgeYazdir() {
    this.ciktialiniyor=true;
      this.service.cizelgeBelgeAl(this.cizelgeToplamList).pipe(finalize(() => {
      })).subscribe({
        next: res => {
          // format 0=pdf, 1=html view
          this.rapor.format = 1;
          this.rapor.data = res.data.jsonData;
          //this.rapor.id = "7"; //rapor id = 7 Ekders Çizelge 10 çıktısı
          if (this.dongu <= 5) {
            this.rapor.kod = "OOA8CWS1";
          }
          else if (this.dongu <= 8) {
            this.rapor.kod = "FTS6ZQMX";
          }
          else if (this.dongu <= 10) {
            this.rapor.kod = "RRNZ0YP4";
          }
          else { // 20
            this.rapor.kod = "BZCQYP1O";
          }
          this.rapor.name = "Toplu Özet Ders Raporu";

          this.service.downloadFastReport("https://neurapor.erbakan.edu.tr/app/raporlama", this.rapor).pipe(finalize(() => {
          this.ciktialiniyor=false})).subscribe({
            next: res1 => {
              //console.log("datapdf", res1.data.url);
              this.previewUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(res1.data.url);
            //  window.open(res1.url);
            },
            error: err => {
              if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
                if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
                if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
              }
            }
          });

        },
        error: err => {
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
            if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
            if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
          }
        }
      });

    }



  exportexcel(fileName: string, tableName: string): void {
    var element = document.getElementById(tableName);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
