import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { BirimService } from 'src/app/services/birim.service';
import { RaporFarkDto, RaporFarkDtoParam, RaporSinavDto, RaporSinavDtoParam } from 'src/app/models/Raporlar.model';
import { RaporService } from 'src/app/services/rapor.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';

@Component({  
  selector: 'raporfark',
  templateUrl: './raporfark.component.html',
  styleUrls: ['./raporfark.component.css']
})

export class RaporFarkComponent implements OnInit {
  
  constructor(private raporService: RaporService, 
              private birimService: BirimService, 
              public yetkiService: YetkiService,             
              private formBuilder: FormBuilder,
              private router: Router
              ) {
    this.service = raporService;
   }

   listForm = this.formBuilder.group({
    raporForm: this.formBuilder.group(new RaporFarkDto()),
  });

  raporForm(): FormGroup {
    return this.listForm.get('raporForm') as FormGroup;
  }

  yukleniyor=false;
  service: RaporService;  
  raporFarkList: RaporFarkDto[] = [];
  raporFarkParam: RaporFarkDtoParam = new RaporFarkDtoParam();
  birimParam: BirimParam = new BirimParam();
  birimDto: any[] = [];
  bolumDto: any[] = [];
  programDto: any[] = [];
  birimID: number = -1;
  bolumID: number = 0;   
  abdID: number = 0;   
  tarih1: string = '';
  tarih2: string = '';
  personelTC: string = ''; 
  
  birimHata: string | null = '';
  tarih1Hata: string | null = '';
  tarih2Hata: string | null = ''; 


  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.BirimleriGetir();  
  } 
 
  GetRaporFark() {   
    let hataBirim = this.birimID <= -1 ? 'Lütfen Birim Seçiniz.' : null;
    let hataTarih1 = this.tarih1 == '' ? 'Lütfen Başlangıç Tarihi Giriniz.' : null;
    let hataTarih2 = this.tarih2 == '' ? 'Lütfen Bitiş Tarihi Giriniz.' : null;    
    this.birimHata = hataBirim;    
    this.tarih1Hata = hataTarih1; 
    this.tarih2Hata = hataTarih2;     
    if ((!this.birimHata) && (!this.tarih1Hata) && (!this.tarih2Hata)) { 
      this.yukleniyor = true;
this.raporFarkParam.birim_id = this.birimID.toString();
      this.raporFarkParam.bolum_id = this.bolumID.toString();
      this.raporFarkParam.program_id = this.abdID.toString();
      this.raporFarkParam.tarih1 = this.tarih1;
      this.raporFarkParam.tarih2 = this.tarih2; 
      this.raporFarkParam.akademisyen_tc = this.personelTC;  
      this.service.getListRaporFark(this.raporFarkParam).subscribe(respone => {      
        
        if (respone.data.length > 0) {
          this.raporFarkList = respone.data;           
        }
        else {
          this.raporFarkList = []; 
          this.yetkiService.error("Herhangi bir fark bulunmamaktadır",1);   
        }
        this.yukleniyor = false;   
      });
    }
  }

  BirimleriGetir() {
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19,44,20]; //2,6,10,19,44,20 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul, Koordinatörlük, Formasyon
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {      
      this.birimDto = respone.data;   
      this.birimID = -1;
      this.bolumID = 0;    
      this.abdID = 0;          
      this.programDto = [];         
    });
  }

  BolumGetir() {
    this.birimParam.ustbirimid = this.birimID;
    this.birimParam.tip = 'Bolum';
    this.birimParam.birimtur = []; 
    this.birimParam.durum = [];
    this.programDto = [];
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      if (this.birimID != -1) {
        if (respone.data.length > 0) {
          this.bolumDto = respone.data;                              
        } else {
          this.bolumDto = [];
          this.programDto = [];                     
        }         
      }
      else {           
        this.bolumDto = [];
        this.programDto = [];                
      } 
      this.bolumID = 0;
      this.abdID = 0;      
      this.birimHata = null;
    });    
  }

  AnabilimdaliGetir() {
    this.birimParam.ustbirimid = this.bolumID;
    this.birimParam.tip = 'Program';
    this.birimParam.birimtur = []; 
    this.birimParam.durum = [];
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      if (this.bolumID != -1) {
        if (respone.data.length > 0) {          
          this.programDto = respone.data;
        } else {
          this.programDto = [];          
          this.abdID = 0;         
        }        
      }
      else {       
        this.programDto = [];
        this.abdID = 0;      
      }
    });
  }


  exportexcel(fileName: string,  tableName: string): void {    
    var element = document.getElementById(tableName); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
