import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { BirimService } from 'src/app/services/birim.service';
import { RaporSinavDto, RaporSinavDtoParam } from 'src/app/models/Raporlar.model';
import { RaporService } from 'src/app/services/rapor.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { OgrenimTurlerDto } from 'src/app/models/OgrenimTurler.model';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';


@Component({
  selector: 'raporsinav',
  templateUrl: './raporsinav.component.html',
  styleUrls: ['./raporsinav.component.css']
})

export class RaporSinavComponent implements OnInit {

  constructor(private raporService: RaporService,
    private birimService: BirimService,
    public yetkiService: YetkiService,
    private dersProgramService: HaftalikDersProgramService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.service = raporService;
  }

  listForm = this.formBuilder.group({
    raporForm: this.formBuilder.group(new RaporSinavDto()),
  });

  raporForm(): FormGroup {
    return this.listForm.get('raporForm') as FormGroup;
  }

  yukleniyor = false;
  service: RaporService;
  raporSinavList: RaporSinavDto[] = [];
  raporSinavParam: RaporSinavDtoParam = new RaporSinavDtoParam();
  birimListDto: BirimlerDto[] = [];
  birimParam: BirimParam = new BirimParam();
  birimDto: any[] = [];
  bolumDto: any[] = [];
  birimID: number = 0;
  bolumID: number = 0;
  tarih1: string = '';
  tarih2: string = '';
  sinavTipi: string = '';
  ogrenimTur: string = '';
  ogrenimTurDto: OgrenimTurlerDto[] = [];

  birimHata: string | null = '';
  tarih1Hata: string | null = '';
  tarih2Hata: string | null = '';

  secilibirim: BirimlerDto | undefined;

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.BirimleriGetir();
    this.DersProgramOgrenimTurlerGetir();
  }

  GetRaporSinav() {
    let hataBirim = this.secilibirim == undefined ? 'Lütfen Birim Seçiniz.' : null;
    let hataTarih1 = this.tarih1 == '' ? 'Lütfen Başlangıç Tarihi Giriniz.' : null;
    let hataTarih2 = this.tarih2 == '' ? 'Lütfen Bitiş Tarihi Giriniz.' : null;
    this.birimHata = hataBirim;
    this.tarih1Hata = hataTarih1;
    this.tarih2Hata = hataTarih2;
    if ((!this.birimHata) && (!this.tarih1Hata) && (!this.tarih2Hata)) {
      this.yukleniyor = true;
      this.raporSinavParam.birim_id = this.birimID.toString();
      this.raporSinavParam.bolum_id = this.bolumID.toString();
      this.raporSinavParam.tarih1 = this.tarih1;
      this.raporSinavParam.tarih2 = this.tarih2;
      this.raporSinavParam.ogrenim_turu = this.ogrenimTur;
      this.raporSinavParam.sinav_tipi = this.sinavTipi;
      this.service.getListRaporSinav(this.raporSinavParam).subscribe(respone => {
        if (respone.data.length > 0) {
          this.raporSinavList = respone.data;
        }
        else {
          this.raporSinavList = [];
        }
        this.yukleniyor = false;
      });
    }
  }

  DersProgramOgrenimTurlerGetir() {
    this.dersProgramService.getDersProgramOgrenimTurlerList().subscribe(respone => {
      this.ogrenimTurDto = respone.data;
      this.ogrenimTur = '';
    });
  }

  BirimleriGetir() {
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19,44,20]; //2,6,10,19,44,20 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul, Koordinatörlük, Formasyon
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      this.birimDto = respone.data;
      this.birimID = 0;
      this.bolumID = 0;
    });
  }

  BolumGetir(item: BirimlerDto) {
    this.secilibirim = item;
    if (item != undefined) {
      this.birimID = item.birimid;
      this.birimParam.ustbirimid = item.birimid ?? 0;
      this.birimParam.tip = 'Bolum';
      this.birimParam.birimtur = [];
      this.birimParam.durum = [];
      this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
        if (item.birimid != 0) {
          if (respone.data.length > 0) {
            this.bolumDto = respone.data;
          } else {
            this.bolumDto = [];
          }
        }
        else {
          this.bolumDto = [];
        }
        this.bolumID = 0;
        this.birimHata = null;
      });
    }
    else {
      this.bolumDto = [];
      this.bolumID = 0;
      this.birimID = 0;
    }
  }

  exportexcel(fileName: string, tableName: string): void {
    var element = document.getElementById(tableName);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
