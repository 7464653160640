import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { GridComponent } from 'src/app/components/grid/grid.component';
//import { BirimComponent } from 'src/app/modules/yonetim/birim/birim.component';
import { BirimParam } from 'src/app/models/Birimler.model';
import { GenelService } from 'src/app/services/genel.service';
import { BirimService } from 'src/app/services/birim.service';
import { AkademisyenService } from 'src/app/services/akademisyen.service';
import { OgrencilerDto } from 'src/app/models/Ogrenciler.model';
import { OgrenciService } from 'src/app/services/ogrenci.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'ogrenci',
  templateUrl: './ogrenci.component.html',
  styleUrls: ['./ogrenci.component.css']
})

export class OgrenciComponent implements OnInit {
  
  constructor(private ogrenciService: OgrenciService, 
              private birimService: BirimService, 
              private akademisyenService: AkademisyenService, 
              public yetkiService: YetkiService, 
              private genelService: GenelService, 
              private formBuilder: FormBuilder
              ) {
    this.service = ogrenciService;
   }

   listForm = this.formBuilder.group({
    ogrenciForm: this.formBuilder.group(new OgrencilerDto()),
  });

  ogrenciForm(): FormGroup {
    return this.listForm.get('ogrenciForm') as FormGroup;
  }

  yukleniyor=false;
  service: OgrenciService;  
  ogrenciListDto: OgrencilerDto[] = [];
  birimParam: BirimParam = new BirimParam();
  birimDto: any[] = [];
  bolumDto: any[] = [];
  akademisyenDto: any[] = [];
  birimID: number = -1;
  bolumID: number = 0; 
  adSoyad: string = '';
  personelID: number = 0;
  personelTC: string = '';

  birimHata: string | null = '';
  //bolumHata: string | null = '';

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.BirimleriGetir();
    //this.AkademisyenGetir(0);  
  } 
 
  GetListByOgrenci() {
    var secbirimID : number = 0;
    let hataBirim = this.birimID == -1 ? 'Lütfen Birim Seçiniz.' : null;
    //let hataBolum = this.bolumID == 0 ? 'Lütfen Bölüm Seçiniz.' : null;    
    this.birimHata = hataBirim;    
    //this.bolumHata = hataBolum;     
    if ((!this.birimHata)) {   //&& (!this.bolumHata)
      if (this.bolumID != 0) {   secbirimID = this.bolumID;}
      else if (this.birimID != -1) {   secbirimID = this.birimID;} 
      this.yukleniyor = true;
      this.service.getAllOgrenciList(secbirimID, this.adSoyad, this.personelID, this.personelTC).subscribe(respone => {
        this.ogrenciListDto = respone.data;       
        this.yukleniyor = false;  
      });
      this.birimHata = null;
      //this.bolumHata = null;
    }
  }

  BirimleriGetir() {                    
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = '';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [10]; //2,6,10,19 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul 
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      this.birimDto = respone.data;      
      this.birimID = -1;
      this.bolumID = 0;      
    });
  }

  BolumGetir() {
    this.birimParam.ustbirimid = this.birimID;//item.target.value;
    this.birimParam.tip = '';
    this.birimParam.birimtur = [];
    this.birimParam.durum = [];
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      if (this.birimID != -1) {
        if (respone.data.length > 0) {
          this.bolumDto = respone.data;  
          this.AkademisyenGetir(this.birimID);                              
        } else {
          this.bolumDto = [];                     
        }         
      }
      else {           
        this.bolumDto = [];             
      }            
      this.bolumID = 0;  
      this.birimHata = null;      
    });    
  } 

  AkademisyenGetir(birimid : number=0) {
    /* this.akademisyenService.getAllAkademisyenList(birimid, 0, 0, '', 0, '', 'H').subscribe(respone => {
      this.akademisyenDto = respone.data;      
      this.personelID = 0;    
    }); */    
    this.service.getAllOgrenciAkademisyenList(birimid).subscribe(respone => {
      this.akademisyenDto = respone.data;      
      this.personelTC = ""; 
    });
    //this.bolumHata = null; 
  }

  
  exportexcel(fileName: string,  tableName: string): void {    
    var element = document.getElementById(tableName); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
