<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-table me-2 text-success" style="font-size: large;"></i>Ücrete Yansıyan Ders
                        Yükü Formu
                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporDersYukuKBS.DersYuku.Yazdir'])" class="col-md-6"
                style="text-align: right;">
                <!--  <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('Ders Yükü Formu.xlsx','bl_subscriptions_table')" style="color: green;">
                    
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button> -->
                <button type="button" class="btn btn-success btn-sm" [disabled]="ciktialiniyor"
                    (click)="DersYukuYazdir()" [attr.data-kt-indicator]="ciktialiniyor ? 'on' : 'off'"
                    data-bs-toggle="modal" data-bs-target="#belge_modal" data-toggle="modal">
                    <span class="indicator-label">
                        <i class="fas fa-file-word"></i>Yazdır
                    </span>
                    <span class="indicator-progress">Bekleyiniz...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="bl_post">
        <div id="bl_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <table id="bl_subscriptions_table" style="width:100%; background-color:white;">
                    <tr class="textsatir">
                        <td colspan="8">Birim / Fakülte Adı : {{birimAdi}}</td>
                    </tr>
                    <tr class="textsatir">
                        <td colspan="8">Başlangıç / Bitiş Tarihi : {{ilktarih}} - {{sontarih}}</td>
                    </tr>
                    <!-- <tr class="textsatir">
                        <td colspan="8">Bitiş Tarihi : {{sontarih}}</td>
                    </tr> -->
                    <tr class="textsatir">
                        <td colspan="8">Öğrenim Türü : {{ogrTur}}</td>
                    </tr>
                    <tr>
                        <td colspan="6" class="textsatir">ÖĞRETİM ELEMANININ</td>
                        <td class="textsatir">
                            <div *ngFor="let in of counter(haftadongu) ;let i = index" class="hafta">
                                <div>
                                    {{i+1}}. HAFTA
                                </div>
                            </div>
                        </td>
                        <td rowspan="2" class="textsatir" style="width: 80px; min-width: 80px;">Toplam EkDers Saati</td>
                    </tr>
                    <tr class="textsatir">
                        <td style="width: 30px; min-width: 30px;">Sıra</td>
                        <td style="width: 160px; min-width: 160px;">Görev Ünvanı</td>
                        <td style="width: 100px; min-width: 100px;">TC Kimlik No</td>
                        <td style="width: 80px; min-width: 80px;">Ünvan</td>
                        <td style="width: 150px; min-width: 150px;">Akademisyen Adı</td>
                        <td style="width: 35px; min-width: 35px;">Tip</td>
                        <td>
                            <div *ngFor="let item of dersYukuGunList" class="gun">
                                <div class="gun_baslik">
                                    <div>{{item.gun}}</div>
                                </div>
                                <div>
                                    <div *ngIf="item.gun_no != 0;">{{item.gun_no}}</div>
                                    <div *ngIf="item.gun_no == 0;">&nbsp;</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngFor="let item of dersYukuList">
                        <td style="text-align: center">
                            {{ item.sira_no}}
                        </td>
                        <td>
                            {{ item.idari_gorev_unvan}}
                        </td>
                        <td>
                            {{ item.akademisyen_tc}}
                        </td>
                        <td>
                            {{ item.unvan_kisa}}
                        </td>
                        <td>
                            {{ item.akademisyen_adi}}
                        </td>
                        <td style="text-align: center;">
                            <span *ngIf="item.ders_tipi == 'T' && item.akademisyen_adi != 'TOPLAM'; else Tdegil">Teo.</span>
                            <ng-template #Tdegil>
                                <span *ngIf="item.ders_tipi == 'U' && item.akademisyen_adi != 'TOPLAM'; else Udegil">Uyg.</span>
                                <ng-template #Udegil>
                                    <span *ngIf="item.akademisyen_adi != 'TOPLAM'">
                                        <div>
                                            <div style="border-bottom: solid 1px #000;">
                                                <div>Uyg.</div>
                                            </div>
                                            <div>
                                                <div>Teo.</div>
                                            </div>
                                        </div>
                                    </span>
                                </ng-template>
                            </ng-template>
                        </td>
                        <td>
                            <div *ngFor="let itemgun of dersYukuGunList" class="gun"
                                [ngStyle]="{'background-color' : itemgun.gun_no == 0 ? 'silver':'none'}">
                                <div *ngFor="let itemdetay of item.dersyuku_detay">
                                    <div *ngIf="itemgun.gun_tarih == itemdetay.gun_tarih && itemgun.gun_no != 0 && itemdetay.durum == '';"
                                        class="col-md-12"
                                        [ngStyle]="{'border-bottom': item.ders_tipi == 'UT' && itemdetay.ders_tipi == 'Uygulama' ?'solid 1px #000':'none'}">
                                        {{itemdetay.ders_saat}}
                                    </div>
                                    <div *ngIf="itemgun.gun_tarih == itemdetay.gun_tarih && itemgun.gun_no != 0 && itemdetay.durum != '';"
                                        class="col-md-12" style="color: red;">
                                        {{itemdetay.durum}}
                                    </div>
                                </div>
                                <div *ngFor="let itemhafta of item.dersyuku_hafta">
                                    <div *ngIf="itemgun.yilhafta == itemhafta.yilhafta && itemgun.gun_no == 0;"
                                        class="col-md-12">{{ itemhafta.hafta_saat}}</div>
                                </div>
                            </div>
                        </td>
                        <td class="textsatir">
                            {{ item.toplam_saat}}
                        </td>
                    </tr>
                </table>
            </loading>
        </div>
    </div>
</div>

<div id="belge_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <i class="far fa-print me-2 text-primary" style="font-size: large; padding-right:2px;"></i>
                <h2 class="modal-title">Ders Yükü Çıktısı</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="border-top: #36d35d 1px solid;padding:10px 0">
                <div *ngIf="previewUrl; else bekleg">
                    <iframe id="iframe" name="iframe" frameBorder="10" [src]="previewUrl" marginHeight="0"
                        marginWidth="0" width="100%" height="600px" scrolling="auto">
                    </iframe>
                </div>
                <ng-template #bekleg>
                    <span>Lütfen bekleyiniz...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </ng-template>

            </div>
        </div>
    </div>
</div>