import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { RaporDigerUniversiteDto } from 'src/app/models/Raporlar.model';
import { RaporService } from 'src/app/services/rapor.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';


@Component({  
  selector: 'rapordigeruniversite',
  templateUrl: './rapordigeruniversite.component.html',
  styleUrls: ['./rapordigeruniversite.component.css']
})

export class RaporDigerUniversiteComponent implements OnInit {
  
  constructor(private raporService: RaporService, 
              public yetkiService: YetkiService,               
              private formBuilder: FormBuilder,
              private router: Router
              ) {
    this.service = raporService;
   }

   listForm = this.formBuilder.group({
    raporForm: this.formBuilder.group(new RaporDigerUniversiteDto()),
  });

  raporForm(): FormGroup {
    return this.listForm.get('raporForm') as FormGroup;
  }

  yukleniyor=false;
  service: RaporService;  
  raporDigerUniversiteList: RaporDigerUniversiteDto[] = [];

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {    
    this.GetRaporDigerUniversite();  
  } 
 
  GetRaporDigerUniversite() {   
      this.yukleniyor = true;      
      this.service.getListRaporDigerUniversite('UNIVERSITE').subscribe(respone => {
        if (respone.data.length > 0) {
          this.raporDigerUniversiteList = respone.data;           
        }
        else {
          this.raporDigerUniversiteList = [];     
        }
        this.yukleniyor = false;   
      });    
  }

  exportexcel(fileName: string,  tableName: string): void {    
    var element = document.getElementById(tableName); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
