import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { DersYukuDetayDto, DersYukuDetayParam, DersYukuDto, DersYukuGunDto } from 'src/app/models/DersYuku.model';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';


@Component({  
  selector: 'dersyuku-kbs',
  templateUrl: './dersyuku-kbs.component.html',
  styleUrls: ['./dersyuku-kbs.component.css']
})

export class DersYukuKbsComponent implements OnInit {
  
  constructor(private dersyukuService: HaftalikDersProgramService,               
              public yetkiService: YetkiService,
              private route: ActivatedRoute,
              private datepipe: DatePipe
              ) {
    this.service = dersyukuService;
   }

  yukleniyor=false;
  service: HaftalikDersProgramService;     
  dersYukuList: DersYukuDto[] = []; 
  dersYukuKbsList: DersYukuDetayDto[] = [];
  dersYukuGunList: DersYukuGunDto[] = []; 
  dersYukuKbsParam: DersYukuDetayParam = new DersYukuDetayParam();
  birimID: number = 0;  
  bolumID: number = 0;  
  birimAdi: string = '';
  tarih1: string = ''; 
  tarih2: string = '';
  ogrTur: string = '';
  tclist : any[] = [];
  bolumlist : any[] = [];
  dongu: number = 0;
  haftadongu: number = 0;
  ilktarih : any; 
  sontarih : any;

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(qparams => {
        if (qparams['tc_list']) {
          this.tclist = qparams['tc_list'];       
          this.birimAdi = qparams['birim_adi'];  
          this.bolumID = qparams['bolum_id'];               
          this.tarih1 = qparams['tarih_1'];
          this.tarih2 = qparams['tarih_2'];
          this.ogrTur = qparams['ogr_tur'];     
          this.bolumlist = qparams['bolum_list'];                 
        }
      })
      
      if (params['birimID']) {
        this.birimID = params['birimID'];    
        this.route.queryParams.subscribe(qryparams => {
          if (qryparams['tc_list']) {       
            this.tclist = qryparams['tc_list'];       
            this.birimAdi = qryparams['birim_adi'];    
            this.bolumID = qryparams['bolum_id'];     
            this.tarih1 = qryparams['tarih_1'];
            this.tarih2 = qryparams['tarih_2'];
            this.ogrTur = qryparams['ogr_tur']; 
            this.bolumlist = qryparams['bolum_list'];
            this.GetListByDersYukuKbs(params['birimID'], qryparams['bolum_id'], qryparams['tarih_1'], qryparams['tarih_2'], 
                        qryparams['ogr_tur'], qryparams['tc_list'], qryparams['bolum_list']);                            
          }        
        })
        
      }
    }); 
    
  } 

  counter(i: number) {
    return new Array(i);
  }
  
  GetListByDersYukuKbs(birimID: number = 0, bolumID: number = 0, tarih1: string = '', tarih2: string = '', 
                ogrTur: string='', tclist: any[], bolumlist: any[]) {       
    this.yukleniyor = true;    
    this.dongu = 0;     
    this.haftadongu = 0;
    this.ilktarih = this.datepipe.transform(tarih1, 'dd.MM.yyyy');  
    this.sontarih = this.datepipe.transform(tarih2, 'dd.MM.yyyy'); 
    this.dersYukuKbsParam.birim_id = birimID.toString();
    this.dersYukuKbsParam.tclist = tclist;    
    this.dersYukuKbsParam.bolum_id = bolumID.toString();
    this.dersYukuKbsParam.tarih1 = tarih1;
    this.dersYukuKbsParam.tarih2 = tarih2;
    this.dersYukuKbsParam.ogrenim_turu = ogrTur;
    this.dersYukuKbsParam.bolum_list = bolumlist; 
    this.service.getAllDersYukuGun('KBS', tarih1, tarih2).subscribe(respone => {
      this.dersYukuGunList = respone.data;  
      this.dersYukuGunList.forEach(e => {                 
        if (this.haftadongu < e.hafta_no){
          this.haftadongu = e.hafta_no;
        }              
      });                 
      
      this.service.getAllDersYukuKbs(this.dersYukuKbsParam).subscribe(respone => {
        this.dersYukuList = respone.data;   
        this.dersYukuList.forEach(e => {                 
          if (this.dongu < e.gun_sayisi){
            this.dongu = e.gun_sayisi;
          }              
        });  
        this.yukleniyor = false;   
      });
    }); 
  }  
  
  exportexcel(fileName: string,  tableName: string): void {    
    var element = document.getElementById(tableName); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
