type Nullable<T> = T | null;
export class HaftalikDersProgram {
    id:number=0;
    portal_id:number=0;
    akademisyen_id:number=0;
    akademisyen_tc:string='';
    akademisyen_tipi:string='';
    akademik_takvim_id:number=0;
    donem_id:number=0;
    kadro_birim_id:number=0;
    kadro_bolum_id:number=0;
    kadro_program_id:number=0;
    calistigi_birim_id:number=0;
    unvan_id:number=0;
    idari_gorev_id:number=0;
    idari_gorev_vekaleten:boolean=false;
    idari_gorev_tedviren:boolean=false;
    hafta_id:number=0;
    yilhafta:string='';
    birim_id:number=0;
    bolum_id:number=0;
    program_id:number=0;
    sube_id:number=0;
    derslik_id:number=0;
    ders_id:number=0;
    ders_adi:string='';
    ders_tipi:string='';
    ders_turu:string='';
    sinif:string='';
    baslama_saati:string='';
    bitis_saati:string='';
    gun_tarih?:  Nullable<Date> = null;
    gun_no:number=0;
    kac_saat:number=0;
    blok_ders:boolean=false;
    kurum_disi:boolean=false;
    ogrenim_turu:string='';
    renk:string='';
    titletext:string='';
    onay:string='';
    onay_tc:string='';
    onay_tarihi?:  Nullable<Date> = null;
    idy_tc: string='';
    idt?:  Nullable<Date> = null;
    sdy_tc: string='';
    sdt?:  Nullable<Date> = null;
}

export class HaftalikDersProgramOzet {    
    tip: string = '';
    renk: string = '';
    ders_saat: number = 0;    
}

export class HaftalikDersProgramiHaftaGun {    
    pazartesi: string = '';
    sali: string = '';
    carsamba: string = '';
    persembe: string = '';
    cuma: string = '';
    cumartesi: string = '';
    pazar: string = '';   
}

export class HaftalikDersProgramiTumDersler {    
    akademisyen_id:number=0;
    akademisyen_tc:string='';
    akademisyen_adi:string='';
    unvan_kisaadi:string='';
    akademik_takvim_id:number=0;
    donem_adi:string='';    
    birim_id:number=0;
    birim_adi:string='';  
    bolum_id:number=0;
    bolum_adi:string='';  
    program_id:number=0;
    program_adi:string='';      
    ders_id:number=0;
    ders_adi:string='';
    ders_turu:string='';
    ogrenim_turu:string='';
    ders_tipi:string='';    
    teo_saat:number=0;
    uyg_saat:number=0;  
}

export class RaporTanim {
    id:string='';
    data:string='';
    format:number=0;
    name:string='';
    kod:string='';
}

export class PageParam {
    tc:string='';
    unvan:string='';
    ad_soyad:string='';
    ad_soyad_unvan:string='';
    birim_id :number=0;
}