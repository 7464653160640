import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { GenelService } from 'src/app/services/genel.service';
import { BirimService } from 'src/app/services/birim.service';
import { AkademikTakvimService } from 'src/app/services/akademiktakvim.service';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { AkademisyenService } from 'src/app/services/akademisyen.service';
import { AkademikTakvimDto } from 'src/app/models/AkademikTakvim.model';
import { DatePipe } from '@angular/common';
import { HaftaDto } from 'src/app/models/Haftalar.model';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs';
import { ValidationService } from 'src/app/services/validation.service';
import { HaftalikDersProgram } from 'src/app/models/HaftalikDersProgram.model';
import { AkademisyenlerDto, AkademisyenParam } from 'src/app/models/Akademisyenler.model';



@Component({
  selector: 'topluhesap',
  templateUrl: './topluhesap.component.html',
  styleUrls: ['./topluhesap.component.css']
})

export class TopluHesapComponent implements OnInit {

  constructor(private cizelgeService: HaftalikDersProgramService,
              private birimService: BirimService,
              public yetkiService: YetkiService,
              private genelService: GenelService,
              private validationService: ValidationService,
              private akademiktakvimService: AkademikTakvimService,
              private akademisyenService: AkademisyenService,
              private formBuilder: FormBuilder,
              private datepipe: DatePipe
              ) {
    this.service = cizelgeService;
   }

   listForm = this.formBuilder.group({
    hesapForm: this.formBuilder.group(new HaftalikDersProgram()),
  });

  hesapForm(): FormGroup {
    return this.listForm.get('hesapForm') as FormGroup;
  }

  yukleniyor=false;
  kaydediliyor = false;
  hatalar: any = {};
  service: HaftalikDersProgramService;
  birimParam: BirimParam = new BirimParam();
  birimDto: any[] = [];
  bolumDto: any[] = [];
  birimID: number = 0;
  bolumID: number = 0;
  haftaDto: any[] = [];
  haftaID: number = 0;
  yilhafta: string = '';
  akademikTakvimDto: AkademikTakvimDto[] = [];
  akademisyenDto: any[] = [];
  akademisyenParam: AkademisyenParam = new AkademisyenParam();
  akademisyenTC: string = '';
  ilktarih : any; //'19.09.2022';
  sontarih : any; //'30.12.2022';

  birimHata: string | null = '';
  haftaHata: string | null = '';

  secilibirim : BirimlerDto |undefined;
  secilihafta : HaftaDto |undefined;
  seciliakademisyen : AkademisyenlerDto |undefined;

  ngOnInit(): void {
    this.BirimleriGetir();
  }

  TopluHesapla() {
    Swal.fire({
      title: 'Seçilen haftada seçilen kriterlere göre toplu hesaplama yapmak istediğinize emin misiniz ?',
      text: 'TOPLU HESAPLA İŞLEMİ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Hesapla',
      cancelButtonText: 'Vazgeç',
    }).then((result) => {
      if (result.value) {
        this.Hesapla();
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Vazgeçildi", "Hesaplama yapılmadı", 'error')
      }
    })
  }

  Hesapla() {
    let hataBirim = this.secilibirim == undefined ? 'Lütfen Birim Seçiniz.' : null;
    let hataHafta = this.secilihafta == undefined ? 'Lütfen Hafta Seçiniz.' : null;
    this.birimHata = hataBirim;
    this.haftaHata = hataHafta;
    if ((!this.birimHata) && (!this.haftaHata)) {
      this.kaydediliyor = true;
      this.hatalar = {};
      this.service.multiHesap(this.birimID, this.akademisyenTC, this.yilhafta).pipe(finalize(() => {
        this.kaydediliyor = false;
      })).subscribe({
        next: res => {
          if (res?.message && res?.message != "" ) {this.yetkiService.success(res?.message,1)};
        },
        error: err => {
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          if (err.error.Errors == undefined ||err.error.Errors == null ||err.error.Errors?.length==0){
            if (err.error?.message && err.error?.message != "" ) {this.yetkiService.error(err.error?.message,1)};
            if (err.error?.Message && err.error?.Message != "" ) {this.yetkiService.error(err.error?.Message,1)};
          }
        }
      });
    }
  }

  BirimleriGetir() {
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19,44,20]; //2,6,10,19,44,20 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul, Koordinatörlük, Formasyon
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      this.birimDto = respone.data;
      this.birimID = 0;
      this.bolumID = 0;
      this.haftaID = 0;
    });
  }

  BolumGetir(item: BirimlerDto) {
    this.secilibirim = item;
    if (item!=undefined){
      this.birimID = item.birimid;
      //this.birimParam.ustbirimid = item.birimid?.toString() ?? 0;
      //this.birimParam.birimtur = [];
      this.HaftalariGetir(this.birimID);
      this.AkademisyenGetir(this.birimID);
      /* this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
        if (item.birimid != 0) {
          if (respone.data.length > 0) {
            this.bolumDto = respone.data;
          } else {
            this.bolumDto = [];
          }
        }
        else {
          this.bolumDto = [];
        }
        this.bolumID = 0;
        this.birimHata = null;
      }); */
    }
    else {
      this.bolumDto = [];
      this.bolumID = 0;
      this.birimID = 0;
      this.haftaID = 0;
      this.birimHata = null;
    }
  }

  AkademisyenGetir(birimid : number=0) {
    this.akademisyenParam.kadrobirimid = birimid;
    this.akademisyenService.getAllAkademisyenList(this.akademisyenParam).subscribe(respone => {
      this.akademisyenDto = respone.data;
    });
  }

  AkademisyenBilgiVer(item: AkademisyenlerDto) {
    this.seciliakademisyen = item;
    if (item!=undefined){
      this.akademisyenTC = item.tc;
    }
    else {
      this.akademisyenTC = '';
    }
  }

  HaftalariGetir(birim_id: number = 0) {
    this.akademiktakvimService.getAkademikTakvim(birim_id, true, false).subscribe(respone => {
      this.akademikTakvimDto = respone.data;
      this.akademikTakvimDto.forEach(e => {
        this.ilktarih = this.datepipe.transform(e.baslamaTarihi, 'dd.MM.yyyy');
        if ( e.enstituUzmanlikBitisTarihi==null && e.enstituUzmanlikBitisTarihi==undefined){
          this.sontarih = this.datepipe.transform(e.bitisTarihi, 'dd.MM.yyyy');
        } else {
          this.sontarih = this.datepipe.transform(e.enstituUzmanlikBitisTarihi, 'dd.MM.yyyy');
        }
      });

      this.genelService.getHaftaList('',this.ilktarih, this.sontarih).subscribe(respone => {
        this.haftaDto = respone.data;
        this.haftaID = 0;
        if (respone.data.length > 0) {
            this.haftaDto.forEach(e => {
            if (e.bu_hafta == true)
            {
              this.haftaID = e.id;
            }
          });
        }
        else {
          this.haftaID = 0;
        }
      });
    });

  }

  YilHafta(item: HaftaDto) {
    this.secilihafta = item;
    if (item!=undefined){
      this.yilhafta = item.yil.toString() + (item.hafta_id.toString().length == 1 ? '0'+ item.hafta_id.toString() : item.hafta_id.toString());
      this.haftaID = item.id;
      this.haftaHata = null;
    }
    else {
      this.yilhafta = '';
      this.haftaID = 0;
    }
  }

}
