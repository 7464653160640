import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { DersYukuDetayDto, DersYukuDetayParam, DersYukuDto, DersYukuGunDto, DersYukuHaftaDto } from 'src/app/models/DersYuku.model';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { RaporTanim } from 'src/app/models/HaftalikDersProgram.model';
import { finalize } from 'rxjs';
import { ValidationService } from 'src/app/services/validation.service';


@Component({  
  selector: 'dersyuku-detay',
  templateUrl: './dersyuku-detay.component.html',
  styleUrls: ['./dersyuku-detay.component.css']
})

export class DersYukuDetayComponent implements OnInit {
  
  constructor(private dersyukuService: HaftalikDersProgramService,               
              public yetkiService: YetkiService,
              private validationService: ValidationService, 
              private route: ActivatedRoute,
              private datepipe: DatePipe,
              private sanitizer: DomSanitizer
              ) {
    this.service = dersyukuService;
   }

  yukleniyor=false;
  ciktialiniyor: boolean = false;
  service: HaftalikDersProgramService;     
  dersYukuList: DersYukuDto[] = []; 
  //dersYukuDetayList: DersYukuDetayDto[] = [];
  //dersYukuHaftaList: DersYukuHaftaDto[] = [];
  dersYukuGunList: DersYukuGunDto[] = []; 
  dersYukuDetayParam: DersYukuDetayParam = new DersYukuDetayParam();
  birimID: number = 0;
  bolumID: number = 0;
  programID: number = 0;
  birimAdi: string = '';
  tarih1: string = ''; 
  tarih2: string = '';
  ogrTur: string = '';
  tclist : any[] = [];
  bolumlist : any[] = [];
  dongu: number = 0;
  haftadongu: number = 0;
  ilktarih : any; 
  sontarih : any;
  previewUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl("");
  rapor: RaporTanim = new RaporTanim();
  hatalar: any = {};

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(qparams => {
        if (qparams['tc_list']) {
          this.tclist = qparams['tc_list'];       
          this.birimAdi = qparams['birim_adi'];   
          this.birimID = qparams['birim_id'];    
          this.programID = qparams['program_id'];       
          this.tarih1 = qparams['tarih_1'];
          this.tarih2 = qparams['tarih_2'];
          this.ogrTur = qparams['ogr_tur'];   
          this.bolumlist = qparams['bolum_list'];              
        }
      })
      
      if (params['bolumID']) {
        this.bolumID = params['bolumID'];  
        this.route.queryParams.subscribe(qryparams => {
          if (qryparams['tc_list']) {       
            this.tclist = qryparams['tc_list'];       
            this.birimAdi = qryparams['birim_adi'];   
            this.birimID = qryparams['birim_id'];   
            this.programID = qryparams['program_id'];        
            this.tarih1 = qryparams['tarih_1'];
            this.tarih2 = qryparams['tarih_2'];
            this.ogrTur = qryparams['ogr_tur']; 
            this.bolumlist = qryparams['bolum_list'];
            this.GetListByDersYukuDetay(qryparams['birim_id'], params['bolumID'], qryparams['program_id'], 
                     qryparams['tarih_1'], qryparams['tarih_2'], qryparams['ogr_tur'], qryparams['tc_list'], qryparams['bolum_list']);                            
          }        
        })
        
      }
    }); 
    
  } 

  counter(i: number) {
    return new Array(i);
  }
  
  GetListByDersYukuDetay(birimID: number = 0, bolumID: number = 0, programID: number = 0, tarih1: string = '', tarih2: string = '', 
                      ogrTur: string='', tclist: any[], bolumlist: any[]) {       
    this.yukleniyor = true;    
    this.dongu = 0;     
    this.haftadongu = 0;
    this.ilktarih = this.datepipe.transform(tarih1, 'dd.MM.yyyy');  
    this.sontarih = this.datepipe.transform(tarih2, 'dd.MM.yyyy'); 
    this.dersYukuDetayParam.birim_id = birimID.toString();
    this.dersYukuDetayParam.tclist = tclist;    
    this.dersYukuDetayParam.bolum_id = bolumID.toString();
    this.dersYukuDetayParam.program_id = programID.toString();
    this.dersYukuDetayParam.tarih1 = tarih1;
    this.dersYukuDetayParam.tarih2 = tarih2;
    this.dersYukuDetayParam.ogrenim_turu = ogrTur;
    this.dersYukuDetayParam.bolum_list = bolumlist; 
    console.log(bolumlist);
    this.service.getAllDersYukuGun('', tarih1, tarih2).subscribe(respone => {
      this.dersYukuGunList = respone.data;  
      this.dersYukuGunList.forEach(e => {                 
        if (this.haftadongu < e.hafta_no){
          this.haftadongu = e.hafta_no;
        }              
      });                 
      
      this.service.getAllDersYukuDetay(this.dersYukuDetayParam).subscribe(respone => {
        this.dersYukuList = respone.data;   
        this.dersYukuList.forEach(e => {                 
          if (this.dongu < e.gun_sayisi){
            this.dongu = e.gun_sayisi;
          } 
          e.tarih_araligi = this.ilktarih + ' - ' + this.sontarih;          
          e.birim_adi = this.birimAdi;
          e.ogrenim_turu = this.ogrTur;
        });  
        this.yukleniyor = false;   
      });
    }); 
  }  

  DersYukuYazdir() {
    this.ciktialiniyor=true;
      this.service.dersyukuBelgeAl(this.dersYukuList).pipe(finalize(() => {
      })).subscribe({
        next: res => {
          // format 0=pdf, 1=html view
          this.rapor.format = 1;
          this.rapor.data = res.data.jsonData;
          //this.rapor.id = "16"; //rapor id = 16 Ders Yükü çıktısı   
                    
          if (this.haftadongu == 1) {
            this.rapor.kod = "21S5XJJ3"; 
          }   
          else if (this.haftadongu == 2) {
            this.rapor.kod = "3IBPZPKA"; 
          }
          else if (this.haftadongu == 3) {
            this.rapor.kod = "4HMCWEQ9"; 
          }
          else if (this.haftadongu == 4) {
            this.rapor.kod = "93YN9MJS"; 
          }
          else if (this.haftadongu == 5) {
            this.rapor.kod = "YFPLRSE3"; 
          }
          else { // 6
            this.rapor.kod = "UTYDLDGD"; 
          }           
          this.rapor.name = "Ücrete Yansıyan Ders Yükü Formu";

          this.service.downloadFastReport("https://neurapor.erbakan.edu.tr/app/raporlama", this.rapor).pipe(finalize(() => {
          this.ciktialiniyor=false})).subscribe({
            next: res1 => {
              //console.log("datapdf", res1.data.url);      
              this.previewUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(res1.data.url);
            //  window.open(res1.url);
            },
            error: err => {
              if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
                if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
                if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
              }             
            }
          });  
  
        },
        error: err => {
          this.hatalar = this.validationService.hatalar(err.error.Errors);         
          if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
            if (err.error?.message && err.error?.message != "") { this.yetkiService.sweetAlertError(err.error?.message) };
            if (err.error?.Message && err.error?.Message != "") { this.yetkiService.sweetAlertError(err.error?.Message) };
          }          
        }
      });
  
    }
  
  exportexcel(fileName: string,  tableName: string): void {    
    var element = document.getElementById(tableName); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
