<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-table me-2 text-success" style="font-size: large;"></i>Verilen Tüm Dersler Formu
                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.TumDersForm.ExcelAktar'])" class="col-md-6" style="text-align: right;">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('Verilen Tüm Dersler Formu.xlsx','bl_subscriptions_table')" style="color: green;">
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button>

            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="bl_post">
        <div id="bl_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <table id="bl_subscriptions_table" style="width:100%; background-color:white;">
                    <tr class="textbaslik">
                        <td colspan="11">Verilen Dersler</td>
                    </tr>
                    <tr class="textbaslik">
                        <td>Ünvanı</td>
                        <td>Adı Soyadı</td>
                        <td>Dönem</td>
                        <td>Birim</td>
                        <td>Bölüm</td>
                        <td>Program</td>
                        <td>Öğrenim Türü</td>
                        <td>Ders Türü</td>
                        <td>Ders Adı</td>
                        <td>Teo. Saat</td>
                        <td>Uyg. Saat</td>
                    </tr>
                    <tr *ngFor="let item of dersprogramTumDersList">
                        <td>{{item.unvan_kisaadi}}</td>
                        <td>{{item.akademisyen_adi}}</td>
                        <td>{{item.donem_adi}}</td>
                        <td>{{item.birim_adi}}</td>
                        <td>{{item.bolum_adi}}</td>
                        <td>{{item.program_adi}}</td>
                        <td>{{item.ogrenim_turu}}</td>
                        <td>{{item.ders_turu}}</td>
                        <td>{{item.ders_adi}}</td>
                        <td>{{item.teo_saat}}</td>
                        <td>{{item.uyg_saat}}</td>
                    </tr>
                </table>
            </loading>
        </div>
    </div>
</div>
