<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-list me-2 text-success" style="font-size: large;"></i>Akademisyen Birim
                        Güncelle
                    </span>
                </h1>
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="bl_post">
        <div id="bl_content_container" class="container-fluid">
            <div class="card" style="border-top: 3px #77c2e9 solid; ">
                <div class="card-body" style="padding:1.5rem">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-floating mb-5">
                                <input type="text" class="form-control" id="personelTC"
                                    [ngModelOptions]="{standalone: true}" placeholder="TC Kimlik No" name="personelTC"
                                    [(ngModel)]="personelTC" />
                                <label style="color: #009ef7;" class="fw-bold" for="personelTC">TC Kimlik No</label>
                                <span class="form-text text-danger fs-6" *ngIf="tcHata">{{tcHata}}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-floating mb-5">
                                <select class="form-select" id="birimID" [(ngModel)]="birimID" name="birimID"
                                    [ngModelOptions]="{standalone: true}" (change)="BolumGetir()">
                                    <option [ngValue]=-1>Lütfen Seçiniz... </option>
                                    <option *ngFor="let item of birimDto" [ngValue]="item.birimid">
                                        {{item.birimadi}}
                                    </option>
                                </select>
                                <label style="color: #009ef7;" class="fw-bold" for="birimID">Birim Adı</label>
                                <span class="form-text text-danger fs-6" *ngIf="birimHata">{{birimHata}}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-floating mb-5">
                                <select class="form-select" id="abdID" [(ngModel)]="abdID" name="abdID"
                                    [ngModelOptions]="{standalone: true}">
                                    <option [ngValue]=0>Lütfen Seçiniz... </option>
                                    <option *ngFor="let item of programDto" [ngValue]="item.birimid">
                                        {{item.birimadi}}
                                    </option>
                                </select>
                                <label style="color: #009ef7;" class="fw-bold" for="abdID">Anabilimdalı Adı</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-floating mb-5" style="vertical-align: middle;">
                                <button
                                    class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                    (click)="GetListByAkademisyenTC(personelTC)" style="color: #009ef7;">
                                    <i class="fas fa-list text-white"></i>
                                    Akademisyen Getir
                                </button>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-floating mb-5">
                                <select class="form-select" id="bolumID" [ngModelOptions]="{standalone: true}"
                                    name="bolumID" (change)="AnabilimdaliGetir()" [(ngModel)]="bolumID">
                                    <option [ngValue]=-1>Lütfen Seçiniz... </option>
                                    <option *ngFor="let item of bolumDto" [ngValue]="item.birimid">
                                        {{item.birimadi}}
                                    </option>
                                </select>
                                <label style="color: #009ef7;" class="fw-bold" for="bolumID">Bölüm Adı</label>
                                <span class="form-text text-danger fs-6" *ngIf="bolumHata">{{bolumHata}}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-floating mb-5" style="vertical-align: middle;">
                                <button
                                    class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                    (click)="Guncelle()" style="color: #009ef7;">
                                    <i class="fas fa-list text-white"></i>
                                    Akademisyen Güncelle
                                </button>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
            <div class="card-body py-4" style="border-top: #f5c6c6 1px solid; background-color:white; ">
                <loading [loader]="yukleniyor">
                    <div style="overflow:auto;width: 100%;">
                        <table class="table table-striped" id="bl_subscriptions_table">
                            <thead>
                                <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 " style="color:#009ef7;">
                                    <th>No</th>
                                    <th class="min-w-125px">Adı</th>
                                    <th class="min-w-125px">Soyadı</th>
                                    <th class="min-w-125px">Ünvan</th>
                                    <th class="min-w-125px">Kadro Birimi</th>
                                    <th class="min-w-125px">Kurum Sicil No</th>
                                    <th class="min-w-125px">E-Mail</th>
                                    <th class="min-w-125px">Cep Telefonu</th>
                                    <th class="min-w-125px">Aktif</th>
                                </tr>
                            </thead>
                            <tbody class="text-gray-600 fw-bold">
                                <tr *ngFor="let item of akademisyenDto">
                                    <td>
                                        {{ item.personelid }}
                                    </td>
                                    <td>
                                        {{ item.ad}}
                                    </td>
                                    <td>
                                        {{ item.soyad}}
                                    </td>
                                    <td>
                                        {{ item.akunvan == null ? item.unvan : item.akunvan}}
                                    </td>
                                    <td>
                                        {{ item.kadrobirimuzun}}
                                    </td>
                                    <td>
                                        {{ item.kurumsicilno}}
                                    </td>
                                    <td>
                                        {{ item.kurummail }}
                                    </td>
                                    <td>
                                        {{ item.ceptel}}
                                    </td>
                                    <td>
                                        {{ item.kayitdurumu }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </loading>
            </div>
        </div>
    </div>
</div>