export class DerslerDto {
    id: number=0;
    birimid: number=0;
    ders_id: string='';
    ders_kodu: string='';
    ders_adi: string='';
    dersturu: string='';
    dersturu_id: number=0;
    derstipi: string='';
    donem_id: number=0;
    donemad: string='';
    sinif: string=''; 
    kredi: string='';
    akts: string='';
    teo_saat: string='';
    uyg_saat: string='';
    lab_saat: string='';
    hoca_tc: string='';
    personel_id: number=0;
    akademisyenTC: string='';
    yoksisid: string='';
    birimAdi: string='';
} 

export class DersProgramDerslerDto {
    ders_id:number=0;
    akademik_takvim_id:number=0; 
    donem_id: number=0;   
    birim_id:number=0;
    bolum_id:number=0;     
    program_id:number=0;   
    ders_turu:string='';
    ders_tipi:string='';
    ogrenim_turu:string='';
    birim_adi:string='';
    bolum_adi:string='';
    ders_adi:string='';   
    ders_saat:number=0;
}
