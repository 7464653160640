<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-list me-2 text-success" style="font-size: large;"></i>Öğrenci Listesi
                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.Ogrenci.ExcelAktar'])" class="col-md-6" style="text-align: right;">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('ÖğrenciListesi.xlsx','bl_subscriptions_table')" style="color: green;">                    
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button>

            </div>
        </div>
    </div>
    <form [formGroup]="listForm">
        <div class="post d-flex flex-column-fluid" id="bl_post">
            <div id="bl_content_container" class="container-fluid">

                <div class="card" style="border-top: 3px #77c2e9 solid; ">
                    <div class="card-body" style="padding:1.5rem">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <input type="text" class="form-control" id="adSoyad"
                                        [ngModelOptions]="{standalone: true}" placeholder="Adı Soyadı" name="adSoyad"
                                        [(ngModel)]="adSoyad" />
                                    <label style="color: #009ef7;" class="fw-bold" for="adSoyad">Adı Soyadı</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="birimID" [(ngModel)]="birimID" name="birimID"
                                        [ngModelOptions]="{standalone: true}" (change)="BolumGetir()">
                                        <option [ngValue]=-1>Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of birimDto" [ngValue]="item.birimid">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="birimID">Birim Adı</label>
                                    <span class="form-text text-danger fs-6" *ngIf="birimHata">{{birimHata}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="personelTC" [(ngModel)]="personelTC"
                                        name="personelTC" [ngModelOptions]="{standalone: true}">
                                        <option [ngValue]="">Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of akademisyenDto" [ngValue]="item.personel_tc">
                                            {{item.akademisyenad}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="personelTC">Akademisyen
                                        Adı</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="bolumID" [(ngModel)]="bolumID" name="bolumID"
                                        [ngModelOptions]="{standalone: true}">                                       
                                        <option [ngValue]=0>Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of bolumDto" [ngValue]="item.birimid">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="bolumID">Bölüm Adı</label>
                                    <!-- <span class="form-text text-danger fs-6" *ngIf="bolumHata">{{bolumHata}}</span> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-left">
                                <div class="form-floating mb-5" style="vertical-align: middle;">
                                    <button
                                        class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                        (click)="GetListByOgrenci()" style="color: #009ef7;">
                                        <i class="fas fa-list text-white"></i>
                                        Öğrencileri Listele
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body py-4" style="border-top: #f5c6c6 1px solid; background-color:white; ">
                    <loading [loader]="yukleniyor">
                        <div style="overflow:auto;width: 100%;">
                            <table class="table table-striped" id="bl_subscriptions_table">
                                <thead>
                                    <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 " style="color:#009ef7;">
                                        <th>No</th>
                                        <th class="min-w-125px">Adı</th>
                                        <th class="min-w-125px">Soyadı</th>
                                        <th class="min-w-125px">Öğrenci No</th>
                                        <th class="min-w-125px">Birim Adı</th>
                                        <th class="min-w-125px">Ayrılış Tarihi</th>
                                        <th class="min-w-125px">Akademisyen Adı</th>
                                        <th class="min-w-125px">Öğrenim Tipi</th>
                                        <th class="min-w-125px">Aktif</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-600 fw-bold">
                                    <tr *ngFor="let item of ogrenciListDto">
                                        <td>
                                            {{ item.ogrid }}
                                        </td>
                                        <td>
                                            {{ item.ad}}
                                        </td>
                                        <td>
                                            {{ item.soyad}}
                                        </td>
                                        <td>
                                            {{ item.ogrno}}
                                        </td>
                                        <td>
                                            {{ item.birimadi}}
                                        </td>
                                        <td>
                                            {{ item.ayrilis_tarihi}}
                                        </td>
                                        <td>
                                            {{ item.akademisyenad }}
                                        </td>
                                        <td>
                                            {{ item.ogrenimtipi}}
                                        </td>
                                        <td>
                                            {{ item.aktif }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </loading>
                </div>
            </div>
        </div>
    </form>
</div>