<div class="card " style="border-top: 4px #77c2e9 solid;">
    <div class="card-header border-0 pt-1">
        <div class="card-title">
            <div class="d-flex align-items-center position-relative my-1">
                <span class="svg-icon  svg-icon-danger position-absolute ms-6" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" 
                        height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="17.0365" y="15.1223"
                            width="8.15546" height="2" rx="1"
                            transform="rotate(45 17.0365 15.1223)"
                            fill="currentColor" />
                        <path
                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                            fill="currentColor" />
                    </svg>
                </span>
                <input (input)="searchChange($event)" type="text" 
                    class="form-control w-300px ps-14" style="border-color: #8fb9d5;"
                    placeholder="ARA"/>
            </div>
        </div>
        <div class="card-toolbar">
        </div>
    </div>
    <div class="card-body py-4">
        <loading [loader]="loading">
            <ng-content></ng-content>
        </loading>
        <div class="d-flex flex-stack flex-wrap pt-10">
            <div class="fs-6 fw-bold ">
                Toplam Kayıt: {{recordCount}}
            </div>
            <ul class="pagination" *ngIf="pageCount>1">
                <li class="page-item previous">
                    <a (click)="goToPage(page - 1)"
                        class="page-link cursor-pointer">
                        <i class="previous"></i>
                    </a>
                </li>
                <li class="page-item"
                    *ngFor="let index of [].constructor(pageCount); let i = index;"
                    [ngClass]="{'active': i+1==page}">
                    <a (click)="goToPage(i+1)"
                        class="page-link cursor-pointer">{{i+1}}</a>
                </li>
                <li class="page-item next">
                    <a (click)="goToPage(page + 1)"
                        class="page-link cursor-pointer">
                        <i class="next"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
