<div *ngIf="loader; else showLoaded" class="text-center m-20">
    <img src="/assets/image/Logo TR1.png" style="width:12%;"/>  
    <br>
    <br>
    <span class="h4 ms-4 text-primary"> <span class="spinner-border spinner-primary spinner-border-lg align-middle text-primary"></span> Lütfen bekleyin...</span>

    <!-- <span class="spinner-border spinner-primary spinner-border-lg align-middle text-primary"></span>
    <span class="h4 ms-4 text-primary">Lütfen bekleyin...</span> -->
</div>
<ng-template #showLoaded>
    <ng-content></ng-content>
</ng-template>