<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="far fa-snowflake me-2 text-success" style="font-size: large;"></i>Resmi Tatil
                        Tanımları
                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.ResmiTatil.Ekle'])" class="d-flex align-items-center gap-2 gap-lg-3">
                <a [routerLink]="['yeni']" class="btn btn-sm btn-primary">
                    <i class="fas fa-plus"></i>
                    Resmi Tatil Ekle</a>

            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">

            <grid #grid [service]="service" [(data)]="resmiTatiller" [rowCount]="20">
                <table class="table  table-hover  gn-middle table-row-bordered fs-6 gy-5" id="kt_subscriptions_table">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 " style="color:#009ef7;">
                            <th class="min-w-125px">Birim</th>
                            <th class="min-w-125px">Gün Tarih</th>
                            <th class="min-w-125px">Adı</th>
                            <th class="min-w-125px">Oluşturan</th>
                            <th class="min-w-125px">Oluşturma Tarihi</th>
                            <th *ngIf="yetkiService.yetkiVar(['EkDers.ResmiTatil.Degistir','EkDers.ResmiTatil.Sil'])" 
                                class="text-end min-w-70px">İşlemler</th>
                        </tr>
                    </thead>
                    <tbody class="text-gray-600 fw-bold">
                        <tr *ngFor="let resmiTatil of resmiTatiller" style="height: 30px;">
                            <td>
                                {{ resmiTatil.birim_adi }}
                            </td>
                            <td>
                                {{ resmiTatil.gun_tarih | date:'dd.MM.yyyy HH:mm':'IST' }}
                            </td>
                            <td>
                                {{ resmiTatil.adi}}
                            </td>
                            <td>
                                {{ resmiTatil.olusturan}}
                            </td>
                            <td>
                                {{ resmiTatil.idt | date:'dd.MM.yyyy HH:mm:ss':'IST' }}
                            </td>
                            <td *ngIf="yetkiService.yetkiVar(['EkDers.ResmiTatil.Degistir','EkDers.ResmiTatil.Sil'])" class="text-end">
                                <div class="d-flex justify-content-end flex-shrink-0">
                                    <a [routerLink]="[resmiTatil.id]" style="background-color:#dcdce1;" *ngIf="yetkiService.yetkiVar(['EkDers.ResmiTatil.Degistir'])" 
                                        class="btn btn-icon border border-primary btn-hover-rise  btn-sm me-1">
                                        <i class="fas fa-edit text-primary"></i>
                                    </a> 
                                    <button type="button" (click)="silModal(resmiTatil)" *ngIf="yetkiService.yetkiVar(['EkDers.ResmiTatil.Sil'])" 
                                        style="background-color:#dcdce1;" data-bs-toggle="modal"
                                        class="btn btn-icon btn-active-color-danger btn-sm border border-primary btn-hover-rise"
                                        data-bs-target="#sil_modal">
                                        <i class="far fa-trash-alt text-danger"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </grid>
        </div>
    </div>

    <div id="sil_modal" class="modal fade" tabindex="-1">
        <div class="modal-dialog  modal-dialog-centered mw-900px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title">Kayıt Sil</h2>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p *ngIf="silinecekResmiTatil">
                        {{silinecekResmiTatil?.adi}} kaydını silmek istediğinizden emin misiniz?
                    </p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">İptal</button>
                    <button type="button" class="btn btn-danger" (click)="sil(silinecekResmiTatil!)">Sil</button>
                </div>
            </div>
        </div>
    </div>