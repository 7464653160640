<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-map me-2 text-success" style="font-size: large;"></i>Telafi Düzenle
                        ({{akademisyen_AdSoyadUnvan}})
                    </span>
                </h1>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <form class="form" [formGroup]="detayForm" (submit)="kaydet()">
                    <div class="card col-8 offset-2" style="border-top: 2px #77c2e9 solid; ">
                        <div class="card-header">
                            <h3 class="card-title" *ngIf="yeni">
                                Yeni Telafi Ekle
                            </h3>
                            <h3 class="card-title" *ngIf="!yeni">
                                {{ telafi.ders_adi }} Telafisini Düzenle
                            </h3>
                        </div>
                        <div class="card-body" style="padding:1.5rem">
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-floating mb-5">
                                        <select class="form-select" id="seciliders"
                                            [ngModelOptions]="{standalone: true}" name="seciliders"
                                            [(ngModel)]="seciliders" (ngModelChange)="DersBilgiVer($event)">
                                            <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                            <option *ngFor="let item of dersDto" [ngValue]="item">
                                                {{ item.ders_adi }} {{ item.ogrenim_turu }} / {{ item.birim_adi }} / {{
                                                item.bolum_adi }} ({{item.ders_saat}} {{item.ders_tipi}})
                                            </option>
                                        </select>
                                        <label style="color: #009ef7;" class="fw-bold" for="seciliders">Ders Adı</label>
                                        <span class="form-text text-danger fs-6" *ngIf="dersHata">{{dersHata}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6">
                                    <div class="form-floating mb-5">
                                        <input formControlName="iptal_ders_tarihi" type="date"
                                            class="form-control datepicker" id="iptal_ders_tarihi"
                                            placeholder="iptal_ders_tarihi" name="iptal_ders_tarihi"
                                            [value]="this.detayForm.value.iptal_ders_tarihi | date:'yyyy-MM-dd'"
                                            style="border-color: #8fb9d5;" />
                                        <label style="color: #009ef7;" class="fw-bold" for="iptal_ders_tarihi">İptal
                                            Edilen Ders Tarihi</label>
                                        <div class="text-danger" *ngFor="let hata of hatalar['iptal_ders_tarihi']">
                                            {{hata}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-5">
                                        <input formControlName="telafi_tarihi" type="date"
                                            class="form-control datepicker" id="telafi_tarihi"
                                            placeholder="telafi_tarihi" name="telafi_tarihi"
                                            [value]="this.detayForm.value.telafi_tarihi | date:'yyyy-MM-dd'"
                                            style="border-color: #8fb9d5;" />
                                        <label style="color: #009ef7;" class="fw-bold" for="telafi_tarihi">Telafi
                                            Tarihi</label>
                                        <div class="text-danger" *ngFor="let hata of hatalar['telafi_tarihi']">
                                            {{hata}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6">
                                    <div class="form-floating mb-5">
                                        <input formControlName="baslama_saati" type="time" class="form-control time"
                                            id="baslama_saati" placeholder="Başlama Saati" name="baslama_saati" />
                                        <label style="color: #009ef7;" class="fw-bold" for="baslama_saati">Başlama
                                            Saati</label>
                                        <div class="text-danger" *ngFor="let hata of hatalar['baslama_saati']">
                                            {{hata}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-5">
                                        <input formControlName="ders_saati_sayisi" type="number" class="form-control"
                                            id="ders_saati_sayisi" name="ders_saati_sayisi" />
                                        <label style="color: #009ef7;" class="fw-bold" for="ders_saati_sayisi">Yapılacak
                                            Ders Saati</label>
                                        <div class="text-danger" *ngFor="let hata of hatalar['ders_saati_sayisi']">
                                            {{hata}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6">
                                <div class="form-floating mb-5">
                                    <input formControlName="resmi_yazi_tarihi" type="date"
                                        class="form-control datepicker" id="resmi_yazi_tarihi"
                                        placeholder="resmi_yazi_tarihi" name="resmi_yazi_tarihi"
                                        [value]="this.detayForm.value.resmi_yazi_tarihi | date:'yyyy-MM-dd'"
                                        style="border-color: #8fb9d5;" />
                                    <label style="color: #009ef7;" class="fw-bold" for="resmi_yazi_tarihi">Resmi Yazı
                                        Tarihi</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['resmi_yazi_tarihi']">
                                        {{hata}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating mb-5">
                                    <input formControlName="resmi_yazi_sayi" type="text" class="form-control"
                                        id="resmi_yazi_sayi" name="resmi_yazi_sayi" />
                                    <label style="color: #009ef7;" class="fw-bold" for="resmi_yazi_sayi">Resmi Yazı
                                        Sayısı</label>
                                    <div class="text-danger" *ngFor="let hata of hatalar['resmi_yazi_sayi']">
                                        {{hata}}
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="row card-footer  ">
                                <div class="col-6 text-left">
                                    <button type="button" [routerLink]="['/telafi', akademisyen_tc]"
                                        [queryParams]="{donem_id: donem_id, adsoyadunvan: akademisyen_AdSoyadUnvan}"
                                        id="kt_modal_new_target_cancel" class="btn btn-danger btn-hover-rise me-3">
                                        <i class="far fa-arrow-alt-circle-left"></i>Geri
                                    </button>
                                </div>
                                <div class="col-6 text-end">
                                    <submit-button [loader]="kaydediliyor"></submit-button>
                                    <!-- <submit-button
                                    [disabled]="!yetkiService.yetkiVar(['KartCihaz.Ekle','KartCihaz.Degistir'])"
                                    [loader]="kaydediliyor"></submit-button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </loading>
        </div>
    </div>
</div>
