import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cizelge, CizelgeToplam, CizelgeToplamParam, CizelgeParam, CizelgeRaporDto } from '../models/Cizelge.model';
import { DersProgramDerslerDto } from '../models/Dersler.model';
import { DersProgramDto } from '../models/DersProgramlar.model';
import { DersYukuDetayParam, DersYukuDto, DersYukuGunDto, DersYukuParam } from '../models/DersYuku.model';
import { HaftalikDersProgram, HaftalikDersProgramiHaftaGun, HaftalikDersProgramiTumDersler, HaftalikDersProgramOzet, RaporTanim } from '../models/HaftalikDersProgram.model';
import { OgrenimTurlerDto } from '../models/OgrenimTurler.model';
import { Response } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class HaftalikDersProgramService {
  apiUrl = '/api/haftalikDersProgrami'
  constructor(private http: HttpClient) { }

  getList(query: string = '', page: number = 1, rowCount: number = 20): Observable<Response<HaftalikDersProgram[]>> {
    return this.http.get<Response<HaftalikDersProgram[]>>(this.apiUrl + '/getList', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }  

  multiDeleteAdd(kayitDersProgram: DersProgramDto[]): Observable<Response<HaftalikDersProgram[]>> {    
    return this.http.post<Response<HaftalikDersProgram[]>>(this.apiUrl + "/multiDeleteAdd", kayitDersProgram);   
  }

  multiUpdate(akademisyentc: string, onay: string, donem: number = 0): Observable<Response<HaftalikDersProgram>> {    
    return this.http.post<Response<HaftalikDersProgram>>(this.apiUrl + '/multiUpdate?akademisyen_tc=' + akademisyentc + '&onay=' + onay + '&aktifDonem=' + donem,"",{});   
  }

  multiHesap(birimID: number = 0,akademisyentc: string = '', yilhafta: string = ''): Observable<Response<HaftalikDersProgram>> {    
    return this.http.post<Response<HaftalikDersProgram>>(this.apiUrl + '/multiHesap?birimID=' + birimID + '&akademisyen_tc=' + akademisyentc + '&yilhafta=' + yilhafta,"",{});   
  }

  multiDonemUzat(birimID: number = 0, bolumID: number = 0,akademisyentc: string = '', donemID: number = 0): Observable<Response<HaftalikDersProgram>> {    
    return this.http.post<Response<HaftalikDersProgram>>(this.apiUrl + '/multiDonemUzat?birimID=' + birimID + '&bolumID=' + bolumID + '&akademisyen_tc=' + akademisyentc + '&aktifDonem=' + donemID,"",{});   
  }

  getById(id: number): Observable<Response<HaftalikDersProgram>> {
    return this.http.get<Response<HaftalikDersProgram>>(this.apiUrl + '/getbyid?id=' + id);
  }

  getListTCNo(akademisyentc: string, hafta_id: number = 0, yilhafta: string ='', donem: number = 0): Observable<Response<HaftalikDersProgram[]>> {
    return this.http.get<Response<HaftalikDersProgram[]>>(this.apiUrl + '/getlisttcno?akademisyen_tc=' + akademisyentc + '&hafta_id=' + hafta_id + '&yilhafta=' + yilhafta + '&aktifDonem=' + donem);
  }  

  getListOzetSorgu(akademisyentc: string, hafta_id: number = 0, yilhafta: string ='', donem: number = 0): Observable<Response<HaftalikDersProgramOzet[]>> {
    return this.http.get<Response<HaftalikDersProgramOzet[]>>(this.apiUrl + '/getlistozet?akademisyen_tc=' + akademisyentc + '&hafta_id=' + hafta_id + '&yilhafta=' + yilhafta + '&aktifDonem=' + donem);
  }
 
  getDersProgramiByTC(akademisyentc: string): Observable<Response<DersProgramDto[]>> {
    return this.http.get<Response<DersProgramDto[]>>(this.apiUrl + '/dersprogramlist?AkademisyenTC=' + akademisyentc);
  } 

  getAllCizelgeList(cizelgeParam: CizelgeParam): Observable<Response<Cizelge[]>> {
    return this.http.post<Response<Cizelge[]>>(this.apiUrl + '/getlistcizelge' , cizelgeParam);    
  }

  getAllCizelgeToplam(cizelgeToplamParam: CizelgeToplamParam): Observable<Response<CizelgeToplam[]>> {
    return this.http.post<Response<CizelgeToplam[]>>(this.apiUrl + '/getlistcizelgetoplam' , cizelgeToplamParam);    
  }

  getAllDersYukuList(dersyukuParam: DersYukuParam): Observable<Response<DersYukuDto[]>> {
    return this.http.post<Response<DersYukuDto[]>>(this.apiUrl + '/getlistdersyuku' , dersyukuParam);    
  }

  getAllDersYukuDetay(dersyukuDetayParam: DersYukuDetayParam): Observable<Response<DersYukuDto[]>> {
    return this.http.post<Response<DersYukuDto[]>>(this.apiUrl + '/getlistdersyukudetay' , dersyukuDetayParam);    
  }

  getAllDersYukuKbs(dersyukuDetayParam: DersYukuDetayParam): Observable<Response<DersYukuDto[]>> {
    return this.http.post<Response<DersYukuDto[]>>(this.apiUrl + '/getlistdersyukukbs' , dersyukuDetayParam);    
  }

  getAllDersYukuGun(tur: string ='', tarih1: string ='', tarih2: string =''): Observable<Response<DersYukuGunDto[]>> {
    return this.http.get<Response<DersYukuGunDto[]>>(this.apiUrl + '/getlistdersyukugun', {
      params: {
        'tur': tur,
        'tarih1': tarih1,    
        'tarih2': tarih2
      }
    }); 
  }

  getAllHaftaGun(akademisyentc: string, hafta_id: number = 0, yilhafta: string ='', donem: number = 0): Observable<Response<HaftalikDersProgramiHaftaGun[]>> {
    return this.http.get<Response<HaftalikDersProgramiHaftaGun[]>>(this.apiUrl + '/getlisttchaftagun?akademisyen_tc=' + akademisyentc + '&hafta_id=' + hafta_id + '&yilhafta=' + yilhafta + '&aktifDonem=' + donem);
  }

  getAllTumDersler(akademisyentc: string, hafta_id: number = 0, yilhafta: string ='', donem: number = 0): Observable<Response<HaftalikDersProgramiTumDersler[]>> {
    return this.http.get<Response<HaftalikDersProgramiTumDersler[]>>(this.apiUrl + '/getlisttctumdersler?akademisyen_tc=' + akademisyentc + '&hafta_id=' + hafta_id + '&yilhafta=' + yilhafta + '&aktifDonem=' + donem);
  }

  getDersProgramOgrenimTurlerList(): Observable<Response<OgrenimTurlerDto[]>> {
    return this.http.get<Response<OgrenimTurlerDto[]>>(this.apiUrl + '/dersprogramogrenimturler');
  }

  getDersProgramDerslerList(akademisyentc: string): Observable<Response<DersProgramDerslerDto[]>> {
    return this.http.get<Response<DersProgramDerslerDto[]>>(this.apiUrl + '/dersprogramdersler?akademisyen_tc=' + akademisyentc);
  }

  cizelgeBelgeAl(cizelgeToplamListBelge:any[]): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/cizelgeBelgeAl', cizelgeToplamListBelge);
  }

  dersyukuBelgeAl(dersYukuListBelge:any[]): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + '/dersyukuBelgeAl', dersYukuListBelge);
  }


  downloadFastReport(url: string = '',rapor:RaporTanim): Observable<any> {
    return this.http.post<any>(url, rapor);
  }

  cizelgeRapor(): Observable<Response<CizelgeRaporDto>> {
    return this.http.get<Response<CizelgeRaporDto>>(this.apiUrl + '/cizelgeRapor');
  }


  /*delete(haftalikDersProgram: HaftalikDersProgram): Observable<Response<HaftalikDersProgram>> {
    return this.http.post<Response<HaftalikDersProgram>>(this.apiUrl + '/delete', haftalikDersProgram)
  }  

  dosyaYukle(formData: FormData, dersprogram_id: number): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + "/fileAdd?dersprogram_id=" + dersprogram_id + "", formData)
  }
  dosyaSil(dersprogram_id: number): Observable<Response<any>> {
    return this.http.post<Response<any>>(this.apiUrl + "/fileDelete?dersprogram_id=" + dersprogram_id + "", {})
  }

  dosyaIndir(dersprogram_id: number):Observable<any> {
    return this.http.get<any>(this.apiUrl + "/fileDownload/?dersprogram_id="+dersprogram_id)
  }

 
  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array]);
    return blob;
  }
 */
  
}
