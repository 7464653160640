<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-table me-2 text-success" style="font-size: large;"></i>Tüm Çizelgeler
                        (RP07-Toplu Özet Ders Raporu)

                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.Cizelge.Yazdir'])" class="col-md-6" style="text-align: right;">
                <!--  <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('Cizelgeler.xlsx','bl_subscriptions_table')" style="color: green;">
                    
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button> -->
                <button type="button" class="btn btn-success btn-sm" [disabled]="ciktialiniyor"
                    (click)="cizelgeYazdir()" [attr.data-kt-indicator]="ciktialiniyor ? 'on' : 'off'"
                    data-bs-toggle="modal" data-bs-target="#belge_modal" data-toggle="modal">
                    <span class="indicator-label">
                        <i class="fas fa-file-word"></i>Yazdır
                    </span>
                    <span class="indicator-progress">Bekleyiniz...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>

            </div> 
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="bl_post">
        <div id="bl_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <table id="bl_subscriptions_table" style="background-color:white;">
                    <tr class="textsatir">
                        <td colspan="9">DÖNEM BAŞLAMA / BİTİŞ TARİHİ: {{haftatanim}}</td>
                    </tr>
                    <tr class="textsatir">
                        <td colspan="9">AKADEMİK YIL VE DÖNEMİ: {{donemAdi}}</td>
                    </tr>
                    <tr class="textsatir">
                        <td colspan="9">ÖĞRETİM ELEMANLARI HAFTALIK YÜKE ESAS FAALİYETLERİN DAĞILIMI VE ÜCRETE
                            ESAS DERS YÜKÜ DAĞILIMI</td>
                    </tr>
                    <tr class="textsatir">
                        <td colspan="9">NECMETTİN ERBAKAN ÜNİVERSİTESİ - {{birimAdi}}</td>
                    </tr>
                    <tr>
                        <td rowspan="2" class="textsatir">No</td>
                        <td rowspan="2" class="textsatir">Akademisyen Adı</td>
                        <td rowspan="2" class="textsatir">Öğr. Tipi</td>
                        <td rowspan="2">
                            <div *ngFor="let in of counter(dongu) ;let i = index" class="blok_ders"
                                [ngStyle]="{'border-right': i+1 != dongu ?'solid 1px #000':'none'}">
                                <div class="ders_kolon">
                                    <div class="col-md-12">{{i+1}}</div>
                                </div>
                                <div class="ders_satir">
                                    <div class="separate">Teo.</div>
                                    <div>Uyg.</div>
                                </div>
                            </div>
                        </td>
                        <td colspan="5" class="textsatir">NECMETTİN ERBAKAN ÜNİVERSİTESİ</td>
                    </tr>
                    <tr>
                        <td class="textkolon">Teo.</td>
                        <td class="textkolon">Uyg.</td>
                        <td class="textkolon">Teo+Uyg</td>
                        <td class="textkolon">Zorunlu</td>
                        <td class="textsatir">Ücrete Esas Ek Ders</td>
                    </tr>
                    <tr *ngFor="let item of cizelgeToplamList"
                        [ngStyle]="{'border-top': item.ucret_tipi_id == 1 ?'solid 2px #000':'none'}">
                        <td rowspan="4" *ngIf="item.ucret_tipi_id == 1;" style="text-align: center">
                            {{ item.sira_no}}
                        </td>
                        <td rowspan="4" *ngIf="item.ucret_tipi_id == 1;">
                            {{ item.akademisyen_adi}}
                        </td>
                        <td>
                            {{ item.ucret_tipi}}
                        </td>
                        <td>
                            <div *ngFor="let itemhesap of item.cizelgehesap; let a = index " class="blok_ders"
                                [ngStyle]="{'border-right': a+1 != dongu ?'solid 1px #000':'none'}">
                                <div class="ders_baslik">
                                    <div class="col-md-12">{{ itemhesap.birim_adi}} - {{ itemhesap.ders_adi}}
                                    </div>
                                </div>
                                <div class="ders_satir">
                                    <div class="separate">
                                        <span>{{itemhesap.teo_saat}}</span>                                        
                                    </div>
                                    <div class="">
                                        <span>{{itemhesap.uyg_saat}}</span>
                                    </div>
                                    <!-- <div class="separate">
                                        <span *ngIf="itemhesap.ders_tipi=='Teorik'; else degil">{{
                                            itemhesap.ders_saat}}</span>
                                        <ng-template #degil>
                                            <span>0</span>
                                        </ng-template>
                                    </div>
                                    <div class="">
                                        <span *ngIf="itemhesap.ders_tipi=='Uygulama'; else degil">{{
                                            itemhesap.ders_saat}}</span>
                                        <ng-template #degil>
                                            <span>0</span>
                                        </ng-template>
                                    </div> -->
                                </div>
                            </div>
                        </td>
                        <td class="textsatir">
                            {{ item.toplam_teo}}
                        </td>
                        <td class="textsatir">
                            {{ item.toplam_uyg}}
                        </td>
                        <td class="textsatir">
                            {{ item.toplam_teo_uyg}}
                        </td>
                        <td class="textsatir">
                            {{ item.zorunlu_ders}}
                        </td>
                        <td rowspan="4" *ngIf="item.ucret_tipi_id == 1;"
                            style="min-width: 250px; width: 250px; font-size: 11px;" [innerHTML]="item.ucret_ekders">
                            <!--  {{item.ucret_ekders}} -->
                        </td>
                    </tr>
                </table>
                <!-- <table class="table table-striped" id="bl_subscriptions_table">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 " style="color:#009ef7;">
                            <th class="min-w-125px">sira_no</th>
                            <th class="min-w-125px">akademisyen_tc</th>                            
                            <th class="min-w-125px">akademisyen_adi</th>
                            <th class="min-w-125px">ucret_tipi</th>
                            <th class="min-w-125px">ogrenim_turu</th>    
                            <th class="min-w-125px">ders_tipi</th>
                            <th class="min-w-125px">birim_id</th>
                            <th class="min-w-125px">birim_adi</th>                                
                            <th class="min-w-125px">ders_id</th>
                            <th class="min-w-125px">ders_adi</th>    
                            <th class="min-w-125px">saat_durumu</th>
                            <th class="min-w-125px">ders_saat</th>                           
                        </tr>
                    </thead>  
                    <tbody class="text-gray-600 fw-bold">
                        <tr *ngFor="let item of cizelgeHesapList">
                            <td>
                                {{ item.sira_no}}
                            </td>
                            <td>
                                {{ item.akademisyen_tc}}                              
                            </td>                            
                            <td>
                                {{ item.akademisyen_adi}}
                            </td>
                            <td>
                                {{ item.ucret_tipi}}
                            </td>
                            <td>
                                {{ item.ogrenim_turu}}
                            </td>       
                            <td>
                                {{ item.ders_tipi }}
                            </td>                                  
                            <td>
                                {{ item.birim_id }}
                            </td> 
                            <td>
                                {{ item.birim_adi }}
                            </td> 
                            <td>
                                {{ item.ders_id }}
                            </td> 
                            <td>
                                {{ item.ders_adi }}
                            </td> 
                            <td>
                                {{ item.saat_durumu }}
                            </td> 
                            <td>
                                {{ item.ders_saat }}
                            </td>                                                                      
                        </tr>
                    </tbody>                
                </table> -->
                <!-- <table class="table table-striped" id="bl_subscriptions_table">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 " style="color:#009ef7;">
                            <th class="min-w-125px">sira_no</th>
                            <th class="min-w-125px">akademisyen_tc</th>                            
                            <th class="min-w-125px">akademisyen_adi</th>
                            <th class="min-w-125px">ucret_tipi</th>
                            <th class="min-w-125px">toplam_teo</th>    
                            <th class="min-w-125px">toplam_uyg</th>
                            <th class="min-w-125px">toplam_teo_uyg</th>
                            <th class="min-w-125px">zorunlu_ders</th>                                
                            <th class="min-w-125px">ucret_ekders</th>                                                      
                        </tr>
                    </thead>  
                    <tbody class="text-gray-600 fw-bold">
                        <tr *ngFor="let item of cizelgeToplamList">
                            <td>
                                {{ item.sira_no}}
                            </td>
                            <td>
                                {{ item.akademisyen_tc}}                              
                            </td>                            
                            <td>
                                {{ item.akademisyen_adi}}
                            </td>
                            <td>
                                {{ item.ucret_tipi}}
                            </td>
                            <td>
                                {{ item.toplam_teo}}
                            </td>       
                            <td>
                                {{ item.toplam_uyg }}
                            </td>                                  
                            <td>
                                {{ item.toplam_teo_uyg }}
                            </td> 
                            <td>
                                {{ item.zorunlu_ders }}
                            </td> 
                            <td style="min-width: 300px; width: 300px; font-size: 11px;" [innerHTML]="item.ucret_ekders">                                
                            </td>                                                                                             
                        </tr>
                    </tbody>                
                </table> -->
            </loading>
        </div>
    </div>
</div>

<div id="belge_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl">        
        <div class="modal-content">            
            <div class="modal-header">                
                <i class="far fa-print me-2 text-primary" style="font-size: large; padding-right:2px;"></i>
            <h2 class="modal-title">Çizelge Çıktısı</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="border-top: #36d35d 1px solid;padding:10px 0">
                <div *ngIf="previewUrl; else bekleg">
                    <iframe id="iframe" name="iframe" frameBorder="10" [src]="previewUrl" marginHeight="0"
                        marginWidth="0" width="100%" height="600px" scrolling="auto"> 
                    </iframe>
                </div> 
                <ng-template #bekleg>
                    <span>Lütfen bekleyiniz...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </ng-template> 
               
            </div>
        </div>
    </div>
</div>