import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TelafiIptal } from '../models/TelafiIptal.model';
import { Response } from '../models/response.model';
@Injectable({
  providedIn: 'root'
})
export class TelafiService {

  apiUrl = '/api/telafiIptal'

  constructor(private http: HttpClient) { }

  getAll(akademisyen_tc: string = '', donem: number = 0): Observable<Response<TelafiIptal[]>> {  
    return this.http.get<Response<TelafiIptal[]>>(this.apiUrl + '/getall?akademisyen_tc=' + akademisyen_tc + '&aktifDonem=' + donem);
  }

  getById(id: number): Observable<Response<TelafiIptal>> {    
    return this.http.get<Response<TelafiIptal>>(this.apiUrl + '/getbyid?id=' + id);
  }

  add(telafi: TelafiIptal): Observable<Response<TelafiIptal>> {   
    if (telafi.id > 0) {
      return this.http.post<Response<TelafiIptal>>(this.apiUrl + "/update", telafi)
    }
    else {
      return this.http.post<Response<TelafiIptal>>(this.apiUrl + "/add", telafi)
    }
  }

  delete(telafi: TelafiIptal): Observable<Response<TelafiIptal>> {
    return this.http.post<Response<TelafiIptal>>(this.apiUrl + '/delete', telafi)
  }
}
