import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { GirisService } from 'src/app/services/giris.service';

@Component({
  selector: 'giris-form',
  templateUrl: './giris-form.component.html',
  styleUrls: ['./giris-form.component.css']
})
export class GirisFormComponent {

  constructor(private girisService: GirisService, private router: Router) { }

  yukleniyor = false;
  yukleniyorEdevlet=false;
  hataVar = false;
  hataMesaj="";
  stringEdevletTc:string="";
  girisForm = new FormGroup({
    'tckimlikno': new FormControl(''),
    'sifre': new FormControl('')
  });

  ngOnInit(): void {  
    var searchstring = window.location.search.substring(0, 500);
    var control=searchstring.substring(1,12);
    if (control=="giris_durum"){
      var girisDurum=searchstring.substring(13,500);
      console.log("girisdurum",girisDurum);
      
      this.girisYapEdevlet(girisDurum);
    }
  }

 /*  girisYap() {
    this.yukleniyor = true;
    document.cookie='authEkders=';
    this.girisService.giris(parseInt(this.girisForm.controls['tckimlikno'].value), this.girisForm.controls['sifre'].value).subscribe(res => { 
      this.hataMesaj='';
      // localStorage.setItem('auth', JSON.stringify(res.data));
      
      document.cookie = 'authEkders=' +  JSON.stringify(res.data);
      //console.log(document.cookie);
      this.router.navigate(['/']);
    }, err => {   
      this.hataMesaj=err.error.message;
      this.hataVar = true;
      this.yukleniyor = false;
    });
  } */

  girisYap() {
    this.yukleniyor = true;
    this.girisService.giris(parseInt(this.girisForm.controls['tckimlikno'].value), this.girisForm.controls['sifre'].value).subscribe(res => {

      //document.cookie = 'authEkders=' +  JSON.stringify(res.data);
      localStorage.setItem('auth', JSON.stringify(res.data));
      this.router.navigate(['/']);
    }, err => {
      this.hataMesaj=err.error.message;
      this.hataVar = true;
      this.yukleniyor = false;
    });
  }


  girisYapEdevlet(girisdurum:string) {
    this.hataVar = false;
    this.yukleniyorEdevlet = true;
    this.girisService.girisEdevletLogin(girisdurum).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        this.yukleniyorEdevlet = false;
        this.hataMesaj="";
        // localStorage.setItem('auth', JSON.stringify(res.data));
        //document.cookie = 'authEkders=' +  JSON.stringify(res.data);
        localStorage.setItem('auth', JSON.stringify(res.data));
       // console.log(document.cookie);
        this.router.navigate(['/']);
      },
      error: err => {
        if (err?.error?.message && err.error?.message != "") { this.hataMesaj= err?.error?.message};
          if (err?.error?.Message && err.error?.Message != "") { this.hataMesaj= err?.error?.Message };
          this.hataVar = true;
          this.yukleniyorEdevlet = false;
      }
    }); 
  }

  /* girisYapNebis(data:string){
    this.hataVar = false;
    this.yukleniyor = true;
    this.girisService.girisNebisLogin(data).pipe(finalize(() => {
    })).subscribe({
      next: res => {
        this.yukleniyor = false;
        this.hataMesaj="";
        document.cookie = 'authEkders=' +  JSON.stringify(res.data);
        this.router.navigate(['/']);
      },
      error: err => {
        if (err?.error?.message && err.error?.message != "") { this.hataMesaj= err?.error?.message};
          if (err?.error?.Message && err.error?.Message != "") { this.hataMesaj= err?.error?.Message };
          this.hataVar = true;
          this.yukleniyor = false;
      }
    }); 
  } */
  

  girisEdevletLink() {
    window.location.href = "https://giris.erbakan.edu.tr/giris?oto_giris=1cfa8127-c73f-48fc-bb9f-38a207514696&uygulama_id=18";
  }
}
