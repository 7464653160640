import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { GenelService } from 'src/app/services/genel.service';
import { BirimService } from 'src/app/services/birim.service';
import { DerslerDto } from 'src/app/models/Dersler.model';
import { DersService } from 'src/app/services/ders.service';
import { YetkiService } from 'src/app/services/yetki.service';

import * as XLSX from 'xlsx';


@Component({
  selector: 'ders',
  templateUrl: './ders.component.html',
  styleUrls: ['./ders.component.css']
})

export class DersComponent implements OnInit {
  
  constructor(private dersService: DersService, 
              private birimService: BirimService, 
              public yetkiService: YetkiService, 
              private genelService: GenelService, 
              private formBuilder: FormBuilder
              ) {
    this.service = dersService;
   }

   listForm = this.formBuilder.group({
    dersForm: this.formBuilder.group(new DerslerDto()),
  });

  dersForm(): FormGroup {
    return this.listForm.get('dersForm') as FormGroup;
  }

  yukleniyor=false;
  service: DersService;  
  dersListDto: DerslerDto[] = [];
  birimListDto: BirimlerDto[] = [];
  birimParam: BirimParam = new BirimParam();
  birimDto: any[] = [];
  bolumDto: any[] = [];
  anabilimdaliDto: any[] = [];
  donemDto: any[] = [];
  derstipDto: any[] = [];
  birimID: number = -1;
  bolumID: number = -1;
  abdID: number = 0;
  donemID: number = 0;
  dersTipiID: number = 0;
  dersAdi: string = '';
  personelID: number = 0;
  personelTC: string = '';
  donemHata: string | null = '';
  turler:any[]=[];
  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.BirimleriGetir();    
    this.DonemleriGetir();
    this.DersTurleriGetir();
  } 
 
  GetListByDers() {
    var secbirimID : number = 1;
    let hataDonem = this.donemID == 0 ? 'Lütfen Dönem Seçiniz.' : null;
    this.donemHata = hataDonem;    
    if (!this.donemHata) {

      if (this.abdID != 0) {          secbirimID = this.abdID;  }
      else if (this.bolumID > 0) {   secbirimID = this.bolumID;}
      else if (this.birimID > 0) {   secbirimID = this.birimID;} 
      //alert(this.secbirimID)
      this.yukleniyor = true;
      this.service.getAllDersList(secbirimID, this.dersAdi, this.dersTipiID, this.donemID, this.personelID, this.personelTC).subscribe(respone => {
        this.dersListDto = respone.data;  
        this.yukleniyor = false;       
      });
    }
  }

  BirimleriGetir() {
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19]; //2,6,10,19 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul 
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      this.birimDto = respone.data;
      //this.listForm.value.dersForm.birimid=0;
      //this.listForm.value.dersForm.bolumID=0; 
      //this.listForm.value.dersForm.abdID=0;
      this.birimID = -1;
      this.bolumID = -1;
      this.abdID = 0;          
      this.anabilimdaliDto = [];   
    });
  }

  BolumGetir() {
    this.birimParam.ustbirimid = this.birimID;//item.target.value;
    this.birimParam.tip = 'Bolum';
    this.birimParam.birimtur = []; 
    this.birimParam.durum = [];
    this.anabilimdaliDto = [];
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      if (this.birimID != -1) {
        if (respone.data.length > 0) {
          this.bolumDto = respone.data;                              
        } else {
          this.bolumDto = [];
          this.anabilimdaliDto = [];                     
        }         
      }
      else {           
        this.bolumDto = [];
        this.anabilimdaliDto = [];    
        //this.listForm.value.dersForm.birimid=0;          
      }
      //this.listForm.value.dersForm.bolumID=0; 
      //this.listForm.value.dersForm.abdID=0;       
      this.bolumID = -1;
      this.abdID = 0; 
    });    
  }

  AnabilimdaliGetir() {
    this.birimParam.ustbirimid = this.bolumID;//item.target.value;
    this.birimParam.tip = 'Program';
    this.birimParam.birimtur = []; 
    this.birimParam.durum = [];
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      if (this.bolumID != -1) {
        if (respone.data.length > 0) {          
          this.anabilimdaliDto = respone.data;
        } else {
          this.anabilimdaliDto = [];          
          //this.listForm.value.dersForm.abdID=0;  
          this.abdID = 0;         
        }        
      }
      else {       
        this.anabilimdaliDto = [];
        // this.listForm.value.dersForm.abdID=0;   
        this.abdID = 0;      
      }
    });
  }

  DonemleriGetir() {
    this.genelService.getDonemlerList().subscribe(respone => {
      this.donemDto = respone.data;    
       if (respone.data.length > 0) {          
        this.donemID = this.donemDto[0].id;
      } 
      else {
        this.donemID = 0;  
      }    
    });
  }

  DersTurleriGetir() {
    this.genelService.getDersTurlerList().subscribe(respone => {
      this.derstipDto = respone.data;      
      this.dersTipiID = 0;      
    });
  }

  exportexcel(fileName: string,  tableName: string): void {    
    var element = document.getElementById(tableName); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
