<!--begin::Aside-->
<div id="kt_aside" class="aside aside-dark aside-hoverable" data-kt-drawer="true" data-kt-drawer-name="aside"
    data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_aside_mobile_toggle">
    <!--begin::Brand-->
    <div class="flex-column-auto text-center mt-5" id="kt_aside_logo">
        <!--begin::Logo-->
        <a [routerLink]="['/']">
            <img alt="Logo" src="/assets/image/neu-logo.png" class="h-100px logo" />
        </a>
    </div>
    <!--begin::Aside menu-->
    <div class="aside-menu flex-column-fluid">
        <!--begin::Aside Menu-->
        <div class="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside_menu"
            data-kt-scroll-offset="0">
            <!--begin::Menu-->
            <div class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                id="#kt_aside_menu" data-kt-menu="true" data-kt-menu-expand="false">
                <div class="menu-item">
                    <a class="menu-link" [routerLink]="['.']" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">
                        <span class="menu-bullet">
                            <i class="fas fa-paste text-white"></i>
                        </span>
                        <span class="menu-title">Anasayfa</span>
                    </a>
                </div>
                <div *ngIf="yetkiService.yetkiVar(['EkDers.Birim.Gorme','EkDers.Ders.Gorme','EkDers.Derslik.Gorme','EkDers.Ogrenci.Gorme','EkDers.Akademisyen.Gorme'])" 
                    data-kt-menu-trigger="click" class="menu-item menu-accordion">
                    <span class="menu-link">
                        <span class="menu-icon">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor" />
                                    <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor" />
                                    <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor" />
                                    <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor" />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="menu-title">Ekders Tanım Listeleri</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion menu-active-bg">
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.Birim.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['birim']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="fas fa-list"></span>
                                </span>
                                <span class="menu-title">Birimler</span>
                            </a>
                        </div>
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.Ders.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['ders']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="fas fa-list"></span>
                                </span>
                                <span class="menu-title">Dersler</span>
                            </a>
                        </div>
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.Derslik.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['derslik']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="fas fa-list"></span>
                                </span>
                                <span class="menu-title">Derslikler</span>
                            </a>
                        </div>
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.Ogrenci.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['ogrenci']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="fas fa-list"></span>
                                </span>
                                <span class="menu-title">Öğrenciler</span>
                            </a>
                        </div>
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.Akademisyen.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['akademisyen']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="fas fa-list"></span>
                                </span>
                                <span class="menu-title">Akademisyenler</span>
                            </a>
                        </div>                        
                    </div>
                </div>
                <div *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.Gorme'])" data-kt-menu-trigger="click" class="menu-accordion">
                    <span class="menu-item">
                        <a class="menu-link" [routerLink]="['dersprogram']" routerLinkActive="active">
                            <span class="menu-bullet"> 
                                <i class="fas fa-calendar-alt text-white"></i>
                            </span> 
                            <span class="menu-title">Ders Programları</span>
                        </a>
                    </span>
                </div>
                <div *ngIf="yetkiService.yetkiVar(['EkDers.Cizelge.Gorme'])" data-kt-menu-trigger="click" class="menu-accordion">                    
                    <span class="menu-item">
                        <a class="menu-link" [routerLink]="['cizelge']" routerLinkActive="active">
                            <span class="menu-bullet"> 
                                <i class="fas fa-table text-white"></i>
                            </span> 
                            <span class="menu-title">Tüm Çizelgeler</span>
                        </a>
                    </span>
                    <!-- <span class="menu-link">
                        <span class="menu-icon">                            
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor" />
                                    <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor" />
                                    <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor" />
                                    <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor" />
                                </svg>
                            </span>                            
                        </span>
                        <span class="menu-title">Çizelgeler</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion menu-active-bg">
                        <div class="menu-item">
                            <a class="menu-link" [routerLink]="['cizelge']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="fas fa-table"></span>
                                </span>
                                <span class="menu-title">Tüm Çizelgeler</span>
                            </a>
                        </div>
                        <div class="menu-item">
                            <a class="menu-link" [routerLink]="['dersprogram']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="fas fa-list"></span>
                                </span>
                                <span class="menu-title">Bekleyen Çizelgeler</span>
                            </a>
                        </div>  
                    </div> -->
                </div>
                <div *ngIf="yetkiService.yetkiVar(['EkDers.Cizelge.DetayGorme']) && yetkiService.yetkiVar(['EkDers.Akademisyen.KendiniGorme'])" data-kt-menu-trigger="click" class="menu-accordion">                    
                    <span class="menu-item">
                        <a class="menu-link" [routerLink]="['/cizelge-detay', birimID]" routerLinkActive="active"
                            [queryParams]="{tc_list: tcList, birim_adi: birimAdi, donem_adi: donemAdi, yil_hafta: yilhafta, hafta_tanim: haftaAciklama}">
                            <span class="menu-bullet"> 
                                <i class="fas fa-table text-white"></i>
                            </span> 
                            <span class="menu-title">Çizelgem</span>
                        </a>                       
                    </span>                   
                </div>
                <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporDersYukuKBS.Gorme'])" data-kt-menu-trigger="click" class="menu-accordion">
                    <span class="menu-item">
                        <a class="menu-link" [routerLink]="['dersyuku']" routerLinkActive="active">
                            <span class="menu-bullet"> 
                                <i class="far fa-file-alt text-white"></i>
                            </span> 
                            <span class="menu-title">Ders Yükü ve KBS Formu</span>
                        </a>
                    </span>
                </div> 
                <div *ngIf="yetkiService.yetkiVar(['EkDers.TopluHesapla'])" data-kt-menu-trigger="click" class="menu-accordion">
                    <span class="menu-item">
                        <a class="menu-link" [routerLink]="['topluhesap']" routerLinkActive="active">
                            <span class="menu-bullet"> 
                                <i class="fas fa-calculator text-white"></i>
                            </span> 
                            <span class="menu-title">Toplu Hesapla</span>
                        </a>
                    </span>
                </div>   
                <div *ngIf="yetkiService.yetkiVar(['EkDers.TopluDonemUzat'])" data-kt-menu-trigger="click" class="menu-accordion">
                    <span class="menu-item">
                        <a class="menu-link" [routerLink]="['topludonemuzat']" routerLinkActive="active">
                            <span class="menu-bullet"> 
                                <i class="fas fa-angle-double-right text-white"></i> 
                            </span> 
                            <span class="menu-title">Toplu Dönem Uzat</span>
                        </a>
                    </span>
                </div> 
                <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporDigerUniversite.Gorme','EkDers.RaporDigerAkademisyen.Gorme','EkDers.RaporDersSube.Gorme','EkDers.RaporTelafi.Gorme','EkDers.RaporSinav.Gorme'])"
                    data-kt-menu-trigger="click" class="menu-item menu-accordion">
                    <span class="menu-link">
                        <span class="menu-icon">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor" />
                                    <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor" />
                                    <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor" />
                                    <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor" />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="menu-title">Raporlar</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion menu-active-bg">
                        <!-- <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporDersYukuKBS.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['dersyuku']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-file-alt"></span>
                                </span>
                                <span class="menu-title">Ders Yükü ve KBS Formu</span>
                            </a>
                        </div> -->
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporDigerUniversite.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['rapordigeruniversite']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-file-alt"></span>
                                </span>
                                <span class="menu-title">Diğer Üniversitelerde Ders Verenler</span>
                            </a>
                        </div>
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporDigerAkademisyen.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['rapordigerakademisyen']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-file-alt"></span>
                                </span>
                                <span class="menu-title">Diğer Kurumlardan Gelip Ders Verenler</span>
                            </a>
                        </div>
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporDersSube.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['raporsubeders']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-file-alt"></span>
                                </span>
                                <span class="menu-title">Dersini Şubeye Bölenler</span>
                            </a>
                        </div>
                        <!-- <div class="menu-item">
                            <a class="menu-link" [routerLink]="['dersprogram']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-file-alt"></span>
                                </span>
                                <span class="menu-title">Aynı Dersi Verenler</span>
                            </a>
                        </div> -->
                        <!-- <div class="menu-item">
                            <a class="menu-link" [routerLink]="['dersprogram']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-file-alt"></span>
                                </span>
                                <span class="menu-title">Tez Danışmanlığı Verenler</span>
                            </a>
                        </div> -->
                        <!-- <div class="menu-item">
                            <a class="menu-link" [routerLink]="['dersprogram']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-file-alt"></span>
                                </span>
                                <span class="menu-title">Formasyon Dersi Verenler</span>
                            </a>
                        </div> -->
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporTelafi.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['raportelafi']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-file-alt"></span>
                                </span>
                                <span class="menu-title">Telafi Raporu</span>
                            </a>
                        </div>
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporSinav.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['raporsinav']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-file-alt"></span>
                                </span>
                                <span class="menu-title">Sınav Yükleri Raporu</span>
                            </a>
                        </div>
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporIzin.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['raporizin']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-file-alt"></span>
                                </span>
                                <span class="menu-title">İzin Raporu</span>
                            </a>
                        </div>
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporFark.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['raporfark']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-file-alt"></span>
                                </span>
                                <span class="menu-title">Fark Raporu</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div *ngIf="yetkiService.yetkiVar(['EkDers.AkademikTakvim.Gorme','EkDers.ResmiTatil.Gorme','EkDers.BirimGuncelle'])"
                    data-kt-menu-trigger="click" class="menu-item menu-accordion">
                    <span class="menu-link">
                        <span class="menu-icon">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor" />
                                    <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor" />
                                    <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor" />
                                    <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor" />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </span>
                        <span class="menu-title">Ayarlar</span>
                        <span class="menu-arrow"></span>
                    </span>
                    <div class="menu-sub menu-sub-accordion menu-active-bg">
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.AkademikTakvim.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['akademiktakvim']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-calendar-alt"></span>
                                </span>
                                <span class="menu-title">Akademik Takvim</span>
                            </a>
                        </div>
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.ResmiTatil.Gorme'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['resmitatil']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="far fa-snowflake"></span>
                                </span>
                                <span class="menu-title">Resmi Tatiller</span>
                            </a>
                        </div>
                        <div *ngIf="yetkiService.yetkiVar(['EkDers.BirimGuncelle'])" class="menu-item">
                            <a class="menu-link" [routerLink]="['akademisyenguncelle']" routerLinkActive="active">
                                <span class="menu-bullet">
                                    <span class="fas fa-user-edit"></span>                               
                                </span>
                                <span class="menu-title">Akademisyen Birim Güncelle</span>
                            </a>
                        </div>
                    </div>
                </div>
                <!--end::Menu-->
            </div>
            <!--end::Aside Menu-->
        </div>
        <!--end::Aside menu-->
        <!--begin::Footer-->
        <div class="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
            <a href="https://www.erbakan.edu.tr/" class="btn btn-custom btn-primary w-100" data-bs-toggle="tooltip"
                data-bs-trigger="hover" data-bs-dismiss-="click" title="200+ in-house components and 3rd-party plugins"
                target="_blank">
                <span class="btn-label"> <i class="fas fa-university"></i>NEÜ</span>
                <!--begin::Svg Icon | path: icons/duotune/general/gen005.svg-->

                <!--end::Svg Icon-->
            </a>
        </div>
        <!--end::Footer-->
    </div>
    <!--end::Aside-->