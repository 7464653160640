import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GirisComponent } from './modules/giris/giris.component';
import { GirisFormComponent } from './modules/giris/giris-form/giris-form.component';
import { YonetimComponent } from './modules/yonetim/yonetim.component';
import { YonetimAnasayfaComponent } from './modules/yonetim/yonetim-anasayfa/yonetim-anasayfa.component';
import { SiteComponent } from './modules/site/site.component';
import { AnasayfaComponent } from './modules/site/anasayfa/anasayfa.component';
import { SolMenuComponent } from './modules/yonetim/components/sol-menu/sol-menu.component';
import { UstMenuComponent } from './modules/yonetim/components/ust-menu/ust-menu.component';
import { AltMenuComponent } from './modules/yonetim/components/alt-menu/alt-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { BulunamadiComponent } from './modules/hata/bulunamadi/bulunamadi.component';
import { LoadingComponent } from './components/loading/loading.component';
import { GridComponent } from './components/grid/grid.component';
import { SubmitButtonComponent } from './components/submit-button/submit-button.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelect2Module } from 'ng-select2';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; 
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; 
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { DataTablesModule } from 'angular-datatables';
import { DatePipe } from '@angular/common';
import { HaftalikDersProgramComponent } from './modules/yonetim/dersprogram/dersprogram.component';
import { DerslikComponent } from './modules/yonetim/derslik/derslik.component';
import { BirimComponent } from './modules/yonetim/birim/birim.component';
import { DersComponent } from './modules/yonetim/ders/ders.component';
import { AkademisyenComponent } from './modules/yonetim/akademisyen/akademisyen.component';
import { OgrenciComponent } from './modules/yonetim/ogrenci/ogrenci.component';
import { HaftalikDersProgramDetayComponent } from './modules/yonetim/dersprogram/dersprogram-detay/dersprogram-detay.component';
import { CizelgeComponent } from './modules/yonetim/cizelge/cizelge.component';
import { CizelgeHesapComponent } from './modules/yonetim/cizelge/cizelge-detay/cizelge-detay.component';
import { AkademikTakvimComponent } from './modules/yonetim/akademiktakvim/akademiktakvim.component';
import { DersYukuComponent } from './modules/yonetim/dersyuku/dersyuku.component';
import { DersYukuDetayComponent } from './modules/yonetim/dersyuku/dersyuku-detay/dersyuku-detay.component';
import { DersYukuKbsComponent } from './modules/yonetim/dersyuku/dersyuku-kbs/dersyuku-kbs.component';
import { DersProgramFormComponent } from './modules/yonetim/dersprogram/dersprogram-form/dersprogram-form.component';
import { DersProgramTumDersComponent } from './modules/yonetim/dersprogram/dersprogram-tumders/dersprogram-tumders.component';
import { ResmiTatilComponent } from './modules/yonetim/resmitatil/resmitatil.component';
import { ResmiTatilDetayComponent } from './modules/yonetim/resmitatil/resmitatil-detay/resmitatil-detay.component';
import { TopluHesapComponent } from './modules/yonetim/topluhesap/topluhesap.component';
import { TopluDonemUzatComponent } from './modules/yonetim/topludonemuzat/topludonemuzat.component';
import { SinavComponent } from './modules/yonetim/dersprogram/sinav/sinav.component';
import { SinavDetayComponent } from './modules/yonetim/dersprogram/sinav/sinav-detay/sinav-detay.component';
import { TelafiComponent } from './modules/yonetim/dersprogram/telafi/telafi.component';
import { TelafiDetayComponent } from './modules/yonetim/dersprogram/telafi/telafi-detay/telafi-detay.component';
import { RaporTelafiComponent } from './modules/yonetim/raporlar/raportelafi/raportelafi.component';
import { RaporSinavComponent } from './modules/yonetim/raporlar/raporsinav/raporsinav.component';
import { RaporDigerUniversiteComponent } from './modules/yonetim/raporlar/rapordigeruniversite/rapordigeruniversite.component';
import { RaporDigerAkademisyenComponent } from './modules/yonetim/raporlar/rapordigerakademisyen/rapordigerakademisyen.component';
import { RaporSubeDersComponent } from './modules/yonetim/raporlar/raporsubeders/raporsubeders.component';
import { AkademisyenGuncelleComponent } from './modules/yonetim/akademisyenguncelle/akademisyenguncelle.component';
import { RaporIzinComponent } from './modules/yonetim/raporlar/raporizin/raporizin.component';
import { RaporFarkComponent } from './modules/yonetim/raporlar/raporfark/raporfark.component';


FullCalendarModule.registerPlugins([ 
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    GirisComponent,
    GirisFormComponent,
    YonetimComponent,
    YonetimAnasayfaComponent,
    SiteComponent,
    AnasayfaComponent,
    SolMenuComponent,
    UstMenuComponent,
    AltMenuComponent,
    BulunamadiComponent,
    LoadingComponent,
    GridComponent,
    SubmitButtonComponent,
    HaftalikDersProgramComponent,
    HaftalikDersProgramDetayComponent,
    DerslikComponent,
    BirimComponent,
    DersComponent,
    AkademisyenComponent,
    OgrenciComponent,
    CizelgeComponent,
    CizelgeHesapComponent,
    AkademikTakvimComponent,
    DersYukuComponent,
    DersYukuDetayComponent,
    DersYukuKbsComponent,
    DersProgramFormComponent,
    DersProgramTumDersComponent,
    ResmiTatilComponent,
    ResmiTatilDetayComponent,
    TopluHesapComponent,
    TopluDonemUzatComponent,
    SinavComponent,
    SinavDetayComponent,
    TelafiComponent,
    TelafiDetayComponent,
    RaporTelafiComponent,
    RaporSinavComponent,
    RaporDigerUniversiteComponent,
    RaporDigerAkademisyenComponent,
    RaporSubeDersComponent,
    AkademisyenGuncelleComponent,
    RaporIzinComponent,
    RaporFarkComponent,
  ],
 
  imports: [
    BrowserModule,    
    NgMultiSelectDropDownModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    NgSelect2Module,
    DataTablesModule,  
    FullCalendarModule,  
  ],
  providers: [DatePipe, { provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,      
    }, DatePipe
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
