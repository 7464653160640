<div class="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto" style="border-top: 3px #00A6C9 solid; " >
    
    <form class="form w-100" novalidate="novalidate" [formGroup]="girisForm" (submit)="girisYap()"> 
        <div class="text-center mb-10">
            <h1 class="text-dark mb-3">Ekders Yönetim ve Bilgi Sistemi (FACIT)</h1>
            <div class="text-black-400 fw-bold fs-4">
                <!-- Sisteme giriş yapmak için <span style="color:red">E-Devlet ile Giriş</span> butonunu kullanınız. <br /> -->
                Sisteme <span style="color:#0095E8">Tek Şifreniz </span> ile giriş yapabilirsiniz.
                
                <!-- Sisteme sadece e-devlet üzerinden giriş yapılabilmektedir. -->
            </div>
        </div>
        <div *ngIf="hataVar" class="alert alert-danger">
            {{this.hataMesaj}}
        </div>
        <div class="fv-row mb-10">
            <label class="form-label fs-6 fw-bolder text-dark">TC Kimlik No</label>
            <input class="form-control form-control-lg form-control-solid" type="text" name="email"
                formControlName="tckimlikno" autocomplete="off" style="border-color: #8fb9d5;"/>
        </div>
        <div class="fv-row mb-10">
            <div class="d-flex flex-stack mb-2">
                <label class="form-label fw-bolder text-dark fs-6 mb-0">Tek Şifre</label>
            </div>
            <input class="form-control form-control-lg form-control-solid" type="password" name="password"
                formControlName="sifre" autocomplete="off" style="border-color: #8fb9d5;" />
        </div>
        <div class="text-center">
            <button type="submit" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 mb-5"
                [attr.data-kt-indicator]="yukleniyor ? 'on' : 'off'" [disabled]="yukleniyor">
                <span class="indicator-label">Giriş</span>
                <span class="indicator-progress">Lütfen Bekleyin...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
            <div class="text-center text-muted text-uppercase fw-bolder mb-5 ">ya da</div> 
        </div>
    </form> 

    <button (click)="girisEdevletLink()"  [disabled]="yukleniyorEdevlet"
    [attr.data-kt-indicator]="yukleniyorEdevlet ? 'on' : 'off'"   class="btn btn-flex flex-center btn-danger btn-lg w-100 mb-5">
        <img alt="Logo" src="/assets/image/e-devlet.png" class="h-20px me-3" />
        <span class="indicator-label"> E-Devlet ile Giriş</span>
        <span class="indicator-progress">Giriş Yapılıyor...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
    </button>    
</div>