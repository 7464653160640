export class OgrencilerDto {
    ogrid:number=0;    
    ogrtc:string='';
    ogrno:string='';
    ad:string='';
    soyad:string='';    
    ayrilis_tarihi: Date = new Date();   
    aktif:string='';   
    personel_tc:string='';   
    akademisyenad:string='';
    birimadi:string='';
    ogrenimtipi:string='';
    ogrenimturu:string='';    
}

export class OgrenciAkademisyenlerDto{        
    personel_tc : string='';        
    akademisyenad : string='';        
}