import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { Kullanici } from 'src/app/models/Kullanici.model';
import { GirisService } from 'src/app/services/giris.service';

@Component({
  selector: 'ust-menu',
  templateUrl: './ust-menu.component.html',
  styleUrls: ['./ust-menu.component.css']
})
export class UstMenuComponent implements OnInit {

  constructor(private router: Router, private girisService:GirisService) { }

  kullanici: Kullanici = new Kullanici();
  
  ngOnInit(): void {   
  // const auth = document.cookie.split('=')[1];
    /* if (this.girisService?.cookieByName()) {
      this.kullanici = jwtDecode(this.girisService?.cookieByName()) as Kullanici;
    } */

    const auth = localStorage.getItem('auth');
    if (auth != null) {
      this.kullanici = jwtDecode(auth) as Kullanici;
    }

  }

   cikis() {
    this.girisService.cikis().subscribe(res => {
      //document.cookie='authEkders=';
      localStorage.removeItem('auth');
      this.router.navigate(['giris']);
    });;
  } 
}
