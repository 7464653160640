import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { GenelService } from 'src/app/services/genel.service';
import { BirimService } from 'src/app/services/birim.service';
import { AkademisyenlerDto, AkademisyenParam } from 'src/app/models/Akademisyenler.model';
import { AkademisyenService } from 'src/app/services/akademisyen.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { Router } from '@angular/router';

import * as XLSX from 'xlsx';
import { AkademikTakvimDto } from 'src/app/models/AkademikTakvim.model';
import { AkademikTakvimService } from 'src/app/services/akademiktakvim.service';
import { PageParam } from 'src/app/models/HaftalikDersProgram.model';


@Component({  
  selector: 'dersprogram',
  templateUrl: './dersprogram.component.html',
  styleUrls: ['./dersprogram.component.css']
})

export class HaftalikDersProgramComponent implements OnInit {
  
  constructor(private akademisyenService: AkademisyenService, 
              private birimService: BirimService, 
              private akademiktakvimService: AkademikTakvimService,
              public yetkiService: YetkiService, 
              private genelService: GenelService, 
              private formBuilder: FormBuilder,
              private router:Router
              ) {
    this.service = akademisyenService;
   }

   listForm = this.formBuilder.group({
    dersprogramForm: this.formBuilder.group(new AkademisyenlerDto()),
  });

  dersprogramForm(): FormGroup {
    return this.listForm.get('dersprogramForm') as FormGroup;
  }

  yukleniyor=false;
  service: AkademisyenService;  
  akademisyenListDto: AkademisyenlerDto[] = [];
  akademisyenParam: AkademisyenParam = new AkademisyenParam();
  akademikTakvimDto: AkademikTakvimDto[] = [];
  birimListDto: BirimlerDto[] = [];
  birimParam: BirimParam = new BirimParam();
  pageParam: PageParam = new PageParam();
  birimDto: any[] = [];
  bolumDto: any[] = [];
  programDto: any[] = [];
  birimID: number = 0;
  bolumID: number = 0; 
  programID: number = 0; 
  unvanID: number = 0; 
  adSoyad: string = '';
  personelID: number = 0;
  personelTC: string = '';  
  onayKriter: string = 'D';
  donemID: number = 0;

  kisiArama : string = '';
  birimHata: string | null = '';

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.BirimleriGetir();     
  } 
 
  GetListByAkademisyen() {
    var secbirimID : number = 0;  
    let hataBirim = this.birimID == 0 ? 'Lütfen Birim Seçiniz.' : null;
    this.birimHata = hataBirim;    
    if (!this.birimHata) {  
        if (this.programID != 0) {   secbirimID = this.programID;} 
        else if (this.bolumID != 0) {   secbirimID = this.bolumID;}
        else if (this.birimID != 0) {   secbirimID = this.birimID;} 
        this.yukleniyor = true;
        this.AktifDonem(this.birimID);
        this.akademisyenParam.kadrobirimid = secbirimID;
        this.akademisyenParam.unvanid = this.unvanID;
        this.akademisyenParam.adsoyad = this.adSoyad;
        this.akademisyenParam.personelid = this.personelID;
        this.akademisyenParam.personeltc = this.personelTC;
        this.akademisyenParam.onay = this.onayKriter;
        this.service.getAllAkademisyenList(this.akademisyenParam).subscribe(respone => {
          this.akademisyenListDto = respone.data;      
          this.yukleniyor = false;   
        });
    }
  }

  AktifDonem(birim_id: number = 0) {    
    this.donemID = 0;
    this.akademiktakvimService.getAkademikTakvim(birim_id).subscribe(respone => {
      this.akademikTakvimDto = respone.data;  
      if (respone.data.length > 0) {        
        this.donemID = this.akademikTakvimDto[0].donemID;       
      }
      else {
        this.donemID = 0;        
      }
    });
  }

  ParamGonder(item: AkademisyenlerDto) {    
    this.pageParam.tc = item.tc;
    this.pageParam.ad_soyad = item.ad +' '+ item.soyad;
    this.pageParam.ad_soyad_unvan = (item.akunvan == null ? item.unvankisa : item.akunvankisa) +' '+ item.ad +' '+ item.soyad;
    this.pageParam.unvan = (item.akunvan == null ? item.unvankisa : item.akunvankisa);
    this.pageParam.birim_id = this.birimID;    
  }

  BirimleriGetir() {
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19,44]; //2,6,10,19,44 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul, Koordinatörlük 
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      this.birimDto = respone.data;      
      this.birimID = 0;
      this.bolumID = 0;      
    });
  }

  BolumGetir() {
    this.birimParam.ustbirimid = this.birimID;//item.target.value;
    this.birimParam.tip = 'Bolum';
    this.birimParam.birimtur = [];
    this.birimParam.durum = [];
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      if (this.birimID != 0) {
        if (respone.data.length > 0) {
          this.bolumDto = respone.data;                                        
        } else {
          this.bolumDto = [];                     
        }         
      }
      else {           
        this.bolumDto = [];             
      }            
      this.bolumID = 0;      
      this.birimHata = null;
    });    
  }
 
  AnaBilimDaliGetir() {
    this.birimParam.ustbirimid = this.bolumID;//item.target.value;
    this.birimParam.tip = 'Program';
    this.birimParam.birimtur = [];
    this.birimParam.durum = [];
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      if (this.bolumID != 0) {
        if (respone.data.length > 0) {
          this.programDto = respone.data;                                        
        } else {
          this.programDto = [];                     
        }         
      }
      else {           
        this.programDto = [];             
      }            
      this.programID = 0;  
    });    
  }
 
  onayBekleyen(event: any, deger: string) {
    if (event.currentTarget.checked) {      
      this.onayKriter = 'E'
    } else {
      this.onayKriter = 'D';
    }   
  }

  searchChange(event: Event) {    
    const value = (event.target as HTMLInputElement).value;
    this.kisiArama = value;
    if (value.length > 2)
    {
        this.yukleniyor = true;    
        this.AktifDonem(1);  // 1: Rektörlük     
        this.akademisyenParam.unvanid = this.unvanID;
        this.akademisyenParam.adsoyad = this.kisiArama;
        this.akademisyenParam.personelid = this.personelID;
        this.akademisyenParam.personeltc = this.personelTC;
        this.akademisyenParam.onay = this.onayKriter;
        this.service.getAllAkademisyenList(this.akademisyenParam).subscribe(respone => {
          this.akademisyenListDto = respone.data;   
          this.yukleniyor = false;   
        });
    }
    
  }
  
  exportexcel(fileName: string,  tableName: string): void {    
    var element = document.getElementById(tableName); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

  openWindow(pagedata:any[],data:object) {

    const url = this.router.serializeUrl(
      this.router.createUrlTree([pagedata[0]+"/"+pagedata[1],data])
    );
  
    window.open(url, '_blank');
  }

}
