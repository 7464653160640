import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { BirimService } from 'src/app/services/birim.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { AkademisyenService } from 'src/app/services/akademisyen.service';
import { AkademisyenIzinDto, AkademisyenIzinParam } from 'src/app/models/AkademisyenIzinler.model';


@Component({  
  selector: 'raporizin',
  templateUrl: './raporizin.component.html',
  styleUrls: ['./raporizin.component.css']
})

export class RaporIzinComponent implements OnInit {
  
  constructor(private akademisyenService: AkademisyenService, 
              private birimService: BirimService, 
              public yetkiService: YetkiService,               
              private formBuilder: FormBuilder,
              private router: Router
              ) {
    this.service = akademisyenService;
   }

   listForm = this.formBuilder.group({
    raporForm: this.formBuilder.group(new AkademisyenIzinDto()),
  });

  raporForm(): FormGroup {
    return this.listForm.get('raporForm') as FormGroup;
  }

  yukleniyor=false;
  service: AkademisyenService;  
  raporIzinList: AkademisyenIzinDto[] = [];
  raporIzinParam: AkademisyenIzinParam = new AkademisyenIzinParam();
  birimParam: BirimParam = new BirimParam();
  birimDto: any[] = [];
  bolumDto: any[] = [];
  birimID: number = 0;
  bolumID: number = 0;   
  tarih1: string = '';
  tarih2: string = '';
  adSoyad: string = '';  
  personelTC: string = '';  

  birimHata: string | null = '';
  tarih1Hata: string | null = '';
  tarih2Hata: string | null = ''; 

  secilibirim : BirimlerDto |undefined;   

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.BirimleriGetir();  
  } 
 
  GetRaporIzin() {   
    var secbirimID : number = 0;      
    //let hataBirim = this.secilibirim == undefined ? 'Lütfen Birim Seçiniz.' : null;
    let hataTarih1 = this.tarih1 == '' ? 'Lütfen İzin Başlama Tarihi Giriniz.' : null;
    let hataTarih2 = this.tarih2 == '' ? 'Lütfen İzin Bitiş Tarihi Giriniz.' : null;    
    //this.birimHata = hataBirim;    
    this.tarih1Hata = hataTarih1; 
    this.tarih2Hata = hataTarih2;     
    if ((!this.tarih1Hata) && (!this.tarih2Hata)) { //(!this.birimHata) && 
      this.yukleniyor = true;        
      if (this.bolumID != 0) {   secbirimID = this.bolumID;}
      else if (this.birimID != 0) {   secbirimID = this.birimID;} 
      this.raporIzinParam.kadrobirimid = secbirimID;
      this.raporIzinParam.personeltc = this.personelTC;
      this.raporIzinParam.adsoyad = this.adSoyad; 
      this.raporIzinParam.izinbaslagic = this.tarih1;
      this.raporIzinParam.izinbitis = this.tarih2;
      this.service.getAllAkademisyenIzinList(this.raporIzinParam).subscribe(respone => {
        if (respone.data.length > 0) {
          this.raporIzinList = respone.data;           
        }
        else {
          this.raporIzinList = [];     
        }
        this.yukleniyor = false;   
      });
    }
  }

  BirimleriGetir() {
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19,44,20]; //2,6,10,19,44,20 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul, Koordinatörlük, Formasyon
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {      
      this.birimDto = respone.data;   
      this.birimID = 0;
      this.bolumID = 0;             
    });
  }

  BolumGetir(item: BirimlerDto) {  
    this.secilibirim = item;   
    if (item!=undefined){           
      this.birimID = item.birimid;  
      this.birimParam.ustbirimid = item.birimid ?? 0;
      this.birimParam.tip = 'Bolum';
      this.birimParam.durum = [];
      this.birimParam.birimtur = [];
      this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
        if (item.birimid != 0) {                     
          if (respone.data.length > 0) {
            this.bolumDto = respone.data;                              
          } else {
            this.bolumDto = [];                     
          }         
        }
        else {           
          this.bolumDto = [];             
        }            
        this.bolumID = 0;     
        this.birimHata = null; 
      });    
    } 
    else {
      this.bolumDto = [];
      this.bolumID = 0;
      this.birimID = 0;      
    }     
  }

  exportexcel(fileName: string,  tableName: string): void {    
    var element = document.getElementById(tableName); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
