import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { TelafiIptal } from 'src/app/models/TelafiIptal.model';
import { GirisService } from 'src/app/services/giris.service';
import { TelafiService } from 'src/app/services/telafi.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import Swal from 'sweetalert2';

declare var bootstrap: any;

@Component({
  selector: 'telafi',
  templateUrl: './telafi.component.html',
  styleUrls: ['./telafi.component.css']
})
export class TelafiComponent implements OnInit {

  constructor(private telafiService: TelafiService, 
              public yetkiService: YetkiService,
              private validationService: ValidationService,
              private route: ActivatedRoute,
              public girisService: GirisService) {
    this.service = telafiService;    
  }

  
  service: TelafiService;
  siliniyor = false;
  yukleniyor = false;
  akademisyen_tc: string = '';  
  akademisyen_AdSoyadUnvan: string = '';
  donemID: number = 0;
  hatalar: any = {};
  telafilist: TelafiIptal[] = [];
  silinecekTelafi: TelafiIptal | undefined;  

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(qparams => {
        if (qparams['adsoyadunvan']) {          
          this.akademisyen_AdSoyadUnvan = qparams['adsoyadunvan'];   
          this.donemID = qparams['donem_id'];         
        }        
      })
      if (params['tc']) {
        this.akademisyen_tc = params['tc'];  
        this.route.queryParams.subscribe(qryparams => {
          if (qryparams['donem_id']) {       
            this.donemID = qryparams['donem_id'];   
            this.GetListByTC(params['tc'], qryparams['donem_id']);                   
          }        
        })
        
      }
    });
  }

  GetListByTC(akademisyenTC: string, donem: number = 0) {    
    this.yukleniyor = true;
    /* this.service.getAll(akademisyenTC, donem).subscribe(respone => {
      this.telafilist = respone.data; 
      this.yukleniyor = false;        
    }); */
    
    this.service.getAll(akademisyenTC, donem).pipe(finalize(() => {
      this.yukleniyor = false;
    })).subscribe({
      next: res => {        
        //if (res?.message && res?.message != "" ) {this.yetkiService.success(res?.message,1)};
        this.telafilist = res.data; 
        //this.yukleniyor = false;
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined ||err.error.Errors == null ||err.error.Errors?.length==0){
          if (err.error?.message && err.error?.message != "" ) {this.yetkiService.error(err.error?.message,1)};
          if (err.error?.Message && err.error?.Message != "" ) {this.yetkiService.error(err.error?.Message,1)};
        }
      }
    });   
  }

  silModal(telafi: TelafiIptal) {
    this.silinecekTelafi = telafi;    
  }

  sil(model: TelafiIptal) {
    this.siliniyor = true;
    this.service.delete(model).subscribe(response => {
      bootstrap.Modal.getInstance(document.getElementById('sil_modal')).hide();      
      this.yetkiService.success("Telafi başarıyla silindi.", 1)
      //this.grid?.load();
      this.GetListByTC(this.akademisyen_tc, this.donemID);
      this.siliniyor = false;
    }, error => {
      this.yetkiService.error("Hata oluştu. Silme işlemi yapılamadı.", 1)
      bootstrap.Modal.getInstance(document.getElementById('sil_modal')).hide();
      //this.grid?.load();
      this.GetListByTC(this.akademisyen_tc, this.donemID);
      this.siliniyor = false;
    });
  }

}
