import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DerslerDto } from '../models/Dersler.model';
import { Response } from '../models/response.model';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class DersService {
  apiUrl = '/api/genel'

  constructor(private http: HttpClient) { }
  
  /* getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<DerslerDto[]>> {
    return this.http.get<Response<DerslerDto[]>>(this.apiUrl + '/birimlerList', {
      params: {        
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });      
  } */

  getAllDersList(birimid: number=0, dersadi: string ='', derstipiid: number=0, donemid: number=0, personelid: number=0, personeltc: string =''): Observable<Response<DerslerDto[]>> {
    return this.http.get<Response<DerslerDto[]>>(this.apiUrl + '/derslerlist', {
      params: {
        'birimid': birimid,
        'dersadi': dersadi,
        'derstipiid': derstipiid,
        'donemid': donemid,
        'personelid': personelid,
        'personeltc': personeltc
      }
    });
    
  }  
 
  
}
