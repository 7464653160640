export class DersYukuDto {
    sira_no: number = 0;
    akademisyen_id: number = 0;
    akademisyen_tc: string = '';
    akademisyen_adi: string = '';
    akademisyen_adi_unvan: string = '';
    unvan: string = '';
    unvan_kisa: string = '';
    idari_gorev_unvan: string = '';
    kadrobirimid: number = 0;
    kadro_birim_uzun: string = '';
    kurum_mail: string = '';
    kurum_sicil_no: string = '';  
    ders_tipi: string = '';   
    onay: string = '';
    onay_durumu: string = ''; 
    gun_sayisi: number = 0;   
    toplam_saat: number = 0;   
    dersyuku_detay: any[] = [];
    dersyuku_hafta: any[] = [];
    dersyuku_gun: any[] = [];
    birim_adi: string = '';
    tarih_araligi: string = '';
    ogrenim_turu: string = '';
    
}

export class DersYukuDetayDto {     
    akademisyen_tc: string = '';  
    yilhafta: string = '';    
    gun_tarih?: Date = new Date();       
    ders_tipi: string = '';      
    saat_durumu: string = '';    
    ders_saat: number = 0;  
    durum: string = ''; 
}

export class DersYukuHaftaDto {     
    akademisyen_tc: string = '';  
    yilhafta: string = '';              
    hafta_saat: number = 0;   
}

export class DersYukuGunDto { 
    hafta_no: number = 0;
    yilhafta: string = '';    
    gun_tarih?: Date = new Date();       
    gun: string = '';            
    gun_no: number = 0;   
    gun_ders_saat: string = '';    
}

export class DersYukuParam {    
    birim_id: string = '0';
    bolum_id: string = '0';    
    program_id: string = '0';    
    tarih1: string = '';    
    tarih2: string = '';    
    ogrenim_turu: string = '';
    bolum_list: any[] = [];
}

export class DersYukuDetayParam {       
    tclist: any[] = [];    
    birim_id: string = '0';
    bolum_id: string = '0'; 
    program_id: string = '0';    
    tarih1: string = '';    
    tarih2: string = '';    
    ogrenim_turu: string = '';
    bolum_list: any[] = [];
}

