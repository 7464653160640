<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-scroll me-2 text-success" style="font-size: large;"></i>Sınav Düzenle
                        ({{akademisyen_AdSoyadUnvan}})
                    </span>
                </h1>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <form class="form" [formGroup]="detayForm" (submit)="kaydet()">
                    <div class="card col-8 offset-2" style="border-top: 2px #77c2e9 solid; ">
                        <div class="card-header">
                            <h3 class="card-title" *ngIf="yeni">
                                Yeni Sınav Ekle
                            </h3>
                            <h3 class="card-title" *ngIf="!yeni">
                                {{ sinav.ders_adi }} {{ sinav.ogrenim_turu }} / {{ sinav.birim_adi }} / {{
                                sinav.bolum_adi }} Sınavını Düzenle
                            </h3>

                            <span *ngIf="yeni" class="card-title text-danger fs-6" style="text-align: left;">
                                2.Ö sınavları 1.Ö ile aynı anda yapılmışsa birleştirilerek girilmesi
                                gerekmektedir.</span>
                        </div>
                        <div class="card-body" style="padding:1.5rem">
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-floating mb-5">
                                        <select class="form-select" id="seciliders"
                                            [ngModelOptions]="{standalone: true}" name="seciliders"
                                            [(ngModel)]="seciliders" (ngModelChange)="DersBilgiVer($event)">
                                            <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                            <option *ngFor="let item of dersDto" [ngValue]="item">
                                                {{ item.ders_adi }} {{ item.ogrenim_turu }} / {{ item.birim_adi }} / {{
                                                item.bolum_adi }} ({{ item.ders_tipi }})
                                            </option>
                                        </select>
                                        <label style="color: #009ef7;" class="fw-bold" for="seciliders">Ders Adı</label>
                                        <span class="form-text text-danger fs-6" *ngIf="dersHata">{{dersHata}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6">
                                    <div class="form-floating mb-5">
                                        <input formControlName="sinav_tarihi" type="date"
                                            class="form-control datepicker" id="sinav_tarihi" placeholder="sinav_tarihi"
                                            name="sinav_tarihi"
                                            [value]="this.detayForm.value.sinav_tarihi | date:'yyyy-MM-dd'"
                                            style="border-color: #8fb9d5;" />
                                        <label style="color: #009ef7;" class="fw-bold" for="sinav_tarihi">Sınav
                                            Tarihi</label>
                                        <div class="text-danger" *ngFor="let hata of hatalar['sinav_tarihi']">
                                            {{hata}}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-floating mb-5">
                                        <input formControlName="sinav_baslama_saati" type="time"
                                            class="form-control time" id="sinav_baslama_saati"
                                            placeholder="Sınav Başlama Saati" name="sinav_baslama_saati" />
                                        <label style="color: #009ef7;" class="fw-bold" for="sinav_baslama_saati">Sınav
                                            Başlama Saati</label>
                                        <div class="text-danger" *ngFor="let hata of hatalar['sinav_baslama_saati']">
                                            {{hata}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row ">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-lg-6" style="color: black;">
                                            <label
                                                class="form-check form-check-custom form-check-solid form-switch mb-5">
                                                <input type="radio" formControlName="sinav_tipi" value="V"
                                                    id="sinav_tipi1" name="sinav_tipi" class="form-check-input"
                                                    [checked]="this.detayForm.value.sinav_tipi == 'V'">
                                                <span class="form-check-label fs-6">Vize
                                                </span>
                                            </label>
                                        </div>
                                        <div class="col-lg-6" style="color: black;">
                                            <label
                                                class="form-check form-check-custom form-check-solid form-switch mb-5">
                                                <input type="radio" formControlName="sinav_tipi" value="F"
                                                    id="sinav_tipi2" name="sinav_tipi"
                                                    [checked]="this.detayForm.value.sinav_tipi == 'F'"
                                                    class="form-check-input">
                                                <span class="form-check-label fs-6">Final
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="text-danger" *ngFor="let hata of hatalar['sinav_tipi']">
                                        {{hata}}
                                    </div>



                                    <!-- <div class="form-floating mb-5">
                                        <input formControlName="sinav_tipi" type="radio" id="sinav_tipi1" name="sinav_tipi"
                                            value="V" style="border-color: #8fb9d5;" [defaultChecked]="true"
                                            [checked]="this.detayForm.value.sinav_tipi == 'V'"> Vize
                                         <br>
                                        <input formControlName="sinav_tipi" type="radio" id="sinav_tipi2" name="sinav_tipi"
                                            value="F" style="border-color: #8fb9d5;"
                                            [checked]="this.detayForm.value.sinav_tipi == 'F'"> Final
                                    
                                        <div class="text-danger" *ngFor="let hata of hatalar['sinav_tipi']">
                                            {{hata}}
                                        </div>
                                    </div> -->
                                </div>

                                <div class="col-md-6">
                                    <div class="form-floating mb-5">
                                        <input formControlName="ogrenci_sayisi" type="number" class="form-control"
                                            id="ogrenci_sayisi" name="ogrenci_sayisi" />
                                        <label style="color: #009ef7;" class="fw-bold" for="ogrenci_sayisi">Öğrenci
                                            Sayısı</label>
                                        <div class="text-danger" *ngFor="let hata of hatalar['ogrenci_sayisi']">
                                            {{hata}}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row card-footer  ">
                                <div class="col-6 text-left">
                                    <button type="button" [routerLink]="['/sinav', akademisyen_tc]"
                                        [queryParams]="{donem_id: donem_id, adsoyadunvan: akademisyen_AdSoyadUnvan}"
                                        id="kt_modal_new_target_cancel" class="btn btn-danger btn-hover-rise me-3">
                                        <i class="far fa-arrow-alt-circle-left"></i>Geri
                                    </button>
                                </div>
                                <div class="col-6 text-end">
                                    <submit-button [loader]="kaydediliyor"></submit-button>
                                    <!-- <submit-button 
                                    [disabled]="!yetkiService.yetkiVar(['KartCihaz.Ekle','KartCihaz.Degistir'])"
                                    [loader]="kaydediliyor"></submit-button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </loading>
        </div>
    </div>
</div>