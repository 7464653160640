import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { DersProgramDto } from 'src/app/models/DersProgramlar.model';
import { CalendarEvent } from 'src/app/models/CalendarEvent.model';
import { HaftalikDersProgram, HaftalikDersProgramOzet } from 'src/app/models/HaftalikDersProgram.model';
import { AkademikTakvimDto } from 'src/app/models/AkademikTakvim.model';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';
import { GenelService } from 'src/app/services/genel.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { AkademikTakvimService } from 'src/app/services/akademiktakvim.service';
import { ValidationService } from 'src/app/services/validation.service';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { Calendar, CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import trLocale from '@fullcalendar/core/locales/tr';
import { finalize } from 'rxjs';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { AkademisyenService } from 'src/app/services/akademisyen.service';
import { AkademisyenParam } from 'src/app/models/Akademisyenler.model';
import { GirisService } from 'src/app/services/giris.service';
import { Kullanici } from 'src/app/models/Kullanici.model';
import { HaftaDto } from 'src/app/models/Haftalar.model';


@Component({
  selector: 'dersprogram-detay',
  templateUrl: './dersprogram-detay.component.html',
  styleUrls: ['./dersprogram-detay.component.css']
})

export class HaftalikDersProgramDetayComponent implements OnInit {

  constructor(private haftalikDersProgramService: HaftalikDersProgramService,
    public yetkiService: YetkiService,
    private genelService: GenelService,
    private validationService: ValidationService,
    private akademiktakvimService: AkademikTakvimService,
    private akademisyenService: AkademisyenService,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    public girisService: GirisService
  ) {
    this.service = haftalikDersProgramService;
  }

  listForm = this.formBuilder.group({
    detayForm: this.formBuilder.group(new DersProgramDto()),
  });

  detayForm(): FormGroup {
    return this.listForm.get('detayForm') as FormGroup;
  }

  calendarOptions: CalendarOptions = {
    headerToolbar: false,
    locale: trLocale,
    initialView: 'timeGridWeek',
    firstDay: 1,
    weekends: true,
    editable: false,
    selectable: true,
    expandRows: true,
    allDaySlot: false,
    dayHeaderFormat: { weekday: "long" },
    scrollTime: '08:00:00',
    scrollTimeReset: true,
    slotMinTime: '07:00:00',
    slotMaxTime: '24:00:00',
    slotLabelFormat: [{
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }],
  };

  service: HaftalikDersProgramService;
  haftalikDersProgram: HaftalikDersProgram[] = [];
  haftalikDersProgramOzet: HaftalikDersProgramOzet[] = [];
  dersProgramDto: DersProgramDto[] = [];
  akademikTakvimDto: AkademikTakvimDto[] = [];
  akademisyenParam: AkademisyenParam = new AkademisyenParam();
  //kullanici: Kullanici = new Kullanici();
  //donemDto: any[] = [];
  haftaDto: HaftaDto[] = [];
  donemID: number = 0;
  aktifDonemMi: boolean = false;
  akademikTakvimID: number = 0;
  haftaID: number = 0;
  yilhafta: string = '';
  silinecekDersProgram: HaftalikDersProgram[] = [];
  birimID: number = 0;
  akademisyen_tc: string = '';
  akademisyen_AdSoyadUnvan: string = '';
  akademisyen_onay: string = '';
  akademisyen_resim: String = '';
  ilktarih: any;//'19.09.2022';
  sontarih: any;//'30.12.2022';
  Events: any[] = [];
  yukleniyor = false;
  kaydediliyor = false;
  hatalar: any = {};
  donemHata: string | null = '';

  secilidonem : AkademikTakvimDto |undefined;
  secilihafta : HaftaDto |undefined;

  @ViewChild('grid') grid?: GridComponent;
  @ViewChild('fullcalendar') fullcalendar?: FullCalendarComponent;

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(qryparams => {
        if (qryparams['adsoyadunvan']) {
          this.akademisyen_AdSoyadUnvan = qryparams['adsoyadunvan'];
        }
        if (qryparams['birim_id']) {
          this.DonemleriGetir(qryparams['birim_id']);
          this.birimID = qryparams['birim_id'];
          this.akademisyen_AdSoyadUnvan = qryparams['adsoyadunvan'];
        }
      })
      if (params['tc']) {
        this.akademisyen_tc = params['tc'];
      }
    });
  }

  getListTCNo(akademisyentc: string, haftaid: number, yilhafta: string, donemid: number) {
    let hataDonem = this.secilidonem == undefined ? 'Lütfen Dönem Seçiniz.' : null;
    this.donemHata = hataDonem;
    if (!this.donemHata){
      this.yukleniyor = true;
      this.Events = [];
      //this.kullanici = this.girisService.girisBilgi();

      this.service.getListOzetSorgu(akademisyentc, haftaid, yilhafta, donemid).subscribe(respone => {
        this.haftalikDersProgramOzet = respone.data;
      });

      this.akademisyenParam.personeltc = akademisyentc;
      this.akademisyenParam.resimdurum = 1;
      this.akademisyenService.getAllAkademisyenList(this.akademisyenParam).subscribe(respone => {
        if (respone.data.length > 0) {
          respone.data.forEach(e => {
            this.akademisyen_resim = e.resim;
          });
        }
      });

      this.service.getListTCNo(akademisyentc, haftaid, yilhafta, donemid).pipe(finalize(() => {
        this.yukleniyor = false;
      })).subscribe({
        next: res => {
          if (res.data == null) {
            this.router.navigateByUrl('YetkinizYok');
          }

          //if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };

          if (res.data.length > 0) {
            this.haftalikDersProgram = res.data;
            this.haftalikDersProgram.forEach(e => {
              if (e.titletext.substring(0, 12) == 'Telafi Dersi') {
                this.CalendarEventOlustur(e.id, e.titletext, e.gun_no, e.baslama_saati, e.bitis_saati, e.renk, 'red');
              }
              else {
                this.CalendarEventOlustur(e.id, e.titletext, e.gun_no, e.baslama_saati, e.bitis_saati, e.renk, 'white');
              }
              this.akademisyen_onay = e.onay;
            });
            this.calendarOptions.events = this.Events;

          } else {
            this.haftalikDersProgram = [];
            this.calendarOptions.events = this.Events;
          }
          window.dispatchEvent(new Event("resize"));
          this.yukleniyor = false;
        },
        error: err => {
          console.log('errr',err);

          this.hatalar = this.validationService.hatalar(err.error.Errors);
          if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
            if (err.error?.message && err.error?.message != "") { this.yetkiService.error(err.error?.message, 1) };
            if (err.error?.Message && err.error?.Message != "") { this.yetkiService.error(err.error?.Message, 1) };
          }
        }
      });
    }
  }

  GetListPortalBilgiKaydet() {
    Swal.fire({
      title: 'NEÜ Bilgi sisteminden ders programınız getirilecektir. Emin misiniz ?',
      text: 'DERS PROGRAM GETİR',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Getir',
      cancelButtonText: 'Vazgeç',
    }).then((result) => {
      if (result.value) {

        this.GetListHaftalikDersProgramByTC(this.akademisyen_tc);
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Vazgeçildi", "NEÜ Bilgi sisteminden ders programı getirme işlemi yapılmadı", 'error')
      }
    })

  }

  GetListHaftalikDersProgramByTC(personelTC: string = '') {

    let hataDonem = this.secilidonem == undefined ? 'Lütfen Dönem Seçiniz.' : null;
    this.donemHata = hataDonem;

    if ((!this.donemHata) && (this.aktifDonemMi == true)){
      this.yukleniyor = true;
      this.Events = [];

      this.service.getDersProgramiByTC(personelTC).subscribe(response => {

        if (response.data.length > 0) {
          this.dersProgramDto = response.data;
          this.topluSilEkle(this.dersProgramDto);
          /* this.dersProgramDto.forEach(e => {
            this.CalendarEventOlustur(e.id, e.titletext, e.gunNo, e.baslamaSaati, e.normalBitisSaati, e.renk)
          }); */
          this.calendarOptions.events = this.Events;
        }
        window.dispatchEvent(new Event("resize"));
        //this.yukleniyor = false;
      });


      /*this.service.getDersProgramiByTC(personelTC).pipe(finalize(() => {
        this.kaydediliyor = false;
        this.yukleniyor = false;
      })).subscribe({
        next: response => {
          if (response.data.length > 0) {
            this.dersProgramDto = response.data;
            this.topluSilEkle(this.dersProgramDto);
            //this.dersProgramDto.forEach(e => {
            //  this.CalendarEventOlustur(e.id, e.titletext, e.gunNo, e.baslamaSaati, e.normalBitisSaati, e.renk)
            //});
            this.calendarOptions.events = this.Events;

            window.dispatchEvent(new Event("resize"));
          }
        },
        error: err => {
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
            if (err.error?.message && err.error?.message != "") { this.yetkiService.error(err.error?.message, 1) };
            if (err.error?.Message && err.error?.Message != "") { this.yetkiService.error(err.error?.Message, 1) };
          }
        }
      });*/
    }
  }

  topluSilEkle(kayitDersProgram: DersProgramDto[]) {
    this.kaydediliyor = true;
    this.hatalar = {};
    this.service.multiDeleteAdd(kayitDersProgram).pipe(finalize(() => {
      this.kaydediliyor = false;
      this.yukleniyor = false;
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
        this.getListTCNo(this.akademisyen_tc, 2, this.yilhafta, this.donemID);
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.error(err.error?.message, 1) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.error(err.error?.Message, 1) };
        }
      }
    });
  }

  CalendarEventOlustur(id: number = 0, titletext: string, gunno: number, bassaat: string, bitsaat: string, renk: string, borderrenk: string) {
    let calendarevent = {
      id: id,
      title: titletext,//'('0020060004') Hukukun Temel Kavramları(1.Ö.) - SBF (1 T / 09:00-10:00)'
      daysOfWeek: [gunno],
      startTime: bassaat,
      endTime: bitsaat,
      backgroundColor: renk,
      borderColor: borderrenk,
      textColor: 'black'
    };
    this.Events.push(calendarevent);
  }

  OnayGonder(onay: string, mesaj: string, basliktext: string, butontext: string) {
    Swal.fire({
      title: mesaj,
      text: basliktext,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: butontext,
      cancelButtonText: 'Vazgeç',
    }).then((result) => {
      if (result.value) {
        this.HaftalikDersProgramOnayByTC(this.akademisyen_tc, onay, this.donemID);
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Vazgeçildi", "İşlem yapılmadı", 'error')
      }
    })
  }

  HaftalikDersProgramOnayByTC(akademisyenTC: string = '', onay: string = '', donem: number = 0) {
    let hataDonem = this.secilidonem == undefined ? 'Lütfen Dönem Seçiniz.' : null;
    this.donemHata = hataDonem;
    if ((!this.donemHata) && (this.aktifDonemMi == true)){
      this.kaydediliyor = true;
      this.yukleniyor = true;
      this.hatalar = {};
      this.service.multiUpdate(akademisyenTC, onay, donem).pipe(finalize(() => {
        this.kaydediliyor = false;
        this.yukleniyor = false;
      })).subscribe({
        next: res => {
          this.akademisyen_onay = onay;
          if (res?.message && res?.message != "") { this.yetkiService.success(res?.message, 1) };
        },
        error: err => {
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
            if (err.error?.message && err.error?.message != "") { this.yetkiService.error(err.error?.message, 1) };
            if (err.error?.Message && err.error?.Message != "") { this.yetkiService.error(err.error?.Message, 1) };
          }
        }
      });
    }
  }

  DonemSecim(item: AkademikTakvimDto) {
    this.secilidonem = item;
    if (item!=undefined){
      this.ilktarih = this.datepipe.transform(item.baslamaTarihi, 'dd.MM.yyyy');
      if ( item.enstituUzmanlikBitisTarihi==null ||
           item.enstituUzmanlikBitisTarihi==undefined ||
           this.datepipe.transform(item.enstituUzmanlikBitisTarihi, 'dd.MM.yyyy')=="01.01.0001"){
        this.sontarih = this.datepipe.transform(item.bitisTarihi, 'dd.MM.yyyy');
      } else {
        this.sontarih = this.datepipe.transform(item.enstituUzmanlikBitisTarihi, 'dd.MM.yyyy');
      }
      this.donemID = item.donemID;
      this.aktifDonemMi = item.edowebAktifDonemMi;
      this.akademikTakvimID = item.id;
      this.HaftalariGetir(this.ilktarih, this.sontarih);
      this.donemHata = null;
    }
    else {
      this.donemID = 0;
      this.akademikTakvimID = 0;
      this.aktifDonemMi = false;
    }


  }

  DonemleriGetir(birim_id: number = 0) {
    /* this.genelService.getDonemlerList().subscribe(respone => {
      this.donemDto = respone.data;
      this.donemID = 0;
      if (respone.data.length > 0) {
        this.donemID = 1;
      }
      else {
        this.donemID = 0;
      }
    }); */
    this.donemID = 0;
    this.aktifDonemMi = false;
    this.akademiktakvimService.getAkademikTakvim(birim_id,true,true).subscribe(respone => {
      this.akademikTakvimDto = respone.data;
      console.log("this.akademikTakvimDto : ",this.akademikTakvimDto)
      this.akademikTakvimDto.forEach(e => {
        this.ilktarih = this.datepipe.transform(e.baslamaTarihi, 'dd.MM.yyyy');

        if ( e.enstituUzmanlikBitisTarihi==null ||
             e.enstituUzmanlikBitisTarihi==undefined ||
             this.datepipe.transform(e.enstituUzmanlikBitisTarihi, 'dd.MM.yyyy')=="01.01.0001"){
          this.sontarih = this.datepipe.transform(e.bitisTarihi, 'dd.MM.yyyy');
        } else {
          this.sontarih = this.datepipe.transform(e.enstituUzmanlikBitisTarihi, 'dd.MM.yyyy');
        }

      });

      if (respone.data.length > 0) {
        this.secilidonem = this.akademikTakvimDto[0];
        this.ilktarih = this.datepipe.transform(this.akademikTakvimDto[0].baslamaTarihi, 'dd.MM.yyyy');
        if ( this.akademikTakvimDto[0].enstituUzmanlikBitisTarihi==null ||
             this.akademikTakvimDto[0].enstituUzmanlikBitisTarihi==undefined ||
             this.datepipe.transform(this.akademikTakvimDto[0].enstituUzmanlikBitisTarihi, 'dd.MM.yyyy')=="01.01.0001"){
          this.sontarih = this.datepipe.transform(this.akademikTakvimDto[0].bitisTarihi, 'dd.MM.yyyy');
        } else {
          this.sontarih = this.datepipe.transform(this.akademikTakvimDto[0].enstituUzmanlikBitisTarihi, 'dd.MM.yyyy');
        }
        this.donemID = this.akademikTakvimDto[0].donemID;
        this.aktifDonemMi = this.akademikTakvimDto[0].edowebAktifDonemMi;
        this.akademikTakvimID = this.akademikTakvimDto[0].id;
        this.HaftalariGetir(this.ilktarih, this.sontarih);
      }
      else {
        this.donemID = 0;
        this.akademikTakvimID = 0;
        this.aktifDonemMi = false;
      }

    });
  }

  HaftaSecim(item: HaftaDto) {
    this.secilihafta = item;
    if (item!=undefined){
      this.yilhafta = item.yil.toString() + (item.hafta_id.toString().length == 1 ? '0'+ item.hafta_id.toString() : item.hafta_id.toString());
      this.haftaID = item.id;
      if (this.haftaID > 0) {
        this.getListTCNo(this.akademisyen_tc, this.haftaID, this.yilhafta, this.donemID);
      }
    }
    else {
      this.yilhafta = '';
      this.haftaID = 0;
    }
  }

  HaftalariGetir(ilk_tarih: any, son_tarih: any) {

    console.log("Haftalari Getir'e gelen son tarih",son_tarih);
    this.genelService.getHaftaList('T', ilk_tarih, son_tarih).subscribe(respone => {
      this.haftaDto = respone.data;
      this.haftaID = 2;
      this.yilhafta = '';
      if (respone.data.length > 0) {
        this.haftaDto.forEach(e => {
          if (e.bu_hafta == true) {
            this.haftaID = e.id;
            this.yilhafta = e.yil.toString() + (e.hafta_id.toString().length == 1 ? '0'+ e.hafta_id.toString() : e.hafta_id.toString());
            this.secilihafta = e;
          }
        });
        this.getListTCNo(this.akademisyen_tc, this.haftaID, this.yilhafta, this.donemID);
      }
      else {
        this.yilhafta = '';
        this.haftaID = 0;
      }
    });
  }

  exportexcel(fileName: string, tableName: string): void {
    var element = document.getElementById(tableName);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
/* function moment(date: Date) {
  throw new Error('Function not implemented.');
} */

