<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-table me-2 text-success" style="font-size: large;"></i>KBS Formu                        
                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporDersYukuKBS.KBS.ExcelAktar'])" class="col-md-6" style="text-align: right;">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('KBS Formu.xlsx','bl_subscriptions_table')" style="color: green;">                    
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button>
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="bl_post">
        <div id="bl_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <table id="bl_subscriptions_table" style="width:100%; background-color:white;">                    
                    <tr style="font-weight: bold;">                        
                        <td style="width: 140px; min-width: 140px; font-size: 11px;">TCKN</td>                        
                        <td style="width: 80px; min-width: 80px; font-size: 11px;">Veri Tipi</td>
                        <td *ngFor="let item of dersYukuGunList" style="font-size: 11px;"> 
                            Gün {{item.gun_no}}      
                        </td>
                    </tr>
                    <tr *ngFor="let item of dersYukuList">                        
                        <td>
                            {{ item.akademisyen_tc}}
                        </td>
                        <td>
                            {{ item.ders_tipi}}
                        </td>                        
                        <td *ngFor="let itemgun of dersYukuGunList" 
                          [ngStyle]="{'background-color': itemgun.gun == 'Cmt' ||  itemgun.gun == 'Paz' ? 'darkgrey':'none'}">                           
                            <div *ngFor="let itemdetay of item.dersyuku_detay">
                                <div *ngIf="itemgun.gun_tarih == itemdetay.gun_tarih && itemdetay.durum == '';" class="col-md-12"> 
                                        {{itemdetay.ders_saat}}
                                </div>  
                                <div *ngIf="itemgun.gun_tarih == itemdetay.gun_tarih && itemdetay.durum != '';" class="col-md-12"
                                     style="color: red; font-weight: bold;">
                                     {{itemdetay.durum}}
                                </div>                                  
                            </div>
                        </td>                        
                    </tr>
                </table>
            </loading>
        </div>
    </div>
</div>