import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RaporDigerUniversiteDto, RaporFarkDto, RaporFarkDtoParam, RaporSinavDto, RaporSinavDtoParam, RaporSubeDersDto, RaporTelafiDto, RaporTelafiDtoParam } from '../models/Raporlar.model';
import { Response } from '../models/response.model';

@Injectable({
  providedIn: 'root'
})

export class RaporService {
  apiUrl = '/api/rapor'

  constructor(private http: HttpClient) { }
    
  
  getListRaporTelafi(raporTelafiParam: RaporTelafiDtoParam): Observable<Response<RaporTelafiDto[]>> {
    return this.http.post<Response<RaporTelafiDto[]>>(this.apiUrl + '/getlistraportelafi' , raporTelafiParam);    
  }

  getListRaporSinav(raporsinavParam: RaporSinavDtoParam): Observable<Response<RaporSinavDto[]>> {
    return this.http.post<Response<RaporSinavDto[]>>(this.apiUrl + '/getlistraporsinav' , raporsinavParam);    
  }

  getListRaporDigerUniversite(raporTur: string): Observable<Response<RaporDigerUniversiteDto[]>> {
    return this.http.get<Response<RaporDigerUniversiteDto[]>>(this.apiUrl + '/getlistrapordigeruniversite?raporTur=' + raporTur);
  }

  getListRaporSubeDers(): Observable<Response<RaporSubeDersDto[]>> {
    return this.http.get<Response<RaporSubeDersDto[]>>(this.apiUrl + '/getlistraporsubeders');
  }
  
  getListRaporFark(raporfarkParam: RaporFarkDtoParam): Observable<Response<RaporFarkDto[]>> {
    return this.http.post<Response<RaporFarkDto[]>>(this.apiUrl + '/getlistraporfark' , raporfarkParam);    
  }

  
}
