type Nullable<T> = T | null;
export class ResmiTatil {
    id:number=0;
    birim_id:number=0;
    yilhafta:string='';    
    gun_tarih?:  Nullable<Date> = null;    
    gun_no:number=0;    
    adi:string='';    
    idy_tc: string='';
    idt?:  Nullable<Date> = null;
    sdy_tc: string='';
    sdt?:  Nullable<Date> = null;  
    birim_adi: string='';  
    olusturan: string=''; 
}