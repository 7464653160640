<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-newspaper me-2 text-success" style="font-size: large;"></i>Ders Yükü ve KBS
                        Formu
                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporDersYukuKBS.ExcelAktar'])" class="col-md-6"
                style="text-align: right;">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('Ders Yuku.xlsx','bl_subscriptions_table')" style="color: green;">
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button>

            </div>
        </div>
    </div>
    <!-- <form [formGroup]="listForm"> -->
        <div class="post d-flex flex-column-fluid" id="bl_post">
            <div id="bl_content_container" class="container-fluid">
                <div class="card" style="border-top: 3px #77c2e9 solid; ">
                    <div class="card-body" style="padding:1.5rem">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="birimID" [(ngModel)]="secilibirim" name="birimID"
                                        [ngModelOptions]="{standalone: true}" (ngModelChange)="BolumGetir($event)">
                                        <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of birimDto" [ngValue]="item">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="birimID">Birim Adı</label>
                                    <span class="form-text text-danger fs-6" *ngIf="birimHata">{{birimHata}}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div *ngIf="!enstituSelect" class="form-floating mb-5">
                                    <select class="form-select" id="bolumID" [(ngModel)]="secilibolum" name="bolumID"
                                        [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="AnabilimdaliGetir($event)">
                                        <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of bolumDto" [ngValue]="item">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="bolumID">Bölüm Adı</label>
                                </div>
                                <div *ngIf="enstituSelect" class="form-floating mb-5">
                                    <div class="row">
                                        <label style="color: #009ef7;opacity: .65;transform: scale(.85) translateY(-.5rem) translateX(-2rem);" 
                                           class="fw-bold" for="bolumlist">Bölüm Adı</label>
                                    </div>
                                    <div class="row">
                                        <ng-multiselect-dropdown id="bolumlist" [settings]="dropdownSettings" [data]="bolumDto"   
                                           [placeholder]="'Birden fazla bölüm seçebilirsiniz...'"    
                                           [(ngModel)]="secilibolumList" [ngModelOptions]="{standalone: true}" >
                                        </ng-multiselect-dropdown>
                                    </div>  
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div class="form-floating mb-5" style="vertical-align: middle;">
                                    <button
                                        class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                        (click)="GetListByDersYuku()" style="color: #009ef7; width: 70%;">
                                        <i class="fas fa-list text-white"></i>
                                        Birim Bazında Dersi Olanları Listele
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="ogrenimTur" [ngModelOptions]="{standalone: true}"
                                        name="ogrenimTur" [(ngModel)]="ogrenimTur">
                                        <option [value]=''></option>
                                        <option *ngFor="let item of ogrenimTurDto" [value]="item.baslik">
                                            {{item.baslik}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="ogrenimTur">Öğrenim Türü</label>
                                    <span class="form-text text-danger fs-6" *ngIf="ogrenimHata">{{ogrenimHata}}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="programID" [(ngModel)]="seciliprogram"
                                        name="programID" [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="AnaBilimDaliSecim($event)">
                                        <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of programDto" [ngValue]="item">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="programID">Anabilim Dalı Adı</label>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporDersYukuKBS.DersYuku.Gorme'])"
                                    class="form-floating mb-5" style="vertical-align: middle;">
                                    <button [disabled]="buttonyukgoster" (click)="newTabOpenLink('/dersyuku-detay', bolumID)"
                                        class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"                                        
                                        style="color: #009ef7; width: 70%;">
                                        <!-- [routerLink]="['/dersyuku-detay', bolumID]"
                                        [queryParams]="{tc_list: gridSeclist, birim_adi: birimBolumAd, birim_id: birimID, program_id: programID, tarih_1: tarih1, tarih_2: tarih2, ogr_tur: ogrenimTur}"
                                         -->
                                        <!--  -->
                                        <i class="far fa-newspaper text-white"></i>
                                        RP14-Ücrete Yansıyan Ders Yükü
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <input type="date" class="form-control" id="tarih1" [(ngModel)]="tarih1"
                                        placeholder="baslangicTarihi" name="tarih1"
                                        [ngModelOptions]="{standalone: true}" style="border-color: #8fb9d5;" />
                                    <label style="color: #009ef7;" class="fw-bold" for="tarih1">Başlangıç Tarihi</label>
                                    <span class="form-text text-danger fs-6" *ngIf="tarih1Hata">{{tarih1Hata}}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <input type="date" class="form-control" id="tarih2" [(ngModel)]="tarih2"
                                        placeholder="bitisTarihi" name="tarih2" [ngModelOptions]="{standalone: true}"
                                        style="border-color: #8fb9d5;" />
                                    <label style="color: #009ef7;" class="fw-bold" for="tarih2">Bitiş Tarihi</label>
                                    <span class="form-text text-danger fs-6" *ngIf="tarih2Hata">{{tarih2Hata}}</span>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                                <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporDersYukuKBS.KBS.Gorme'])"
                                    class="form-floating mb-5" style="vertical-align: middle;">
                                    <button [disabled]="buttonkbsgoster" 
                                        class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                        [routerLink]="['/dersyuku-kbs', birimID]"
                                        [queryParams]="{tc_list: gridSeclist, birim_adi: birimAdi, bolum_id: bolumID, tarih_1: tarih1, tarih_2: tarih2, ogr_tur: ogrenimTur, bolum_list: secilibolumIDList}"
                                        style="color: #009ef7; width: 70%;">
                                        <!-- (click)="newTabOpenLink('/dersyuku-kbs', birimID)"  -->
                                        <i class="far fa-sticky-note text-white"></i>
                                        RP04-KBS Formu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="card-body py-4" style="border-top: #f5c6c6 1px solid; background-color:white; ">
                    <loading [loader]="yukleniyor">
                        <div style="overflow:auto;width: 100%;">
                            <table class="table table-striped" id="bl_subscriptions_table">
                                <thead>
                                    <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 " style="color:#009ef7;">
                                        <th class="min-w-50px">Sıra No</th>
                                        <th class="min-w-50px">
                                            <input type="checkbox" class="form-check-input border border-success"
                                                [(ngModel)]="selectedAll" [ngModelOptions]="{standalone: true}" (click)="selectAll()" [checked]="selectedAll">
                                            Tümünü Seç
                                        </th>
                                        <th class="min-w-125px">Onay Durumu</th>
                                        <th class="min-w-125px">İdari Görevi</th>
                                        <th class="min-w-125px">Akademisyen Adı</th>                                        
                                        <th class="min-w-125px">Kurum Mail</th>
                                        <th class="min-w-125px">Kurum Sicil No</th>
                                        <th class="min-w-125px">Birim</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-600 fw-bold">
                                    <tr *ngFor="let item of dersyukuList">
                                        <td>
                                            {{ item.sira_no}}
                                        </td>
                                        <td>
                                            <input type="checkbox" class="form-check-input border border-primary"
                                                (change)="secimlist($event,item)" [value]="item.akademisyen_tc"
                                                [checked]="gridSeclist.indexOf(item.akademisyen_tc)>=0">
                                        </td>
                                        <td
                                            [ngStyle]="{'color': item.onay == 'R' ?'red':item.onay == 'O' ?'#0ebd1d': item.onay == 'E' ? '#ffcc00': item.onay == 'H' ? 'blue':'black'}">
                                            {{ item.onay_durumu}}
                                        </td>
                                        <td>
                                            {{ item.idari_gorev_unvan}}
                                        </td>
                                        <td>
                                            <a target="_blank" [routerLink]="['/dersprogram-detay', item.akademisyen_tc]" 
                                                *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.Takvim']); else degil"
                                                [queryParams]="{birim_id: item.kadrobirimid, adsoyadunvan: item.akademisyen_adi_unvan}"
                                                data-toggle="tooltip" data-theme="dark"
                                                title="Akademisyenin ders programını görmek için tıklayınız.">
                                                <i class="far fa-calendar-alt" style="font-size:small;"></i>
                                                <u> {{ item.akademisyen_adi_unvan}}</u>
                                            </a>                                                
                                            <ng-template #degil>
                                                <span> {{ item.akademisyen_adi_unvan}} </span>
                                            </ng-template>
                                        </td>                                        
                                        <td>
                                            {{ item.kurum_mail}}
                                        </td>
                                        <td>
                                            {{ item.kurum_sicil_no }}
                                        </td>
                                        <td>
                                            {{ item.kadro_birim_uzun }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </loading>
                </div>
            </div>
        </div>
    <!-- </form> -->
</div>