import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { Kullanici } from '../models/Kullanici.model';
import { Token } from '../models/token.model';
import { GirisService } from './giris.service';

@Injectable({
  providedIn: 'root'
})
export class YetkiService {

  telDogrulama: boolean = false;
  mernisDogrulama: boolean = false;
  constructor(private toastr: ToastrService ,public girisService: GirisService) { }

  yetkiVar(yetkiler: string | string[]): boolean {
    if (typeof (yetkiler) == 'string') {
      yetkiler = [yetkiler];
    }

    //const token = JSON.parse(this?.girisService.cookieByName()!) as Token;

    const token = JSON.parse(localStorage.getItem('auth')!) as Token;

    const roller = (jwtDecode(token.token) as Kullanici).Rol;
    for (const yetki of yetkiler) {
      if (roller.includes(yetki)) {
        return true;
      }
    }
    return false;
  }


  success(mesaj: string, beklemezamani: number) {
    this.toastr.success(mesaj);
  }

  error(mesaj: string, beklemezamani: number) {
    this.toastr.error(mesaj);
  }

  sweetAlertSuccess(mesaj: string) {
    Swal.fire("Başarırlı", mesaj, 'success');
  }
  sweetAlertError(mesaj: string) {
    Swal.fire("Hata", mesaj, 'error');
  }

  sweetAlertConfirmBox() {
    Swal.fire({
      title: 'Bu kaydı silmek istediğinize eminmisiniz ?',
      text: 'Deneme',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sil',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.value){
        Swal.fire("Silindi","Kayıt başarıyla silindi.",'success')
      }
      else if (result.dismiss===Swal.DismissReason.cancel){
        Swal.fire("İptal edildi","Silme işlemi iptal edildi",'error')
      }

    })

  }




}
