import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GirisGuard } from './guards/giris.guard';
import { GirisFormComponent } from './modules/giris/giris-form/giris-form.component';
import { GirisComponent } from './modules/giris/giris.component';
import { BulunamadiComponent } from './modules/hata/bulunamadi/bulunamadi.component';
import { AnasayfaComponent } from './modules/site/anasayfa/anasayfa.component';
import { SiteComponent } from './modules/site/site.component';
import { AkademikTakvimComponent } from './modules/yonetim/akademiktakvim/akademiktakvim.component';
import { AkademisyenComponent } from './modules/yonetim/akademisyen/akademisyen.component';
import { AkademisyenGuncelleComponent } from './modules/yonetim/akademisyenguncelle/akademisyenguncelle.component';
import { BirimComponent } from './modules/yonetim/birim/birim.component';
import { CizelgeHesapComponent } from './modules/yonetim/cizelge/cizelge-detay/cizelge-detay.component';
import { CizelgeComponent } from './modules/yonetim/cizelge/cizelge.component';
import { DersComponent } from './modules/yonetim/ders/ders.component';
import { DerslikComponent } from './modules/yonetim/derslik/derslik.component';
import { HaftalikDersProgramDetayComponent } from './modules/yonetim/dersprogram/dersprogram-detay/dersprogram-detay.component';
import { DersProgramFormComponent } from './modules/yonetim/dersprogram/dersprogram-form/dersprogram-form.component';
import { DersProgramTumDersComponent } from './modules/yonetim/dersprogram/dersprogram-tumders/dersprogram-tumders.component';
import { HaftalikDersProgramComponent } from './modules/yonetim/dersprogram/dersprogram.component';
import { SinavDetayComponent } from './modules/yonetim/dersprogram/sinav/sinav-detay/sinav-detay.component';
import { SinavComponent } from './modules/yonetim/dersprogram/sinav/sinav.component';
import { TelafiDetayComponent } from './modules/yonetim/dersprogram/telafi/telafi-detay/telafi-detay.component';
import { TelafiComponent } from './modules/yonetim/dersprogram/telafi/telafi.component';
import { DersYukuDetayComponent } from './modules/yonetim/dersyuku/dersyuku-detay/dersyuku-detay.component';
import { DersYukuKbsComponent } from './modules/yonetim/dersyuku/dersyuku-kbs/dersyuku-kbs.component';
import { DersYukuComponent } from './modules/yonetim/dersyuku/dersyuku.component';
import { OgrenciComponent } from './modules/yonetim/ogrenci/ogrenci.component';
import { RaporDigerAkademisyenComponent } from './modules/yonetim/raporlar/rapordigerakademisyen/rapordigerakademisyen.component';
import { RaporDigerUniversiteComponent } from './modules/yonetim/raporlar/rapordigeruniversite/rapordigeruniversite.component';
import { RaporFarkComponent } from './modules/yonetim/raporlar/raporfark/raporfark.component';
import { RaporIzinComponent } from './modules/yonetim/raporlar/raporizin/raporizin.component';
import { RaporSinavComponent } from './modules/yonetim/raporlar/raporsinav/raporsinav.component';
import { RaporSubeDersComponent } from './modules/yonetim/raporlar/raporsubeders/raporsubeders.component';
import { RaporTelafiComponent } from './modules/yonetim/raporlar/raportelafi/raportelafi.component';
import { ResmiTatilDetayComponent } from './modules/yonetim/resmitatil/resmitatil-detay/resmitatil-detay.component';
import { ResmiTatilComponent } from './modules/yonetim/resmitatil/resmitatil.component';
import { TopluHesapComponent } from './modules/yonetim/topluhesap/topluhesap.component';
import { TopluDonemUzatComponent } from './modules/yonetim/topludonemuzat/topludonemuzat.component';
import { YonetimAnasayfaComponent } from './modules/yonetim/yonetim-anasayfa/yonetim-anasayfa.component';
import { YonetimComponent } from './modules/yonetim/yonetim.component';

const routes: Routes = [
  {
    path: 'giris', component: GirisComponent, children: [
      { path: '', component: GirisFormComponent }
    ]
  },
  {
    path: 'site', component: SiteComponent, children: [
      { path: '', component: AnasayfaComponent }
    ]
  },  
  {
    path: '', component: YonetimComponent, children: [
      { path: '', component: YonetimAnasayfaComponent, canActivate: [GirisGuard] },        
      {
        path: 'derslik', children: [
          { path: '', component: DerslikComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Derslik.Gorme"]} },          
        ]
      },
      {
        path: 'birim', children: [
          { path: '', component: BirimComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Birim.Gorme"]} },          
        ]
      },
      {
        path: 'ders', children: [
          { path: '', component: DersComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Ders.Gorme"]} },          
        ]
      },
      {
        path: 'akademisyen', children: [
          { path: '', component: AkademisyenComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Akademisyen.Gorme"]} },          
        ]
      },
      {
        path: 'ogrenci', children: [
          { path: '', component: OgrenciComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Ogrenci.Gorme"]} },          
        ]
      },
      {
        path: 'dersprogram', children: [
          { path: '', component: HaftalikDersProgramComponent, canActivate: [GirisGuard],data: { roller:["EkDers.DersProgram.Gorme"]} },         
        ]
      },
      {
        path: 'dersprogram-detay', children: [
          { path: ':tc', component: HaftalikDersProgramDetayComponent, canActivate: [GirisGuard],data: { roller:["EkDers.DersProgram.Takvim"]} },
        ]
      },  
      {
        path: 'dersprogram-form', children: [
          { path: ':akademisyen_tc', component: DersProgramFormComponent, canActivate: [GirisGuard],data: { roller:["EkDers.DersProgram.GunlukForm.Gorme"]} },
        ]
      }, 
      {
        path: 'dersprogram-tumders', children: [
          { path: ':akademisyen_tc', component: DersProgramTumDersComponent, canActivate: [GirisGuard],data: { roller:["EkDers.DersProgram.TumDersForm.Gorme"]} },
        ]
      }, 
      {
        path: 'cizelge', children: [
          { path: '', component: CizelgeComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Cizelge.Gorme"]} },          
        ]
      },
      {
        path: 'cizelge-detay', children: [
          { path: ':birimID', component: CizelgeHesapComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Cizelge.DetayGorme"]} },
        ]
      },
      {
        path: 'akademiktakvim', children: [
          { path: '', component: AkademikTakvimComponent, canActivate: [GirisGuard],data: { roller:["EkDers.AkademikTakvim.Gorme"]} },          
        ]
      },
      {
        path: 'resmitatil', children: [
          { path: '', component: ResmiTatilComponent, canActivate: [GirisGuard],data: { roller:["EkDers.ResmiTatil.Gorme"]} },   
          { path: ':id', component: ResmiTatilDetayComponent, canActivate: [GirisGuard],data: { roller:["EkDers.ResmiTatil.Ekle"]} },       
        ]
      },     
      {
        path: 'sinav', children: [
          { path: ':tc', component: SinavComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Sinav.Gorme"]} },   
          { path: ':tc/:id', component: SinavDetayComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Sinav.Degistir"]} },       
        ]
      },  
      {
        path: 'sinav-detay', children: [           
          { path: ':id', component: SinavDetayComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Sinav.Ekle"]} },       
        ]
      }, 
      {
        path: 'telafi', children: [
          { path: ':tc', component: TelafiComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Telafi.Gorme"]} },   
          { path: ':tc/:id', component: TelafiDetayComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Telafi.Degistir"]} },       
        ]
      },  
      {
        path: 'telafi-detay', children: [           
          { path: ':id', component: TelafiDetayComponent, canActivate: [GirisGuard],data: { roller:["EkDers.Telafi.Ekle"]} },       
        ]
      }, 
      {
        path: 'dersyuku', children: [
          { path: '', component: DersYukuComponent, canActivate: [GirisGuard],data: { roller:["EkDers.RaporDersYukuKBS.Gorme"]} },          
        ]
      },
      {
        path: 'dersyuku-detay', children: [
          { path: ':bolumID', component: DersYukuDetayComponent, canActivate: [GirisGuard],data: { roller:["EkDers.RaporDersYukuKBS.DersYuku.Gorme"]} },
        ]
      },
      {
        path: 'dersyuku-kbs', children: [
          { path: ':birimID', component: DersYukuKbsComponent, canActivate: [GirisGuard],data: { roller:["EkDers.RaporDersYukuKBS.KBS.Gorme"]} },
        ]
      },
      {
        path: 'topluhesap', children: [
          { path: '', component: TopluHesapComponent, canActivate: [GirisGuard],data: { roller:["EkDers.TopluHesapla"]} },          
        ]
      },
      {
        path: 'topludonemuzat', children: [
          { path: '', component: TopluDonemUzatComponent, canActivate: [GirisGuard],data: { roller:["EkDers.TopluDonemUzat"]} },          
        ]
      },
      {
        path: 'akademisyenguncelle', children: [
          { path: '', component: AkademisyenGuncelleComponent, canActivate: [GirisGuard],data: { roller:["EkDers.BirimGuncelle"]} },          
        ]
      },
      {
        path: 'raportelafi', children: [
          { path: '', component: RaporTelafiComponent, canActivate: [GirisGuard],data: { roller:["EkDers.RaporTelafi.Gorme"]} },          
        ]
      },
      {
        path: 'raporsinav', children: [
          { path: '', component: RaporSinavComponent, canActivate: [GirisGuard],data: { roller:["EkDers.RaporSinav.Gorme"]} },          
        ]
      },
      {
        path: 'rapordigeruniversite', children: [
          { path: '', component: RaporDigerUniversiteComponent, canActivate: [GirisGuard],data: { roller:["EkDers.RaporDigerUniversite.Gorme"]} },          
        ]
      },
      {
        path: 'rapordigerakademisyen', children: [
          { path: '', component: RaporDigerAkademisyenComponent, canActivate: [GirisGuard],data: { roller:["EkDers.RaporDigerAkademisyen.Gorme"]} },          
        ]
      },
      {
        path: 'raporsubeders', children: [
          { path: '', component: RaporSubeDersComponent, canActivate: [GirisGuard],data: { roller:["EkDers.RaporDersSube.Gorme"]} },          
        ]
      },
      {
        path: 'raporizin', children: [
          { path: '', component: RaporIzinComponent, canActivate: [GirisGuard],data: { roller:["EkDers.RaporIzin.Gorme"]} },          
        ]
      },
      {
        path: 'raporfark', children: [
          { path: '', component: RaporFarkComponent, canActivate: [GirisGuard],data: { roller:["EkDers.RaporFark.Gorme"]} },          
        ]
      },
    
    ]
  },  
  {
    path: '**',
    component: BulunamadiComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
