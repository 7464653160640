<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0 col-md-6 col_xs-12">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-calendar-alt me-2 text-success" style="font-size: large;"></i>Akademisyen
                        Haftalık Ders Program Listesi
                    </span>
                </h1>
            </div>           
            <div class="col-md-6 col_xs-12" *ngIf="yetkiService.yetkiVar(['EkDers.Akademisyen.HepsiniGorme'])">
                <form id="kt_sidebar_search_form" class="w-200px w-xxl-250px position-relative d-lg-block float-end">
                    <div class="d-flex align-items-center ms-1 ms-lg-3 cursor-pointer symbol symbol-30px symbol-md-40px"
                        data-kt-menu-placement="bottom-end">
                        <span
                            class="svg-icon svg-icon-4 svg-icon-gray-700 position-absolute top-50 translate-middle-y ms-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="black">
                                </rect>
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="black">
                                </path>
                            </svg>
                        </span>
                        <input type="text" (keydown.enter)="searchChange($event)"
                            class="form-control form-control-sm bg-transparent ps-11 fs-7" [(ngModel)]="kisiArama"
                            [ngModelOptions]="{standalone: true}" id="kisiArama" placeholder="Kişi Arama"
                            name="kisiArama">
                    </div>
                </form>
                <!-- <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('AkademisyenListesi.xlsx','bl_subscriptions_table')" style="color: green;">
                    *ngIf="yetkiService.yetkiVar(['Ekders.Yazdir'])"
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button> -->
            </div>
        </div>
    </div>
    <form [formGroup]="listForm">
        <div class="post d-flex flex-column-fluid" id="bl_post">
            <div id="bl_content_container" class="container-fluid">

                <div class="card" style="border-top: 2px #77c2e9 solid; ">
                    <div class="card-body" style="padding:1.5rem">
                        <div class="row">

                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="birimID" [(ngModel)]="birimID" name="birimID"
                                        [ngModelOptions]="{standalone: true}" (change)="BolumGetir()">
                                        <option [ngValue]=0>Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of birimDto" [ngValue]="item.birimid">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="birimID">Birim Adı</label>
                                    <span class="form-text text-danger fs-6" *ngIf="birimHata">{{birimHata}}</span>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="bolumID" [ngModelOptions]="{standalone: true}"
                                        name="bolumID" [(ngModel)]="bolumID" (change)="AnaBilimDaliGetir()">
                                        <option [ngValue]=0>Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of bolumDto" [ngValue]="item.birimid">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="bolumID">Bölüm Adı</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="programID" [ngModelOptions]="{standalone: true}"
                                        name="programID" [(ngModel)]="programID">
                                        <option [ngValue]=0>Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of programDto" [ngValue]="item.birimid">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="programID">Anabilim Dalı Adı</label>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-4 text-left">
                                <div class="form-floating mb-5" style="vertical-align: middle;">
                                    <button
                                        class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                        (click)="GetListByAkademisyen()" style="color: #009ef7;">
                                        <i class="fas fa-list text-white"></i>
                                        Akademisyenleri Listele
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.Onayla'])" class="col-md-4" style="text-align: left; padding-top: 5px;">
                                <input type="checkbox" class="form-check-input me-4" (change)="onayBekleyen($event,'E')"
                                    [checked]="false">
                                <label class="form-check-label checkbox-lg text-black text-justify"
                                    style="font-size:small;" for="Check1">Onay Bekleyenler </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body py-4" style="border-top: #f5c6c6 1px solid; background-color:white; ">
                    <loading [loader]="yukleniyor">
                        <div style="overflow:auto;width: 100%;">
                            <table class="table table-striped" id="bl_subscriptions_table">
                                <thead>
                                    <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 " style="color:#009ef7;">
                                        <th>No</th>
                                        <th class="min-w-125px">Adı Soyadı</th>
                                        <th class="min-w-125px">Ünvan</th>
                                        <th>TC Kimlik No</th>
                                        <th>Kurum Sicil No</th>
                                        <th class="min-w-125px">E-Mail</th>
                                        <th class="min-w-125px">Birim</th>
                                        <th>Aktif</th>
                                        <th class="text-center min-w-100px">Prg. Göster</th>
                                        <th class="text-center min-w-150px">İşlemler</th>
                                    </tr>
                                </thead>
                                <tbody class="text-gray-600 fw-bold">
                                    <tr *ngFor="let item of akademisyenListDto" style="vertical-align: middle;">
                                        <td>
                                            {{ item.personelid }}
                                        </td>
                                        <td>
                                            {{ item.ad}} {{ item.soyad}}
                                        </td>
                                        <td>
                                            {{ item.akunvan == null ? item.unvan : item.akunvan}}
                                        </td>
                                        <td>
                                            {{ item.tc}}
                                        </td>
                                        <td>
                                            {{ item.kurumsicilno}}
                                        </td>
                                        <td>
                                            {{ item.kurummail }}
                                        </td>
                                        <td>
                                            {{ item.kadrobirimuzun}}
                                        </td>
                                        <td>
                                            {{ item.kayitdurumu }} 
                                        </td>
                                        <td class="text-center">

                                            <a target="_blank" [routerLink]="['/dersprogram-detay', item.tc]" *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.Takvim'])"
                                                [queryParams]="{birim_id: item.kadrobirimid, adsoyadunvan: (item.akunvan == null ? item.unvankisa : item.akunvankisa) +' '+ item.ad +' '+ item.soyad}"
                                                data-toggle="tooltip" data-theme="dark"
                                                title="Haftalık ders programını görmek için tıklayınız."
                                                class="btn  btn-icon btn-sm me-1 btn-primary">
                                                <i class="far fa-calendar-alt text-white" style="font-size:large;"></i>
                                            </a>
                                            <!-- [state]="{unvan: item.unvankisa, ad: item.ad, soyad: item.soyad, birim_id: birimID}" -->
                                        </td>
                                        <td class="text-center">
                                            <div *ngIf="yetkiService.yetkiVar(['EkDers.Sinav.Gorme'])" class="row" style="width: 175px;">
                                                <div class="col-md-12">
                                                    <a target="_blank" [routerLink]="['/sinav', item.tc]"
                                                        [queryParams]="{donem_id: donemID, adsoyadunvan: (item.akunvan == null ? item.unvankisa : item.akunvankisa) +' '+ item.ad +' '+ item.soyad}"
                                                        data-toggle="tooltip" data-theme="dark" title="Sınav Bilgileri"
                                                        class="btn  btn-hover-rise  btn-sm  p-1"
                                                        style="color:black; float: right; border: 2px solid #009EF8">
                                                        <i class="fas fa-scroll text-success "></i> Sınav
                                                        Ekle/Düzenle</a>
                                                </div>
                                                <div *ngIf="yetkiService.yetkiVar(['EkDers.Telafi.Gorme'])" class="col-md-12 mt-1">
                                                    <a target="_blank" [routerLink]="['/telafi', item.tc]"
                                                        [queryParams]="{donem_id: donemID, adsoyadunvan: (item.akunvan == null ? item.unvankisa : item.akunvankisa) +' '+ item.ad +' '+ item.soyad}"
                                                        data-toggle="tooltip" data-theme="dark" title="Telafi Bilgileri"
                                                        class="btn  btn-hover-rise  btn-sm  p-1 "
                                                        style="color:black; float: right; border: 2px solid #009EF8">
                                                        <i class="fas fa-map  text-primary"></i>
                                                        Telafi Ekle/Düzenle</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </loading>
                </div>
            </div>
        </div>
    </form>
</div>