import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { AkademikTakvimDto } from 'src/app/models/AkademikTakvim.model';
import { AkademikTakvimService } from 'src/app/services/akademiktakvim.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'akademiktakvim',
  templateUrl: './akademiktakvim.component.html',
  styleUrls: ['./akademiktakvim.component.css']
})

export class AkademikTakvimComponent implements OnInit {
  
  constructor(private akademiktakvimService: AkademikTakvimService, 
              public yetkiService: YetkiService) {
    this.service = akademiktakvimService;    
   }

  yukleniyor=false;   
  
  service: AkademikTakvimService;  
  akademikTakvimDto: AkademikTakvimDto[] = [];
  birimID: number = 0;

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.GetListAkademikTakvim();  
  }

  GetListAkademikTakvim() {   
    this.yukleniyor = true;
    this.service.getAkademikTakvim().subscribe(respone => {
      this.akademikTakvimDto = respone.data; 
      this.yukleniyor = false;        
    });
  }
  
  exportexcel(fileName: string,  tableName: string): void {    
    var element = document.getElementById(tableName);     
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
