import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { TelafiIptal } from 'src/app/models/TelafiIptal.model';
import { TelafiService } from 'src/app/services/telafi.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { DatePipe } from '@angular/common';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';
import { DersProgramDerslerDto } from 'src/app/models/Dersler.model';

@Component({
  selector: 'telafi-detay',
  templateUrl: './telafi-detay.component.html',
  styleUrls: ['./telafi-detay.component.css']
})
export class TelafiDetayComponent implements OnInit {

  constructor(private telafiService: TelafiService, 
              private dersService: HaftalikDersProgramService,
              private validationService: ValidationService, 
              private route: ActivatedRoute, 
              private formBuilder: FormBuilder,
              public yetkiService : YetkiService,
              private router: Router
              ) { }

  detayForm = this.formBuilder.group(new TelafiIptal());
  yeni = true;
  telafi: TelafiIptal = new TelafiIptal();  
  dersDto: DersProgramDerslerDto[] = [];
  yukleniyor = false;
  kaydediliyor = false;  
  ders_id: number = 0;  
  birim_id: number = 0;  
  bolum_id: number = 0;  
  program_id: number = 0;  
  akademik_takvim_id: number = 0; 
  donem_id: number = 0;  
  ogrenim_turu: string = '';  
  ders_tipi: string = '';  
  ders_saat: number = 0;
  akademisyen_tc: string = '';  
  akademisyen_AdSoyadUnvan: string = '';  
  hatalar: any = {};
  dersHata: string | null = '';

  seciliders : DersProgramDerslerDto |undefined; 

  ngOnInit(): void {    
    this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(qparams => {
        if (qparams['adsoyadunvan']) {              
          this.akademisyen_AdSoyadUnvan = qparams['adsoyadunvan'];  
          this.donem_id = qparams['donem_id']; 
        }        
      })

      if (params['tc']) { 
        this.akademisyen_tc = params['tc']; 
        this.DersleriGetir(params['tc']); 
      }

      if (params['id'] != 'yeni') {        
        this.getById(params['tc'], params['id']);
      }      
      
    });
  }

  getById(akademisyenTC: string, id: number) {
    this.yukleniyor = true; 
    this.telafiService.getById(id).subscribe(res => {
      this.telafi = res.data;
      this.yeni = false;
      this.detayForm.patchValue(res.data);    
      this.DersleriGetir(akademisyenTC, res.data.iptal_ders_id, res.data.iptal_ders_tipi); 
      //this.yukleniyor = false;
    });
  }

  DersleriGetir(akademisyenTC: string, dersID: number = 0, dersTipi: string = '') {  
    this.yukleniyor = true;   
     this.dersService.getDersProgramDerslerList(akademisyenTC).subscribe(respone => {
      this.dersDto = respone.data;  
      if (respone.data.length > 0 && dersID > 0){
        this.seciliders = respone.data.find(p => p.ders_id == dersID && p.ders_tipi == dersTipi);
        this.DersBilgiVer(this.seciliders); 
      }
      this.yukleniyor = false;
    }); 
  }

  DersBilgiVer(item: any) {  
    this.seciliders = item;   
    if (item!=undefined){  
      this.ders_id = item.ders_id;
      this.akademik_takvim_id = item.akademik_takvim_id;  
      this.donem_id = item.donem_id;  
      this.birim_id = item.birim_id;  
      this.bolum_id = item.bolum_id;    
      this.program_id = item.program_id;    
      this.ogrenim_turu = item.ogrenim_turu;
      this.ders_tipi = item.ders_tipi;   
      this.ders_saat = item.ders_saat;
      this.dersHata = null;  
    } 
    else {           
      this.ders_id = 0;
      this.akademik_takvim_id = 0;
      this.donem_id = 0;
      this.birim_id = 0;  
      this.bolum_id = 0;
      this.program_id = 0;
      this.ogrenim_turu = ''; 
      this.ders_tipi = ''; 
      this.ders_saat = 0;
    }     
  }  

  kaydet() {
    let hataders = this.seciliders == undefined ? 'Lütfen Ders Adı Seçiniz.' : null;   
    this.dersHata = hataders;    
    if (!this.dersHata) { 
      this.kaydediliyor = true;
      this.hatalar = {};
      var telafi = this.detayForm.value;
      telafi.akademisyen_tc = this.akademisyen_tc;
      telafi.aktif = 'E';
    
      const datepipe: DatePipe = new DatePipe('en-US');
      let telafitarih = datepipe.transform(telafi.telafi_tarihi, 'yyyy-MM-dd');
      let iptalderstarih = datepipe.transform(telafi.iptal_ders_tarihi, 'yyyy-MM-dd');
      let resmiyazitarih = datepipe.transform(telafi.resmi_yazi_tarihi, 'yyyy-MM-dd');
      telafi.telafi_tarihi = telafitarih; 
      telafi.iptal_ders_tarihi = iptalderstarih; 
      telafi.resmi_yazi_tarihi = resmiyazitarih; 

      telafi.iptal_ders_id = this.ders_id;
      telafi.akademik_takvim_id = this.akademik_takvim_id;  
      telafi.donem_id = this.donem_id;  
      telafi.birim_id = this.birim_id;  
      telafi.bolum_id = this.bolum_id;  
      telafi.program_id = this.program_id;  
      telafi.ogrenim_turu = this.ogrenim_turu;  
      telafi.iptal_ders_tipi = this.ders_tipi;  
      telafi.iptal_ders_saat = this.ders_saat;  
      console.log(telafi);
      this.telafiService.add(telafi).pipe(finalize(() => {
        this.kaydediliyor = false;
      })).subscribe({
        next: res => {
          if (res?.message && res?.message != "" ) {this.yetkiService.success(res?.message,1)};          
          this.router.navigateByUrl('/telafi/'+ this.akademisyen_tc +"?donem_id="+ this.donem_id)
        },
        error: err => {
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          if (err.error.Errors == undefined ||err.error.Errors == null ||err.error.Errors?.length==0){
            if (err.error?.message && err.error?.message != "" ) {this.yetkiService.error(err.error?.message,1)};
            if (err.error?.Message && err.error?.Message != "" ) {this.yetkiService.error(err.error?.Message,1)};
          }
        }
      });   
  
    }
  }

}
