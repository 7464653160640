import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AkademikTakvimDto } from '../models/AkademikTakvim.model';
import { Response } from '../models/response.model';


@Injectable({
  providedIn: 'root'
})
export class AkademikTakvimService {
  apiUrl = '/api/genel'
  constructor(private http: HttpClient) { }
  
  getAkademikTakvim(birimid: number=0, donemuzat: boolean=false, gecmisdonem: boolean=false): Observable<Response<AkademikTakvimDto[]>> {
    return this.http.get<Response<AkademikTakvimDto[]>>(this.apiUrl + '/akademiktakvimlist?BirimID=' + birimid +'&donemuzat='+ donemuzat +'&gecmisdonem='+ gecmisdonem);
  }
}
