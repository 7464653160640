import { AkademisyenGuncelleDto, AkademisyenGuncelleParam, IdariGorevDto, IzinlerDto } from './../../../models/Akademisyenler.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { GenelService } from 'src/app/services/genel.service';
import { BirimService } from 'src/app/services/birim.service';
import { AkademisyenlerDto, AkademisyenParam } from 'src/app/models/Akademisyenler.model';
import { AkademisyenService } from 'src/app/services/akademisyen.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';
import { ValidationService } from 'src/app/services/validation.service';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import Swal from 'sweetalert2';


@Component({
  selector: 'akademisyen',
  templateUrl: './akademisyen.component.html',
  styleUrls: ['./akademisyen.component.css']
})


export class AkademisyenComponent implements OnInit {


  aktifsayfa=1;
  yukleniyor=false;
  service: AkademisyenService;
  akademisyenListDto: AkademisyenlerDto[] = [];
  seciliAkademisyen: AkademisyenlerDto = new AkademisyenlerDto();
  akademisyenParam: AkademisyenParam = new AkademisyenParam();
  birimListDto: BirimlerDto[] = [];
  birimParam: BirimParam = new BirimParam();
  birimDto: any[] = [];
  bolumDto: any[] = [];
  unvanDto: any[] = [];
  birimID: number = 0;
  bolumID: number = 0;
  unvanID: number = 0;
  adSoyad: string = '';
  personelID: number = 0;
  personelTC: string = '';


  hatalar: any = {};
  tekpersonelyukleniyor = false;
  akademisyen_resim: String = '';
  seciliAkademisyen_izin: IzinlerDto[]= [];
  seciliAkademisyen_gorevizin: IzinlerDto[]= [];


  bg_yukleniyor=false;
  bg_kaydediliyor = false;
  bg_hatalar: any = {};

  bg_akademisyenDto: AkademisyenlerDto[] = [];
  bg_akademisyenGuncelleDto: AkademisyenGuncelleDto[] = [];
  bg_akademisyenParam: AkademisyenParam = new AkademisyenParam();
  bg_guncelleParam: AkademisyenGuncelleParam = new AkademisyenGuncelleParam();
  bg_birimListDto: BirimlerDto[] = [];
  bg_birimParam: BirimParam = new BirimParam();
  bg_birimDto: any[] = [];
  bg_bolumDto: any[] = [];
  bg_programDto: any[] = [];
  bg_birimID: number = -1;
  bg_bolumID: number = -1;
  bg_abdID: number = 0;
  bg_personelTC: string = '';
  bg_akademisyenTC: string = '';
  bg_akademisyenbirimID: number = 0;
  bg_turler:any[]=[];

  bg_birimHata: string | null = '';
  bg_bolumHata: string | null = '';
  bg_tcHata: string | null = '';



  constructor(private akademisyenService: AkademisyenService,
              private birimService: BirimService,
              public yetkiService: YetkiService,
              private genelService: GenelService,
              private formBuilder: FormBuilder,
              private validationService: ValidationService,
              private route: ActivatedRoute,

              ) {
    this.service = akademisyenService;
   }

   listForm = this.formBuilder.group({
    akademisyenForm: this.formBuilder.group(new AkademisyenlerDto()),
  });


  pbsGuncelle(akademisyen : AkademisyenlerDto){
    akademisyen.pbsguncelleniyor = true;
    this.service.getPbsPersonelGuncelle(akademisyen.tc).pipe(finalize(() => {
      akademisyen.pbsguncelleniyor = false;
    })).subscribe({
      next: res => {
        this.yetkiService.success("Bilgiler Başarıyla Senkronize Edildi",1);
        this.akademisyenSecildi(akademisyen);
      },
      error: err => {
        this.yetkiService.error("Güncelleme Yapılamadı",1)
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined || err.error.Errors == null || err.error.Errors?.length == 0) {
          if (err.error?.message && err.error?.message != "") { this.yetkiService.error(err.error?.message, 1) };
          if (err.error?.Message && err.error?.Message != "") { this.yetkiService.error(err.error?.Message, 1) };
        }
      }
    });

  }

  akademisyenForm(): FormGroup {
    return this.listForm.get('akademisyenForm') as FormGroup;
  }


  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.BirimleriGetir();
    this.bg_BirimleriGetir();
    this.UnvanlariGetir();
  }

  akademisyenSecildi(item : AkademisyenlerDto){
    this.aktifsayfa=1;
    this.tekpersonelyukleniyor=true;
    var ap = new AkademisyenParam();
    ap.personeltc = item.tc;
    ap.resimdurum = 1;
    this.bg_birimID = -1;
    this.bg_bolumID = -1;
    this.bg_abdID = 0;
    this.bg_birimHata = null;
    this.bg_bolumHata = null;
    this.bg_tcHata = null;

    this.akademisyenService.getAllAkademisyenList(ap).pipe(finalize(() => {
      this.tekpersonelyukleniyor = false;
    })).subscribe({
      next: respone => {
        if (respone.data.length > 0) {
          respone.data.forEach(e => {
            this.seciliAkademisyen = e;
            this.akademisyen_resim='data:image/png;base64,' + e.resim;
            this.seciliAkademisyen_izin = e.izinler.filter(p=>p.izintipi!='Gorevlendirme');
            this.seciliAkademisyen_gorevizin = e.izinler.filter(p=>p.izintipi=='Gorevlendirme');

            var sc = this.akademisyenListDto.filter(p=>p.personelid==this.seciliAkademisyen.personelid).indexOf;
            if (sc!=undefined) {
            //  this.seciliAkademisyen.pbsguncelleniyor=this.akademisyenListDto[Number(sc)].pbsguncelleniyor;
              this.akademisyenListDto[Number(sc)]=this.seciliAkademisyen;
            }
          });
        }
      },
      error: err => {
        this.seciliAkademisyen_gorevizin = [];
        this.seciliAkademisyen_gorevizin = [];
      }
    });
  }

  GetListByAkademisyen() {
    var secbirimID : number = 0;
    if (this.bolumID != 0) {   secbirimID = this.bolumID;}
    else if (this.birimID != 0) {   secbirimID = this.birimID;}
    //alert(secbirimID)
    this.yukleniyor = true;
    this.akademisyenParam.kadrobirimid = secbirimID;
    this.akademisyenParam.unvanid = this.unvanID;
    this.akademisyenParam.adsoyad = this.adSoyad;
    this.akademisyenParam.personelid = this.personelID;
    this.akademisyenParam.personeltc = this.personelTC;

    this.service.getAllAkademisyenList(this.akademisyenParam).subscribe(respone => {
      this.akademisyenListDto = respone.data;
      this.yukleniyor = false;
    });
  }

  BirimleriGetir() {
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19,44,20]; //2,6,10,19,44,20 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul, Koordinatörlük, Formasyon
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      this.birimDto = respone.data;
      this.birimID = 0;
      this.bolumID = 0;
    });
  }

  BolumGetir() {
    this.birimParam.ustbirimid = this.birimID;
    this.birimParam.tip = 'Bolum';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [];
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      if (this.birimID != 0) {
        if (respone.data.length > 0) {
          this.bolumDto = respone.data;
        } else {
          this.bolumDto = [];
        }
      }
      else {
        this.bolumDto = [];
      }
      this.bolumID = 0;
    });
  }

  UnvanlariGetir() {
    this.genelService.getUnvanlarList().subscribe(respone => {
      this.unvanDto = respone.data;
      this.unvanID = 0;
     /*  if (respone.data.length > 0) {
        this.unvanID = 1;
      }
      else {
        this.unvanID = 0;
      }     */
    });
  }


  exportexcel(fileName: string,  tableName: string): void {
    var element = document.getElementById(tableName);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

  bg_Guncelle() {
    Swal.fire({
      title: 'Bu akademisyene ait birimi güncellemek istediğinize emin misiniz ?',
      text: 'GÜNCELLE İŞLEMİ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Güncelle',
      cancelButtonText: 'Vazgeç',
    }).then((result) => {
      if (result.value) {
        this.bg_BirimGuncelle();
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Vazgeçildi", "Güncelleme yapılmadı", 'error')
      }
    })
  }

  bg_BirimGuncelle() {
    var secbirimID : number = 0;
    let hataBirim = this.bg_birimID <= 0 ? 'Lütfen Birim Seçiniz.' : null;
    let hataBolum = this.bg_bolumID <= 0 ? 'Lütfen Bölüm Seçiniz.' : null;
    let hatatc = this.seciliAkademisyen.tc == '' ? 'Akademisyen seçiminde hata oluşmuş' : null;
    if (this.bg_birimID > 0) {

      if (!this.yetkiService.yetkiVar(['EkDers.AnaBirimGuncelle'])){
        hataBirim = this.seciliAkademisyen.kadrobirimid != this.bg_birimID ? 'Farklı bir birimdeki akademisyenle ilgili işlem yapmaya çalışıyorsunuz. Bu işlem için yetkiniz yok. Kadro aktarımı için lütfen Personel Daire Başkanlığı ile irtibata geçiniz...' : null;
      }



    }

    this.bg_birimHata = hataBirim;
    this.bg_bolumHata = hataBolum;
    this.bg_tcHata = hatatc;
    if ((!this.bg_birimHata) && (!this.bg_bolumHata) && (!this.bg_tcHata)) {

      if (this.bg_abdID != 0) {          secbirimID = this.bg_abdID;  }
      else if (this.bg_bolumID > 0) {   secbirimID = this.bg_bolumID;}
      else if (this.birimID > 0) {   secbirimID = this.bg_birimID;}

      this.bg_yukleniyor = true;
      this.bg_hatalar = {};
      this.bg_guncelleParam.tc = this.seciliAkademisyen.tc;
      this.bg_guncelleParam.birim_id = secbirimID;
      this.service.AkademisyenBirimGuncelle(this.bg_guncelleParam).pipe(finalize(() => {
        this.bg_yukleniyor = false;
      })).subscribe({
        next: res => {
          if (res?.message && res?.message != "" ) {this.yetkiService.success(res?.message,1)};
          this.akademisyenSecildi(this.seciliAkademisyen);
        },
        error: err => {
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          if (err.error.Errors == undefined ||err.error.Errors == null ||err.error.Errors?.length==0){
            if (err.error?.message && err.error?.message != "" ) {this.yetkiService.error(err.error?.message,1)};
            if (err.error?.Message && err.error?.Message != "" ) {this.yetkiService.error(err.error?.Message,1)};
          }
        }
      });
    }
  }

  bg_BirimleriGetir() {
    this.bg_birimParam.ustbirimid = 1;
    this.bg_birimParam.tip = 'Birim';
    this.bg_birimParam.durum = [];
    this.bg_birimParam.birimtur = [2,6,10,19]; //2,6,10,19 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul
    this.birimService.getAllBirimList(this.bg_birimParam).subscribe(respone => {
      this.bg_birimDto = respone.data;
      this.bg_birimID = -1;
      this.bg_bolumID = -1;
      this.bg_abdID = 0;
      this.bg_programDto = [];
    });
  }

  bg_BolumGetir() {
    this.bg_birimParam.ustbirimid = this.bg_birimID;//item.target.value;
    this.bg_birimParam.tip = 'Bolum';
    this.bg_birimParam.birimtur = [];
    this.bg_birimParam.durum = [];
    this.bg_programDto = [];
    this.birimService.getAllBirimList(this.bg_birimParam).subscribe(respone => {
      if (this.bg_birimID != -1) {
        if (respone.data.length > 0) {
          this.bg_bolumDto = respone.data;
        } else {
          this.bg_bolumDto = [];
          this.bg_programDto = [];
        }
      }
      else {
        this.bg_bolumDto = [];
        this.bg_programDto = [];
      }
      this.bg_bolumID = -1;
      this.bg_abdID = 0;
    });
  }



  bg_AnabilimdaliGetir() {
    this.bg_birimParam.ustbirimid = this.bg_bolumID;//item.target.value;
    this.bg_birimParam.tip = 'Program';
    this.bg_birimParam.birimtur = [];
    this.bg_birimParam.durum = [];
    this.birimService.getAllBirimList(this.bg_birimParam).subscribe(respone => {
      if (this.bg_bolumID != -1) {
        if (respone.data.length > 0) {
          this.bg_programDto = respone.data;
        } else {
          this.bg_programDto = [];
          this.bg_abdID = 0;
        }
      }
      else {
        this.bg_programDto = [];
        this.bg_abdID = 0;
      }
    });
  }


}
