import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DonemlerDto } from '../models/Donemler.model';
import { DersTurlerDto } from '../models/DersTurler.model';
import { OgrenimTurlerDto } from '../models/OgrenimTurler.model';
import { HaftaDto } from '../models/Haftalar.model';
import { UnvanlarDto } from '../models/Unvanlar.model';
import { Response } from '../models/response.model';
import * as XLSX from 'xlsx';
import { stringCase } from 'src/assets/plugins/formvalidation/dist/js/locales/ar_MA';

@Injectable({
  providedIn: 'root'
})

export class GenelService {
  apiUrl = '/api/genel'

  constructor(private http: HttpClient) { }
    
  

  getDonemlerList(): Observable<Response<DonemlerDto[]>> {
    return this.http.get<Response<DonemlerDto[]>>(this.apiUrl + '/donemler');
  }
 
  getDersTurlerList(): Observable<Response<DersTurlerDto[]>> {
    return this.http.get<Response<DersTurlerDto[]>>(this.apiUrl + '/dersturler');
  }

  getOgrenimTurlerList(): Observable<Response<OgrenimTurlerDto[]>> {
    return this.http.get<Response<OgrenimTurlerDto[]>>(this.apiUrl + '/ogrenimturler');
  }

  getUnvanlarList(): Observable<Response<UnvanlarDto[]>> {
    return this.http.get<Response<UnvanlarDto[]>>(this.apiUrl + '/unvanlar');
  } 

  getHaftaList(tip: string ='', ilktarih: string ='', sontarih: string =''): Observable<Response<HaftaDto[]>> {
    //let ilk = ilktarih.toDateString();
    //let son = sontarih.toDateString();
    return this.http.get<Response<HaftaDto[]>>(this.apiUrl + '/haftalar', {
      params: {
        'tip': tip,
        'ilktarih': ilktarih,    
        'sontarih': sontarih
      }
    });
  }

 
  
  
}
