type Nullable<T> = T | null;
export class Sinav {
    id: number=0;    
    akademisyen_tc: string='';    
    akademik_takvim_id: number=0;    
    donem_id: number = 0;
    ders_id: number=0;
    birim_id: number=0; 
    bolum_id: number=0;
    program_id: number=0;
    ogrenim_turu: string='';
    sinav_tipi: string='';
    sinav_derslik_id: number=0;
    sinav_tarihi?:  Nullable<Date> = null;
    sinav_baslama_saati: string='';
    ogrenci_sayisi: number=0;
    aktif: string='';   
    idy_tc: string='';
    idt?:  Nullable<Date> = null;
    sdy_tc: string='';
    sdt?:  Nullable<Date> = null;   
    birim_adi: string='';
    bolum_adi: string='';
    ders_adi: string=''; 
}