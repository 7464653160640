import { Component, OnInit, ViewChild } from '@angular/core';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { GenelService } from 'src/app/services/genel.service';
import { BirimService } from 'src/app/services/birim.service';
import { YetkiService } from 'src/app/services/yetki.service';

import * as XLSX from 'xlsx';
import { AkademisyenService } from 'src/app/services/akademisyen.service';
import { AkademisyenGuncelleDto, AkademisyenGuncelleParam, AkademisyenlerDto, AkademisyenParam } from 'src/app/models/Akademisyenler.model';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs';
import { ValidationService } from 'src/app/services/validation.service';


@Component({
  selector: 'akademisyenguncelle',
  templateUrl: './akademisyenguncelle.component.html',
  styleUrls: ['./akademisyenguncelle.component.css']
})

export class AkademisyenGuncelleComponent implements OnInit {
  
  constructor(private akademisyenService: AkademisyenService, 
              private birimService: BirimService, 
              public yetkiService: YetkiService, 
              private validationService: ValidationService, 
              private genelService: GenelService
              ) {
    this.service = akademisyenService;
   }

  yukleniyor=false;
  kaydediliyor = false;
  hatalar: any = {};
  service: AkademisyenService;  
  akademisyenDto: AkademisyenlerDto[] = [];
  akademisyenGuncelleDto: AkademisyenGuncelleDto[] = [];
  akademisyenParam: AkademisyenParam = new AkademisyenParam();
  guncelleParam: AkademisyenGuncelleParam = new AkademisyenGuncelleParam();
  birimListDto: BirimlerDto[] = [];
  birimParam: BirimParam = new BirimParam();
  birimDto: any[] = [];
  bolumDto: any[] = [];
  programDto: any[] = [];
  birimID: number = -1;
  bolumID: number = -1;
  abdID: number = 0;
  personelTC: string = '';  
  akademisyenTC: string = '';
  akademisyenbirimID: number = 0;
  turler:any[]=[];

  birimHata: string | null = '';
  bolumHata: string | null = '';
  tcHata: string | null = '';

  ngOnInit(): void {
    this.BirimleriGetir();       
  } 
 
  GetListByAkademisyenTC(akademisyentc: string = '') {        
    let hatatc = akademisyentc == '' ? 'Lütfen TC Kimlik No Giriniz.' : null;    
    this.tcHata = hatatc;    
    if (!this.tcHata) {      
      this.yukleniyor = true;
      this.akademisyenParam.kadrobirimid = 0;
      this.akademisyenParam.unvanid = 0;
      this.akademisyenParam.adsoyad = '';
      this.akademisyenParam.personelid = 0;
      this.akademisyenParam.personeltc = akademisyentc;
      this.akademisyenTC = ''; 
      this.akademisyenbirimID = 0;
      this.service.getAkademisyenData(this.akademisyenParam).subscribe(respone => {
        this.akademisyenDto = respone.data; 
        this.akademisyenDto.forEach(e => {       
          this.akademisyenTC = e.tc; 
          this.akademisyenbirimID = e.kadrobirimid;                         
        }); 
        this.yukleniyor = false;        
      });
    }
  }

  Guncelle() {   
    Swal.fire({
      title: 'Bu akademisyene ait birimi güncellemek istediğinize emin misiniz ?',
      text: 'GÜNCELLE İŞLEMİ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Güncelle',
      cancelButtonText: 'Vazgeç',
    }).then((result) => {
      if (result.value) {
        this.BirimGuncelle();            
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Vazgeçildi", "Güncelleme yapılmadı", 'error')
      }
    })              
  }

  BirimGuncelle() {
    var secbirimID : number = 0;
    let hataBirim = this.birimID <= 0 ? 'Lütfen Birim Seçiniz.' : null;
    let hataBolum = this.bolumID <= 0 ? 'Lütfen Bölüm Seçiniz.' : null;  
    let hatatc = this.akademisyenTC == '' ? 'Akademisyeni getirmeden güncelleme yapamazsınız.' : null;    
    if (this.birimID > 0) {
      hataBirim = this.akademisyenbirimID != this.birimID ? 'Farklı bir birimdeki akademisyenle ilgili işlem yapmaya çalışıyorsunuz. Kadro aktarımı için lütfen Personel Daire Başkanlığı ile irtibata geçiniz...' : null;
    }

    this.birimHata = hataBirim;   
    this.bolumHata = hataBolum;  
    this.tcHata = hatatc;      
    if ((!this.birimHata) && (!this.bolumHata) && (!this.tcHata)) { 

      if (this.abdID != 0) {          secbirimID = this.abdID;  }
      else if (this.bolumID > 0) {   secbirimID = this.bolumID;}
      else if (this.birimID > 0) {   secbirimID = this.birimID;} 
      
      this.yukleniyor = true;
      this.hatalar = {};
      this.guncelleParam.tc = this.akademisyenTC;
      this.guncelleParam.birim_id = secbirimID; 
      this.service.AkademisyenBirimGuncelle(this.guncelleParam).pipe(finalize(() => {
        this.yukleniyor = false;
      })).subscribe({
        next: res => {
          if (res?.message && res?.message != "" ) {this.yetkiService.success(res?.message,1)};
          this.GetListByAkademisyenTC(this.akademisyenTC);
        },
        error: err => {
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          if (err.error.Errors == undefined ||err.error.Errors == null ||err.error.Errors?.length==0){
            if (err.error?.message && err.error?.message != "" ) {this.yetkiService.error(err.error?.message,1)};
            if (err.error?.Message && err.error?.Message != "" ) {this.yetkiService.error(err.error?.Message,1)};
          }
        }
      });  
    }
  }

  BirimleriGetir() {
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19]; //2,6,10,19 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul 
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      this.birimDto = respone.data;
      this.birimID = -1;
      this.bolumID = -1;
      this.abdID = 0;          
      this.programDto = [];   
    });
  }

  BolumGetir() {
    this.birimParam.ustbirimid = this.birimID;//item.target.value;
    this.birimParam.tip = 'Bolum';
    this.birimParam.birimtur = []; 
    this.birimParam.durum = [];
    this.programDto = [];
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      if (this.birimID != -1) {
        if (respone.data.length > 0) {
          this.bolumDto = respone.data;                              
        } else {
          this.bolumDto = [];
          this.programDto = [];                     
        }         
      }
      else {           
        this.bolumDto = [];
        this.programDto = [];                
      } 
      this.bolumID = -1;
      this.abdID = 0; 
    });    
  }

  AnabilimdaliGetir() {
    this.birimParam.ustbirimid = this.bolumID;//item.target.value;
    this.birimParam.tip = 'Program';
    this.birimParam.birimtur = []; 
    this.birimParam.durum = [];
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      if (this.bolumID != -1) {
        if (respone.data.length > 0) {          
          this.programDto = respone.data;
        } else {
          this.programDto = [];          
          this.abdID = 0;         
        }        
      }
      else {       
        this.programDto = [];
        this.abdID = 0;      
      }
    });
  }


}
