import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { BirimService } from 'src/app/services/birim.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { triggerCustomEvent } from 'src/assets/js/components/util';
import * as XLSX from 'xlsx';


@Component({
  selector: 'birim',
  templateUrl: './birim.component.html',
  styleUrls: ['./birim.component.css']
})

export class BirimComponent implements OnInit {
  
  constructor(private birimService: BirimService, public yetkiService: YetkiService) {
    this.service = birimService;
   }
  
  yukleniyor=false;
   
  service: BirimService;  
  birimListDto: BirimlerDto[] = [];
  birimParam: BirimParam = new BirimParam();
  birimDto: BirimlerDto[] = [];
  bolumDto: any[] = []; 
  bolumDurum: boolean = false;  
  buttonCaption: string='';    
  birimID: number = -1;
  bolumID: number = -1;  

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.BirimleriGetir();    
  }

 
  GetListByBirimID() {
    var secbirimID : number = 1;
    let turler : any[]=[];

    if (this.bolumID > 0)      {   
      secbirimID = this.bolumID;
      this.birimParam.tip = 'Program';
    }
    else if (this.birimID > 0) {   
      secbirimID = this.birimID;
      this.birimParam.tip = 'Bolum';
    } 

    if (secbirimID == 1) { 
      turler = [2,6,10,19,44,20];
      this.birimParam.tip = 'Birim';
    }
    this.birimParam.ustbirimid = secbirimID;
    this.birimParam.birimtur = turler;
    this.birimParam.durum = [];
    this.yukleniyor = true;
    this.service.getAllBirimList(this.birimParam).subscribe(respone => {
      this.birimListDto = respone.data; 
      this.yukleniyor = false;        
    });
  }

  BirimleriGetir() {      
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19,44,20]; //2,6,10,19,44,20 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul, Koordinatörlük, Formasyon
    this.service.getAllBirimList(this.birimParam).subscribe(respone => {
      this.birimDto = respone.data;
      this.birimListDto = respone.data;
      this.buttonCaption = 'Üst Birimleri Listele';
    });
  }

  BolumGetir() {    
    this.birimParam.ustbirimid = this.birimID;//item.target.value;   
    this.birimParam.tip = 'Bolum';
    this.birimParam.birimtur = [];   
    this.birimParam.durum = []; 
   // console.log(this.birimParam.ustbirimid, this.birimParam.birimtur);
    this.service.getAllBirimList(this.birimParam).subscribe(respone => {
      if (this.birimID != -1) {
        if (respone.data.length > 0) {
          this.bolumDto = respone.data;
          this.bolumDurum = true;                    
        } else {
          this.bolumDto = [];
          this.bolumDurum = false;            
        }        
        this.buttonCaption = 'Bölümleri Listele';
      }
      else {        
        this.bolumDurum = false;        
        this.bolumDto = [];        
        this.buttonCaption = 'Üst Birimleri Listele';    
      }
      this.bolumID = -1;  
    });    
  }

  AnabilimdaliGetir() {    
      if (this.bolumID > 0) {        
        this.buttonCaption = 'Anabilim Dallarını Listele';
      }
      else {        
        this.buttonCaption = 'Bölümleri Listele';
      }   
  }

  exportexcel(fileName: string,  tableName: string): void {    
    var element = document.getElementById(tableName); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
