import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResmiTatil } from '../models/ResmiTatil.model';
import { Response } from '../models/response.model';
@Injectable({
  providedIn: 'root'
})
export class ResmiTatilService {

  apiUrl = '/api/resmiTatil'

  constructor(private http: HttpClient) { }

  getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<ResmiTatil[]>> {
    return this.http.get<Response<ResmiTatil[]>>(this.apiUrl + '/getall', {
      params: {
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
  }

  getById(id: number): Observable<Response<ResmiTatil>> {
    return this.http.get<Response<ResmiTatil>>(this.apiUrl + '/getbyid?id=' + id);
  }

  add(resmiTatil: ResmiTatil): Observable<Response<ResmiTatil>> {  
    if (resmiTatil.id > 0) {
      return this.http.post<Response<ResmiTatil>>(this.apiUrl + "/update", resmiTatil)
    }
    else {
      return this.http.post<Response<ResmiTatil>>(this.apiUrl + "/add", resmiTatil)
    }
  }

  delete(resmiTatil: ResmiTatil): Observable<Response<ResmiTatil>> {
    return this.http.post<Response<ResmiTatil>>(this.apiUrl + '/delete', resmiTatil)
  }
}
