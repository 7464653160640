import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { ResmiTatil } from 'src/app/models/ResmiTatil.model';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { ResmiTatilService } from 'src/app/services/resmitatil.service';
import { BirimService } from 'src/app/services/birim.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'resmitatil-detay',
  templateUrl: './resmitatil-detay.component.html',
  styleUrls: ['./resmitatil-detay.component.css']
})
export class ResmiTatilDetayComponent implements OnInit {

  constructor(private resmiTatilService: ResmiTatilService, 
              private birimService: BirimService, 
              private validationService: ValidationService, 
              private route: ActivatedRoute, 
              private formBuilder: FormBuilder,
              public yetkiService : YetkiService,
              private router: Router
              ) { }

  detayForm = this.formBuilder.group(new ResmiTatil());
  yeni = true;
  resmiTatil: ResmiTatil = new ResmiTatil();
  birimListDto: BirimlerDto[] = [];
  birimParam: BirimParam = new BirimParam();
  birimDto: any[] = [];
  yukleniyor = false;
  kaydediliyor = false;
  hatalar: any = {};

  ngOnInit(): void {
    this.BirimleriGetir();
    this.detayForm.value.yarim_gun = false;
    this.route.params.subscribe(params => {
      if (params['id'] != 'yeni') {
        this.getById(params['id']);
      }
    });
  }

  BirimleriGetir() {
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19,44,20]; //2,6,10,19,44,20 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul, Koordinatörlük, Formasyon  
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      this.birimDto = respone.data;      
      this.detayForm.value.birim_id = 0;  
    });
  }

  getById(id: number) {
    this.yukleniyor = true;
    this.resmiTatilService.getById(id).subscribe(res => {
      this.resmiTatil = res.data;
      this.yeni = false;
      this.detayForm.patchValue(res.data);       
      this.yukleniyor = false;
    });
  }

  kaydet() {
    this.kaydediliyor = true;
    this.hatalar = {};
    var resmiTatil = this.detayForm.value;
    console.log(resmiTatil);      
   
    const datepipe: DatePipe = new DatePipe('en-US');
    let gunTarih = datepipe.transform(resmiTatil.gun_tarih, 'yyyy-MM-ddTHH:mm');
    resmiTatil.gun_tarih = gunTarih; 

    this.resmiTatilService.add(resmiTatil).pipe(finalize(() => {
      this.kaydediliyor = false;
    })).subscribe({
      next: res => {
        if (res?.message && res?.message != "" ) {this.yetkiService.success(res?.message,1)};
        this.router.navigateByUrl('/resmitatil')
      },
      error: err => {
        this.hatalar = this.validationService.hatalar(err.error.Errors);
        if (err.error.Errors == undefined ||err.error.Errors == null ||err.error.Errors?.length==0){
          if (err.error?.message && err.error?.message != "" ) {this.yetkiService.error(err.error?.message,1)};
          if (err.error?.Message && err.error?.Message != "" ) {this.yetkiService.error(err.error?.Message,1)};
        }
      }
    });
    
  }


}
