import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { DersliklerDto } from 'src/app/models/Derslikler.model';
import { DerslikService } from 'src/app/services/derslik.service';
import { BirimService } from 'src/app/services/birim.service';
import { YetkiService } from 'src/app/services/yetki.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'derslik',
  templateUrl: './derslik.component.html',
  styleUrls: ['./derslik.component.css']
})

export class DerslikComponent implements OnInit {
  
  constructor(private derslikService: DerslikService, 
              private birimService: BirimService, 
              public yetkiService: YetkiService) {
    this.service = derslikService;

   }

  yukleniyor=false;
   
  service: DerslikService;  
  derslikDto: DersliklerDto[] = [];
  birimParam: BirimParam = new BirimParam();
  birimDto: BirimlerDto[] = [];    
  birimID: number = -1;

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {
    this.BirimleriGetir();  
  }

  GetListByDerslik() {
    var secbirimID : number = 1;
    let turler : any[]=[];

    if (this.birimID != -1) {   secbirimID = this.birimID;} 

    if (secbirimID == 1) { turler = [2,6,10,19];}   
    this.yukleniyor = true;
    this.service.getAll(secbirimID).subscribe(respone => {
      this.derslikDto = respone.data; 
      this.yukleniyor = false;        
    });
  }

  BirimleriGetir() {      
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19]; //2,6,10,19 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul 
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
      this.birimDto = respone.data;      
    });
  }
  
  exportexcel(fileName: string,  tableName: string): void {    
    var element = document.getElementById(tableName); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

}
