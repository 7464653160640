export class DersProgramDto {   
    id: number = 0;
    akademikTakvimID: number = 0;
    donemID: number = 0;
    donemAdi: string = '';
    fakulteID: number = 0;  
    fakulteAdi: string = '';
    bolumID: number = 0; 
    bolumAdi: string = '';
    programAdi: string = '';
    programID: number = 0;
    sinif: string = '';
    subeID: number = 0; 
    subeAdi: string = '';
    subeKisaAdi: string = '';
    dersID: number = 0; 
    dersAdi: string = '';
    dersTuru: string = '';
    dersKisaAdi: string = '';
    baslamaTarihi: Date = new Date();    
    bitisTarihi: Date = new Date();   
    gunNo: number = 0; 
    kacSaat: number = 0;
    dersTipi: string = '';
    baslamaSaati: string = '';
    bitisSaati: string = '';
    normalBitisSaati: string = '';
    blokDers: boolean = false;
    obsHarici: boolean = false;
    lokasyonID: number = 0;  
    derslikID: number = 0;  
    derslikAdi: string = '';
    derslikKisaAdi: string = '';
    bagliDersProgramiID: number = 0;    
    personelID: number = 0;
    akademisyenTC: string = '';
    akademisyenID: number = 0;
    akademisyenAdiSoyadi: string = '';
    renk: string = '';
    tarih: Date = new Date();   
    ek_Aciklama: string = '';
    titletext: string = '';
    ogrenimturu: string = '';
}   