<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-calculator me-2 text-success" style="font-size: large;"></i>Toplu Dönem Uzatma
                        Ekranı
                        <!-- <span>
                            &nbsp;&nbsp;
                            <i class="fas fa-calendar-alt text-success fs-3"></i>
                            Diğer
                        </span> -->

                    </span>
                    <!-- <span class="text-muted fs-7 fw-bold mt-2">Ek bilgileri belirtiniz.</span> -->
                </h1>
            </div>
            <!-- <div class="col-md-6" style="text-align: right;">  
            </div> -->  
        </div>
    </div>
    <form [formGroup]="listForm">
        <div class="post d-flex flex-column-fluid" id="bl_post">
            <div id="bl_content_container" class="container-fluid">
                <div class="card col-4 offset-4" style="border-top: 3px #77c2e9 solid; ">
                    <div class="card-body" style="padding:1.5rem">
                        <loading [loader]="kaydediliyor">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-floating mb-5">
                                        <span class="card-title text-danger fs-6" style="text-align: left;">
                                            Bir sonraki dönem başlangıcına kadar DANIŞMANLIK, UZMANLIK ALAN ve ASİSTAN EĞİTİMİ derslerinin uzatılmasını sağlar.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-floating mb-5">
                                        <select class="form-select" id="birimID" [(ngModel)]="secilibirim"
                                            name="birimID" [ngModelOptions]="{standalone: true}" (ngModelChange)="BolumGetir($event)">
                                            <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                            <option *ngFor="let item of birimDto" [ngValue]="item">
                                                {{item.birimadi}}
                                            </option>
                                        </select>
                                        <label style="color: #009ef7;" class="fw-bold" for="birimID">Birim Adı</label>
                                        <span class="form-text text-danger fs-6" *ngIf="birimHata">{{birimHata}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-floating mb-5">
                                        <select class="form-select" id="bolumID" [(ngModel)]="secilibolum" name="bolumID"
                                            [ngModelOptions]="{standalone: true}" (ngModelChange)="BolumSecim($event)">
                                            <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                            <option *ngFor="let item of bolumDto" [ngValue]="item">
                                                {{item.birimadi}}
                                            </option>
                                        </select>
                                        <label style="color: #009ef7;" class="fw-bold" for="bolumID">Bölüm Adı</label>                                        
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-floating mb-5">
                                        <select class="form-select" id="akademisyenID"
                                            [ngModelOptions]="{standalone: true}" name="akademisyenID"
                                            [(ngModel)]="seciliakademisyen"
                                            (ngModelChange)="AkademisyenBilgiVer($event)">
                                            <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                            <option *ngFor="let item of akademisyenDto" [ngValue]="item">
                                                {{item.ad}} {{item.soyad}}
                                            </option>
                                        </select>
                                        <label style="color: #009ef7;" class="fw-bold" for="akademisyenID">Akademisyen
                                            Adı</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-floating mb-5 ">
                                        <select class="form-select" id="donemID" [(ngModel)]="secilidonem" name="donemID" 
                                            [ngModelOptions]="{standalone: true}"  (ngModelChange)="DonemSecim($event)">
                                            <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                            <option *ngFor="let item of akademikTakvimDto" [ngValue]="item">
                                                {{item.birimAdi}} {{item.donemAdi}}
                                            </option>
                                        </select>
                                        <label style="color: #009ef7;" class="fw-bold" for="donemID">Dönem</label>
                                        <span class="form-text text-danger fs-6" *ngIf="donemHata">{{donemHata}}</span>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-left">
                                    <div *ngIf="yetkiService.yetkiVar(['EkDers.TopluDonemUzat'])" class="form-floating mb-5" style="vertical-align: middle;">
                                        <button 
                                            class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                            (click)="TopluDonemUzat()" style="color: #009ef7;">
                                            <i class="fas fa-calculator text-white"></i>
                                            Toplu Dönem Uzat
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </loading>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>