<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="far fa-snowflake me-2 text-success" style="font-size: large;"></i>Resmi Tatil Düzenle
                    </span>
                </h1>
            </div>
            <div class="d-flex align-items-center gap-2 gap-lg-3">
            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" class="container-fluid">
            <loading [loader]="yukleniyor">
                <form class="form" [formGroup]="detayForm" (submit)="kaydet()">
                    <div class="card col-4 offset-4" style="border-top: 2px #77c2e9 solid; ">
                        <div class="card-header">
                            <h3 class="card-title" *ngIf="yeni">
                                Yeni Resmi Tatil Ekle
                            </h3>
                            <h3 class="card-title" *ngIf="!yeni">
                                {{ resmiTatil.adi}} Düzenle
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-floating mb-5">
                                        <select formControlName="birim_id" class="form-select" id="birim_id"
                                            name="birim_id">
                                            <option [ngValue]=0>Lütfen Seçiniz... </option>
                                            <option *ngFor="let item of birimDto" [ngValue]="item.birimid">
                                                {{item.birimadi}}
                                            </option>
                                        </select>
                                        <label style="color: #009ef7;" class="fw-bold" for="birim_id">Birim Adı</label>
                                        <div class="text-danger" *ngFor="let hata of hatalar['birim_id']">
                                            {{hata}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-floating mb-5">
                                        <input formControlName="adi" type="text" class="form-control" id="adi"
                                            placeholder="Tatil Adı" name="adi" />
                                        <label style="color: #009ef7;" class="fw-bold" for="adi">Tatil Adı</label>
                                        <div class="text-danger" *ngFor="let hata of hatalar['adi']">
                                            {{hata}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12">
                                    <div class="form-floating mb-5">
                                        <input formControlName="gun_tarih" type="datetime-local"
                                            class="form-control datepicker" id="gun_tarih" placeholder="gun_tarih"
                                            name="gun_tarih"
                                            [value]="this.detayForm.value.gun_tarih | date:'yyyy-MM-dd HH:mm'"
                                            style="border-color: #8fb9d5;" />
                                        <label style="color: #009ef7;" class="fw-bold" for="gun_tarih">Tatil
                                            Tarihi</label>
                                        <div class="text-danger" *ngFor="let hata of hatalar['gun_tarih']">
                                            {{hata}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row card-footer  ">
                                <div class="col-6 text-left">
                                    <button type="button" routerLink="/resmitatil" id="kt_modal_new_target_cancel"
                                        class="btn btn-danger btn-hover-rise me-3">
                                        <i class="far fa-arrow-alt-circle-left"></i>Geri </button>
                                </div>
                                <div class="col-6 text-end">
                                    <submit-button [loader]="kaydediliyor"></submit-button>
                                    <!-- <submit-button 
                                    [disabled]="!yetkiService.yetkiVar(['KartCihaz.Ekle','KartCihaz.Degistir'])"
                                    [loader]="kaydediliyor"></submit-button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </loading>
        </div>
    </div>
</div>