import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Sinav } from '../models/Sinav.model';
import { Response } from '../models/response.model';
@Injectable({
  providedIn: 'root'
})
export class SinavService {

  apiUrl = '/api/sinav'

  constructor(private http: HttpClient) { }

  getAll(akademisyen_tc: string = '', donem: number = 0): Observable<Response<Sinav[]>> {  
    return this.http.get<Response<Sinav[]>>(this.apiUrl + '/getall?akademisyen_tc=' + akademisyen_tc + '&aktifDonem=' + donem);
  }

  getById(id: number): Observable<Response<Sinav>> {    
    return this.http.get<Response<Sinav>>(this.apiUrl + '/getbyid?id=' + id);
  }

  add(sinav: Sinav): Observable<Response<Sinav>> {  
    //console.log("servis:" +sinav);    
    if (sinav.id > 0) {
      return this.http.post<Response<Sinav>>(this.apiUrl + "/update", sinav)
    }
    else {
      return this.http.post<Response<Sinav>>(this.apiUrl + "/add", sinav)
    }
  }

  delete(sinav: Sinav): Observable<Response<Sinav>> {
    return this.http.post<Response<Sinav>>(this.apiUrl + '/delete', sinav)
  }
}
