export class RaporTelafiDto {    
    akademisyen_tc:string='';
    akademisyen_adsoyad:string='';
    unvan_id: number=0;
    unvan_adi:string='';
    kadro_birim_id:number=0;
    kadro_birim_adi:string='';
    iptal_ders_id:number=0;
    iptal_ders_adi:string='';
    birim_id: number=0; 
    birim_adi:string='';
    bolum_id: number=0;
    bolum_adi:string='';
    ogrenim_turu: string='';
    iptal_ders_tipi: string='';
    iptal_ders_tarihi?: Date = new Date();
    iptal_ders_saat: number=0;  
    telafi_tarihi?: Date = new Date();
    baslama_saati:string='';
    ders_saati_sayisi?:number=0;
    derslik_id:number=0;    
}

export class RaporTelafiDtoParam {    
    birim_id: string = '0';
    bolum_id: string = '0';    
    tarih1: string = '';    
    tarih2: string = '';          
}

export class RaporSinavDto {    
    akademisyen_tc:string='';
    akademisyen_adsoyad:string='';
    unvan_id: number=0;
    unvan_adi:string='';
    kadro_birim_id:number=0;
    kadro_birim_adi:string='';
    ders_id:number=0;
    ders_adi:string='';
    birim_id: number=0; 
    birim_adi:string='';
    bolum_id: number=0;
    bolum_adi:string='';
    ogrenim_turu: string='';
    sinav_tipi: string='';    
    sinav_tarihi?: Date = new Date();
    sinav_baslama_saati:string='';
    ogrenci_sayisi:number=0;
    sinav_yuku:number=0;
    sinav_derslik_id:number=0;    
}

export class RaporSinavDtoParam {    
    birim_id: string = '0';
    bolum_id: string = '0';    
    tarih1: string = '';    
    tarih2: string = '';        
    sinav_tipi: string='';  
    ogrenim_turu: string='';      
}

export class RaporDigerUniversiteDto {    
    akademisyen_tc:string='';
    akademisyen_adsoyad:string='';
    unvan_id: number=0;
    unvan_adi:string='';
    kadro_birim_id:number=0;
    kadro_birim_adi:string='';
    ders_id:number=0;
    ders_adi:string='';
    birim_id: number=0; 
    birim_adi:string='';    
    ogrenim_turu: string='';
    ders_tipi: string='';    
    ders_saat:number=0; 
}

export class RaporSubeDersDto {    
    akademisyen_tc:string='';
    akademisyen_adsoyad:string='';
    unvan_id: number=0;
    unvan_adi:string='';
    kadro_birim_id:number=0;
    kadro_birim_adi:string='';
    sube_id:number=0;
    sube_adi:string='';
    birim_id: number=0; 
    birim_adi:string=''; 
}

export class RaporFarkDto {    
    akademisyen_tc:string='';
    akademisyen_adi:string='';
    yilhafta:string='';
    gun_tarih?: Date = new Date();
    unvan_id: number=0;    
    idari_gorev_id:number=0;  
    birim_id: number=0; 
    birim_adi:string=''; 
    bolum_id: number=0; 
    program_id: number=0; 
    ders_id: number=0; 
    ders_adi:string=''; 
    ders_tipi:string=''; 
    ders_saat: number=0; 
    durum:string=''; 
    fark_nedeni:string=''; 
    idari_gorevi:string=''; 
}

export class RaporFarkDtoParam {    
    birim_id: string = '0';
    bolum_id: string = '0';   
    program_id: string = '0';  
    tarih1: string = '';    
    tarih2: string = '';        
    akademisyen_tc: string='';      
}

