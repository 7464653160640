<!--begin::Footer-->
<div class="footer py-4 d-flex flex-lg-column mt-auto" id="kt_footer">
    <!--begin::Container-->
    <div
        class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        <!--begin::Copyright-->
        <div class="text-dark order-2 order-md-1">
            <span class="text-muted fw-bold me-1">2022 &copy;</span>
            <a href="https://erbakan.edu.tr/bilgiislem" target="_blank"
                class="text-gray-800 text-hover-primary">Necmettin Erbakan Üniversitesi - Bilgi İşlem Daire Başkanlığı</a>
        </div>
        <!--end::Copyright-->
        <!--begin::Menu-->
      
        <!--end::Menu-->
    </div>
    <!--end::Container-->
</div>
<!--end::Footer-->