import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { GenelService } from 'src/app/services/genel.service';
import { BirimService } from 'src/app/services/birim.service';
import { AkademikTakvimService } from 'src/app/services/akademiktakvim.service';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { AkademisyenService } from 'src/app/services/akademisyen.service';
import { AkademikTakvimDto } from 'src/app/models/AkademikTakvim.model';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs';
import { ValidationService } from 'src/app/services/validation.service';
import { HaftalikDersProgram } from 'src/app/models/HaftalikDersProgram.model';
import { AkademisyenlerDto, AkademisyenParam } from 'src/app/models/Akademisyenler.model';



@Component({  
  selector: 'topludonemuzat',
  templateUrl: './topludonemuzat.component.html',
  styleUrls: ['./topludonemuzat.component.css']
})

export class TopluDonemUzatComponent implements OnInit {
  
  constructor(private cizelgeService: HaftalikDersProgramService, 
              private birimService: BirimService, 
              public yetkiService: YetkiService, 
              private genelService: GenelService, 
              private validationService: ValidationService, 
              private akademiktakvimService: AkademikTakvimService, 
              private akademisyenService: AkademisyenService, 
              private formBuilder: FormBuilder,
              private datepipe: DatePipe
              ) {
    this.service = cizelgeService;
   }

   listForm = this.formBuilder.group({
    donemForm: this.formBuilder.group(new HaftalikDersProgram()),
  });

  donemForm(): FormGroup {
    return this.listForm.get('donemForm') as FormGroup;
  }

  yukleniyor=false;
  kaydediliyor = false;
  hatalar: any = {};
  service: HaftalikDersProgramService;  
  birimParam: BirimParam = new BirimParam();
  birimDto: any[] = [];
  bolumDto: any[] = [];  
  birimID: number = 0;
  bolumID: number = 0;  
  donemID: number = 0;
  akademikTakvimID: number = 0;
  akademikTakvimDto: AkademikTakvimDto[] = [];
  akademisyenDto: any[] = [];  
  akademisyenParam: AkademisyenParam = new AkademisyenParam();
  akademisyenTC: string = '';
  
  birimHata: string | null = '';
  donemHata: string | null = '';

  secilibirim : BirimlerDto |undefined; 
  secilibolum : BirimlerDto |undefined; 
  secilidonem : AkademikTakvimDto |undefined; 
  seciliakademisyen : AkademisyenlerDto |undefined; 
  
  ngOnInit(): void {
    this.BirimleriGetir();        
  } 
 
  TopluDonemUzat() {   
    Swal.fire({
      title: 'Seçilen dönemde seçilen kriterlere göre toplu dönem uzatma yapmak istediğinize emin misiniz ?',
      text: 'TOPLU DÖNEM UZAT İŞLEMİ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Dönem Uzat',
      cancelButtonText: 'Vazgeç',
    }).then((result) => {
      if (result.value) {
        this.DonemUzat();                     
      }
      else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Vazgeçildi", "Dönem uzatma yapılmadı", 'error')
      }
    })              
  }

  DonemUzat() {   
    let hataBirim = this.secilibirim == undefined ? 'Lütfen Birim Seçiniz.' : null;
    let hataDonem = this.secilidonem == undefined ? 'Lütfen Dönem Seçiniz.' : null;
    this.birimHata = hataBirim;    
    this.donemHata = hataDonem;    
    if ((!this.birimHata) && (!this.donemHata)) { 
      this.kaydediliyor = true;
      this.hatalar = {};
      this.service.multiDonemUzat(this.birimID, this.bolumID, this.akademisyenTC, this.donemID).pipe(finalize(() => {
        this.kaydediliyor = false;
      })).subscribe({
        next: res => {
          if (res?.message && res?.message != "" ) {this.yetkiService.success(res?.message,1)};          
        },
        error: err => {
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          if (err.error.Errors == undefined ||err.error.Errors == null ||err.error.Errors?.length==0){
            if (err.error?.message && err.error?.message != "" ) {this.yetkiService.error(err.error?.message,1)};
            if (err.error?.Message && err.error?.Message != "" ) {this.yetkiService.error(err.error?.Message,1)};
          }
        }
      });
    }
  }

  BirimleriGetir() {
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19,44,20]; //2,6,10,19,44,20 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul, Koordinatörlük, Formasyon
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {      
      this.birimDto = respone.data;   
      this.birimID = 0;
      this.bolumID = 0; 
      this.donemID = 0;        
    });
  }  

  BolumGetir(item: BirimlerDto) {  
    this.secilibirim = item;   
    if (item!=undefined){  
      this.birimID = item.birimid;  
      this.birimParam.ustbirimid = item.birimid ?? 0;
      this.birimParam.tip = 'Bolum';
      this.birimParam.durum = [];
      this.birimParam.birimtur = [];
      this.DonemleriGetir(this.birimID);
      this.AkademisyenGetir(this.birimID); 
      this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
        if (item.birimid != 0) {                     
          if (respone.data.length > 0) {
            this.bolumDto = respone.data;                              
          } else {
            this.bolumDto = [];  
          }         
        }
        else {           
          this.bolumDto = [];               
        }            
        this.bolumID = 0;  
        this.birimHata = null;   
      });    
    } 
    else {
      this.bolumDto = [];
      this.bolumID = 0;
      this.birimID = 0; 
      this.donemID = 0;      
    }    
    this.secilidonem = undefined;
    this.secilibolum = undefined; 
  }

  BolumSecim(item: BirimlerDto) {  
    this.secilibolum = item;    
    if (item!=undefined){ 
      this.bolumID = item.birimid;   
      this.AkademisyenGetir(this.bolumID); 
    } 
    else {           
      this.bolumID = 0;  
      this.AkademisyenGetir(this.birimID);
    }     
  } 

  AkademisyenGetir(birimid : number=0) {
    this.akademisyenParam.kadrobirimid = birimid;
    this.akademisyenService.getAllAkademisyenList(this.akademisyenParam).subscribe(respone => {
      this.akademisyenDto = respone.data; 
    });
  }

  AkademisyenBilgiVer(item: AkademisyenlerDto) {  
    this.seciliakademisyen = item;   
    if (item!=undefined){     
      this.akademisyenTC = item.tc;        
    } 
    else {     
      this.akademisyenTC = '';         
    }     
  }  

  DonemSecim(item: AkademikTakvimDto) {  
    this.secilidonem = item;   
    if (item!=undefined){   
      this.donemID = item.donemID;
      this.akademikTakvimID = item.id;      
      this.donemHata = null;
    } 
    else {     
      this.donemID = 0;
      this.akademikTakvimID = 0;     
    }     
  }

  DonemleriGetir(birim_id: number = 0) {   
    this.donemID = 0;
    this.akademiktakvimService.getAkademikTakvim(birim_id).subscribe(respone => {
      this.akademikTakvimDto = respone.data;  

      if (respone.data.length > 0) {
        this.secilidonem = this.akademikTakvimDto[0];
        this.donemID = this.akademikTakvimDto[0].donemID;
        this.akademikTakvimID = this.akademikTakvimDto[0].id;       
      }
      else {
        this.donemID = 0;
        this.akademikTakvimID = 0; 
      }

    });
  }


 

}
