import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { Sinav } from 'src/app/models/Sinav.model';
import { SinavService } from 'src/app/services/sinav.service';
import { ValidationService } from 'src/app/services/validation.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { DatePipe } from '@angular/common';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';
import { DersProgramDerslerDto } from 'src/app/models/Dersler.model';

@Component({
  selector: 'sinav-detay',
  templateUrl: './sinav-detay.component.html',
  styleUrls: ['./sinav-detay.component.css']
})
export class SinavDetayComponent implements OnInit {

  constructor(private sinavService: SinavService, 
              private dersService: HaftalikDersProgramService,
              private validationService: ValidationService, 
              private route: ActivatedRoute, 
              private formBuilder: FormBuilder,
              public yetkiService : YetkiService,
              private router: Router
              ) { }

  detayForm = this.formBuilder.group(new Sinav());
  yeni = true;
  sinav: Sinav = new Sinav();  
  dersDto: DersProgramDerslerDto[] = [];
  yukleniyor = false;
  kaydediliyor = false;
  birim_id: number = 0;
  bolum_id: number = 0;
  program_id: number = 0;
  ders_id: number = 0;
  akademik_takvim_id: number = 0;
  donem_id: number = 0;
  ogrenim_turu: string ='';
  akademisyen_id: number = 0;
  akademisyen_tc: string = '';  
  akademisyen_AdSoyadUnvan: string = '';  
  hatalar: any = {};
  dersHata: string | null = '';
gelen='';
  seciliders : DersProgramDerslerDto |undefined; 

  ngOnInit(): void {    
    this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(qparams => {
        if (qparams['adsoyadunvan']) {              
          this.akademisyen_AdSoyadUnvan = qparams['adsoyadunvan'];   
          this.donem_id = qparams['donem_id'];
        }        
      })

      if (params['tc']) { 
        this.akademisyen_tc = params['tc']; 
        this.DersleriGetir(params['tc']); 
      }

      if (params['id'] != 'yeni') {        
        this.getById(params['tc'], params['id']);
      }
      else{        
        this.detayForm.value.sinav_tipi = 'V';
       this.gelen='V';
      }
      
    });
  }

  getById(akademisyenTC: string, id: number) {
    this.yukleniyor = true; 
    this.sinavService.getById(id).subscribe(res => {
      this.sinav = res.data;
      this.yeni = false;
      this.detayForm.patchValue(res.data);    
      this.DersleriGetir(akademisyenTC, res.data.ders_id); 
      //this.seciliders = this.dersDto.find(p => p.ders_id == this.sinav.ders_id);
      //this.yukleniyor = false;
    });
  }

  DersleriGetir(akademisyenTC: string, dersID: number = 0) {  
    this.yukleniyor = true;   
     this.dersService.getDersProgramDerslerList(akademisyenTC).subscribe(respone => {
      this.dersDto = respone.data;  
      if (respone.data.length > 0 && dersID > 0){
        this.seciliders = respone.data.find(p => p.ders_id == dersID);
        this.yukleniyor = false;
      } 
      if (this.yeni) this.yukleniyor = false;     
    }); 
  }

  DersBilgiVer(item: any) {  
    this.seciliders = item;   
    if (item!=undefined){     
      this.birim_id = item.birim_id;
      this.bolum_id = item.bolum_id;
      this.program_id = item.program_id;
      this.ders_id = item.ders_id;
      this.akademik_takvim_id = item.akademik_takvim_id;
      this.donem_id = item.donem_id;
      this.ogrenim_turu = item.ogrenim_turu;     
      this.dersHata = null;  
    } 
    else {     
      this.birim_id = 0;
      this.bolum_id = 0;
      this.program_id = 0;
      this.ders_id = 0;
      this.akademik_takvim_id = 0;
      this.donem_id = 0;
      this.ogrenim_turu = '';          
    }     
  }  

  kaydet() {
    let hataders = this.seciliders == undefined ? 'Lütfen Ders Adı Seçiniz.' : null;   
    this.dersHata = hataders;    
    if (!this.dersHata) { 
      this.kaydediliyor = true;
      this.hatalar = {};
      var sinav = this.detayForm.value;
      sinav.akademisyen_tc = this.akademisyen_tc;
      sinav.aktif = 'E';
    
      const datepipe: DatePipe = new DatePipe('en-US');
      let sinavtarih = datepipe.transform(sinav.sinav_tarihi, 'yyyy-MM-dd');
      sinav.sinav_tarihi = sinavtarih; 

      sinav.birim_id = this.birim_id;
      sinav.bolum_id = this.bolum_id;
      sinav.program_id = this.program_id;
      sinav.ders_id = this.ders_id;
      sinav.akademik_takvim_id = this.akademik_takvim_id;
      sinav.donem_id = this.donem_id;
      sinav.ogrenim_turu = this.ogrenim_turu;
      console.log(sinav);
      this.sinavService.add(sinav).pipe(finalize(() => {
        this.kaydediliyor = false;
      })).subscribe({
        next: res => {
          if (res?.message && res?.message != "" ) {this.yetkiService.success(res?.message,1)};
          this.router.navigateByUrl('/sinav/'+ this.akademisyen_tc +"?donem_id="+ this.donem_id + "&adsoyadunvan=" + this.akademisyen_AdSoyadUnvan)
        },
        error: err => {
          this.hatalar = this.validationService.hatalar(err.error.Errors);
          if (err.error.Errors == undefined ||err.error.Errors == null ||err.error.Errors?.length==0){
            if (err.error?.message && err.error?.message != "" ) {this.yetkiService.error(err.error?.message,1)};
            if (err.error?.Message && err.error?.Message != "" ) {this.yetkiService.error(err.error?.Message,1)};
          }
        }
      });   
  
    }
  }

}
