import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
//import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { BirimlerDto, BirimParam } from 'src/app/models/Birimler.model';
import { GenelService } from 'src/app/services/genel.service';
import { BirimService } from 'src/app/services/birim.service';
import { DersYukuDto, DersYukuParam } from 'src/app/models/DersYuku.model';
import { HaftalikDersProgramService } from 'src/app/services/haftalikdersprogram.service';
import { YetkiService } from 'src/app/services/yetki.service';
import { AkademikTakvimService } from 'src/app/services/akademiktakvim.service';
import { AkademikTakvimDto } from 'src/app/models/AkademikTakvim.model';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { OgrenimTurlerDto } from 'src/app/models/OgrenimTurler.model';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({  
  selector: 'dersyuku',
  templateUrl: './dersyuku.component.html',
  styleUrls: ['./dersyuku.component.css']
})

export class DersYukuComponent implements OnInit {
  
  constructor(private dersyukuService: HaftalikDersProgramService, 
              private birimService: BirimService, 
              public yetkiService: YetkiService, 
              private genelService: GenelService, 
              private akademiktakvimService: AkademikTakvimService, 
              //private formBuilder: FormBuilder,
              private router: Router,
              private datepipe: DatePipe
              ) {
    this.service = dersyukuService;
   }

  /* listForm = this.formBuilder.group({
    dersyukuForm: this.formBuilder.group(new DersYukuDto()),
  });

  dersyukuForm(): FormGroup {
    return this.listForm.get('dersyukuForm') as FormGroup;
  }
 */
  yukleniyor=false;
  service: HaftalikDersProgramService;  
  dersyukuList: DersYukuDto[] = [];
  dersyukuParam: DersYukuParam = new DersYukuParam();
  birimListDto: BirimlerDto[] = [];
  birimParam: BirimParam = new BirimParam();
  birimDto: any[] = [];
  bolumDto: any[] = [];
  programDto: any[] = [];
  birimID: number = 0;
  bolumID: number = 0;   
  programID: number = 0; 
  birimAdi: string = '';
  bolumAdi: string = '';
  bolumAdiList: string = '';
  birimBolumAd: string = '';
  donemAdi: string = '';
  tarih1: string = '';
  tarih2: string = '';
  ogrenimTur: string = '';   
  ogrenimTurDto: OgrenimTurlerDto[] = [];
  akademikTakvimDto: AkademikTakvimDto[] = [];
  ilktarih : any; //'19.09.2022';
  sontarih : any; //'30.12.2022';
  gridSeclist : any[] = [];
  selectedAll: boolean = false;
  
  dropdownSettings:IDropdownSettings={};
  secilibolumList:any[]=[];
  secilibolumIDList:any[]=[];
  enstituSelect: boolean = false;

  birimHata: string | null = '';
  tarih1Hata: string | null = '';
  tarih2Hata: string | null = '';
  ogrenimHata:  string | null = '';
  buttonyukgoster: boolean = true;
  buttonkbsgoster: boolean = true;

  secilibirim : BirimlerDto |undefined; 
  secilibolum : BirimlerDto |undefined; 
  seciliprogram : BirimlerDto |undefined; 

  @ViewChild('grid') grid?: GridComponent;

  ngOnInit(): void {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'birimid',
      textField: 'birimadi',
      enableCheckAll: true,
      allowSearchFilter:false,
      itemsShowLimit: 1,
      searchPlaceholderText:'Ara',
      selectAllText: "Tümünü seç",
      unSelectAllText: "Seçimi kaldır",
      noDataAvailablePlaceholderText: "Kayıt bulunamadı.",
      noFilteredDataAvailablePlaceholderText:"Kayıt bulunamadı.",
    };

    this.BirimleriGetir();  
    this.DersProgramOgrenimTurlerGetir();   
  } 
 
  GetListByDersYuku() {   
    let hataBirim = this.secilibirim == undefined ? 'Lütfen Birim Seçiniz.' : null;
    let hataTarih1 = this.tarih1 == '' ? 'Lütfen Başlangıç Tarihi Giriniz.' : null;
    let hataTarih2 = this.tarih2 == '' ? 'Lütfen Bitiş Tarihi Giriniz.' : null;
    let hataOgrenim = this.ogrenimTur == '' ? 'Lütfen Öğrenim Türü Seçiniz.' : null;
    this.birimHata = hataBirim;    
    this.tarih1Hata = hataTarih1; 
    this.tarih2Hata = hataTarih2;  
    this.ogrenimHata = hataOgrenim; 
    this.bolumAdiList = '';
    this.secilibolumIDList = [];
    if ((!this.birimHata) && (!this.tarih1Hata) && (!this.tarih2Hata) && (!this.ogrenimHata)) { 
      this.yukleniyor = true;
      //console.log(this.secilibolumList);
      this.dersyukuParam.birim_id = this.birimID.toString();
      this.dersyukuParam.bolum_id = this.bolumID.toString();
      this.dersyukuParam.program_id = this.programID.toString();
      this.dersyukuParam.tarih1 = this.tarih1;
      this.dersyukuParam.tarih2 = this.tarih2; 
      this.dersyukuParam.ogrenim_turu = this.ogrenimTur;
      this.dersyukuParam.bolum_list = this.secilibolumList;
      if (this.secilibolumList.length > 0) { this.secilibolumIDList.push('0');} // Tekli bölüm seçiminde hata verince geçici çözüm üretildi.
      this.secilibolumList.forEach(s => {         
          this.secilibolumIDList.push(s.birimid.toString());  
          this.bolumAdiList = this.bolumAdiList + '-' + s.birimadi;                    
      });           

      this.service.getAllDersYukuList(this.dersyukuParam).subscribe(respone => {
        if (respone.data.length > 0) {
          this.dersyukuList = respone.data; 
          this.buttonyukgoster = !((this.bolumID > 0 || this.secilibolumList.length > 0) && (this.tarih1 != '') && (this.tarih2 != '') && (this.ogrenimTur != ''));
          this.buttonkbsgoster = !((this.programID == 0) && (this.tarih1 != '') && (this.tarih2 != '') && (this.ogrenimTur != ''));          
          if (this.secilibolumList.length > 0)
          {
            this.birimBolumAd = this.birimAdi +' / '+ this.bolumAdiList;
          }
          this.gridSeclist = [];
          this.gridSeclist.push('0'); // Tekli çizelge hata verince geçici çözüm üretildi.
          this.dersyukuList.forEach(e => { 
            if (e.onay == 'O'){
              this.gridSeclist.push(e.akademisyen_tc); 
            }             
          }); 
        }
        else {
          this.dersyukuList = [];     
        }
        this.yukleniyor = false;   
      });
    }
  }

  BirimleriGetir() {
    this.birimParam.ustbirimid = 1;
    this.birimParam.tip = 'Birim';
    this.birimParam.durum = [];
    this.birimParam.birimtur = [2,6,10,19,44,20]; //2,6,10,19,44,20 : Meslek Yüksekokulu, Fakülte, Enstitü, Yüksekokul, Koordinatörlük, Formasyon
    this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {      
      this.birimDto = respone.data;   
      this.birimID = 0;
      this.bolumID = 0;
      this.programID = 0;                           
    });
  }

  BolumGetir(item: BirimlerDto) {  
    this.secilibirim = item;   
    this.secilibolumList = [];
    if (item!=undefined){    
      this.enstituSelect = (item.birimtur_id == 10); // 10 : Enstitü           
      //this.dropdownSettings.singleSelection = !this.enstituSelect;  
      this.birimAdi = item.birimadi; 
      this.birimBolumAd = item.birimadi;  
      this.birimID = item.birimid;  
      this.birimParam.ustbirimid = item.birimid ?? 0;
      this.birimParam.tip = 'Bolum';
      this.birimParam.durum = [];
      this.birimParam.birimtur = [];
      this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
        if (item.birimid != 0) {                     
          if (respone.data.length > 0) {
            this.bolumDto = respone.data;                              
          } else {
            this.bolumDto = [];             
          }         
        }
        else {             
          this.bolumDto = []; 
        }            
        this.bolumID = 0;   
        this.programID = 0;   
        this.programDto = [];
        this.birimHata = null;   
      });    
    } 
    else {
      this.secilibolumList = [];
      this.bolumDto = [];
      this.bolumID = 0;
      this.bolumAdi = '';
      this.programDto = [];   
      this.programID = 0;   
      this.birimID = 0;
      this.birimAdi = '';
      this.birimBolumAd = '';
    }     
  }

  AnabilimdaliGetir(item: BirimlerDto) {  
    this.secilibolum = item;   
    if (item!=undefined){     
      this.bolumAdi = item.birimadi;     
      this.birimBolumAd = this.birimAdi +' / '+ item.birimadi; 
      this.bolumID = item.birimid;  
      this.birimParam.ustbirimid = item.birimid ?? 0;
      this.birimParam.tip = 'Program';
      this.birimParam.durum = [];
      this.birimParam.birimtur = [];      
      this.birimService.getAllBirimList(this.birimParam).subscribe(respone => {
        if (item.birimid != 0) {                     
          if (respone.data.length > 0) {
            this.programDto = respone.data;                              
          } else {               
            this.programDto = [];               
          }         
        }
        else {   
          this.programDto = [];             
        }   
        this.programID = 0;   
      });    
    } 
    else {      
      this.programDto = [];   
      this.programID = 0;         
      this.bolumID = 0;
      this.bolumAdi = '';
      this.birimBolumAd = this.birimAdi;      
    }     
  }
  
  AnaBilimDaliSecim(item: BirimlerDto) {  
    this.seciliprogram = item;    
    if (item!=undefined){     
      this.birimBolumAd = this.birimAdi +' / '+ this.bolumAdi +' / '+ item.birimadi;  
      this.programID = item.birimid; 
    } 
    else {           
      this.programID = 0;        
      this.birimBolumAd = this.birimAdi +' / '+ this.bolumAdi;     
    }     
  } 

  DersProgramOgrenimTurlerGetir() {
    this.service.getDersProgramOgrenimTurlerList().subscribe(respone => {
      this.ogrenimTurDto = respone.data;      
      this.ogrenimTur = '';      
    });
  }
  
  secimlist(event: any, item: any) {
    if (event.currentTarget.checked) {
      var tc = item.hasOwnProperty('akademisyen_tc') ? item.akademisyen_tc : "0"; 
      if (!this.gridSeclist.includes(tc)) {
        this.gridSeclist.push(item.akademisyen_tc);       
      }      
    } else {
      this.gridSeclist.splice(this.gridSeclist.indexOf(item.akademisyen_tc), 1);
      this.selectedAll = false;    
    }       
  }  

  selectAll() {
    if (this.selectedAll == false) {
      for (var i = 0; i < this.dersyukuList.length; i++) {
        if (!this.gridSeclist.includes(this.dersyukuList[i].akademisyen_tc)) {
          this.gridSeclist.push(this.dersyukuList[i].akademisyen_tc);
        }
      }
      this.selectedAll = true;      
    }
    else {
      this.gridSeclist = [];
      this.gridSeclist.push('0'); // Tekli çizelge hata verince geçici çözüm üretildi.
      this.selectedAll = false;    
    }
  }
  
  exportexcel(fileName: string,  tableName: string): void {    
    var element = document.getElementById(tableName); 
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd.mm.yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sayfa1');
    XLSX.writeFile(wb, fileName);
  }

  newTabOpenLink(routerlink: string='', routerID: number=0){
    const url = this.router.serializeUrl(this.router.createUrlTree([routerlink,routerID], 
          { 
            queryParams: {tc_list: this.gridSeclist, 
                birim_adi: this.birimBolumAd, 
                birim_id: this.birimID, 
                program_id: this.programID, 
                tarih_1: this.tarih1, 
                tarih_2: this.tarih2, 
                ogr_tur: this.ogrenimTur,
                bolum_list: this.secilibolumIDList} 
          })
        );

    window.open(url, '_blank');
  } 

}
