<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="fas fa-table me-2 text-success" style="font-size: large;"></i>Ders Program Formu
                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.DersProgram.GunlukForm.ExcelAktar'])" class="col-md-6" style="text-align: right;">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('Ders Program Formu.xlsx','bl_subscriptions_table')" style="color: green;">                    
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button>

            </div>
        </div>
    </div>
    <div class="post d-flex flex-column-fluid" id="bl_post">
        <div id="bl_content_container" class="container-fluid">            
                    <loading [loader]="yukleniyor">
                        <table id="bl_subscriptions_table" style="width:100%; background-color:white;">
                            <tr class="textbaslik">
                                <td colspan="7">{{akademisyen_AdSoyadUnvan}}</td>
                            </tr>
                            <tr class="textbaslik">
                                <td colspan="7">Haftalık Ders Programı</td>
                            </tr>
                            <tr class="textbaslik">
                                <td>Pazartesi</td>
                                <td>Salı</td>
                                <td>Çarşamba</td>
                                <td>Perşembe</td>
                                <td>Cuma</td>
                                <td>Cumartesi</td>
                                <td>Pazar</td>
                            </tr>
                            <tr *ngFor="let item of dersprogramHaftaGunList">
                                <td [innerHTML]="item.pazartesi"></td>
                                <td [innerHTML]="item.sali"></td>
                                <td [innerHTML]="item.carsamba"></td>
                                <td [innerHTML]="item.persembe"></td>
                                <td [innerHTML]="item.cuma"></td>
                                <td [innerHTML]="item.cumartesi"></td>
                                <td [innerHTML]="item.pazar"></td>
                            </tr>
                        </table>
                    </loading>              
        </div>
    </div>
</div>