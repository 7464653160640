import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DersliklerDto } from '../models/Derslikler.model';
import { Response } from '../models/response.model';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class DerslikService {
  apiUrl = '/api/genel'

  constructor(private http: HttpClient) { }
  
  getAll(birimid: number = 0, query: string = '', page: number = 1, rowCount: number = 1000): Observable<Response<DersliklerDto[]>> {
    return this.http.get<Response<DersliklerDto[]>>(this.apiUrl + '/dersliklerList', {
      params: {
        'birimid': birimid, 
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });
    
  }
  
}
