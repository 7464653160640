
export class AkademisyenlerDto {
    personelid:number=0;
    tc:string='';
    ad:string='';
    soyad:string='';
    cinsiyet:string='';
    dogumtarihi: Date = new Date();
    personelTipi:string='';
    personelAltTipi:string='';
    akunvanid:number=0;
    akunvan:string='';
    akunvankisa:string='';
    unvanid:number=0;
    unvan:string='';
    unvankisa:string='';
    kadrobirim:string='';
    kadrobirimuzun:string='';
    kadrobirimid:number=0;
    kadrobolumid:number=0;
    kadroanabirimid:number=0;
    calistigibirimid:number=0;
    calistigibirimidad:string='';
    calistigibirimidaduzun:string='';
    kurummail:string='';
    ceptel:string='';
    kurumsicilno:string='';
    unibastarih:Date = new Date();
    kayitdurumu:string='';
    idarigorev: any[] = [];
    izinler: IzinlerDto[] = [];
    siradurum: number = 0;
    resim: String = '';

    pbsguncelleniyor=false;
}

export class IdariGorevDto {
    unvanid: number=0;
    unvanadi: string='';
    idarigorevisvekaleten?: boolean=false;
    idarigorevbastar: Date = new Date();
    idarigorevbittar: Date = new Date();
    idarigorevunvanseviye: string='';
    birimadi: string='';
    idarigorevIstedviren?: boolean=false;
}

export class IzinlerDto {
    izinbastar: Date = new Date();
    izinbittar: Date = new Date();
    izintipi: string='';
    gorevlendirmeTipi: string='';
    aciklama: string='';
    ekdersalirmi: string='';
}

export class AkademisyenParam {
    kadrobirimid: number = 0;
    calistigibirimid: number = 0;
    unvanid: number = 0;
    adsoyad: string = '';
    personelid: number = 0;
    personeltc: string = '';
    resimdurum: number = 0;
    onay: string = 'H';
}

export class AkademisyenGuncelleDto {
    durum: boolean = false;
    mesaj: string = '';
    data:  string = '';
}

export class AkademisyenGuncelleParam {
    tc: string = '';
    birim_id:  number = 0;
    guncelleyen_tc: string = '';
}

