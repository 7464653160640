export class BirimlerDto {
    birimid:number=0;
    birimadi:string='Lütfen Seçiniz...';
    birimkisaad:string='';
    birimtur_id:number=0;    
    turadi:string='';
    ustbirimadi:string='';    
    ustbirimid:number=0;
    durum_id:number=0;
    durum:string='';
    altbirimler: any[] = [];
}

export class BirimParam {
    aramastr: string = '';
    portalid: number = 0;
    birimid: any[] = [];
    ustbirimid: number = 1;
    durum: any[] = [];
    birimtur: any[] = [];
    altdurumu: number = 0;
    tip: string = '';
}