<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h3 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span><i class="fas fa-tasks text-success me-2" style="font-size: large;"></i><span></span> Anasayfa
                    </span>
                </h3>
            </div>                       
        </div>
    </div>
    <div id="kt_content_container" class="mx-10 justify-content-center">
        <loading [loader]="yukleniyor">
            <div class="row gy-5 g-xl-10">
                <!--begin::Col-->
                <div class="col-xxl-6">
                    <!--begin::Row-->
                    <div class="row gx-5 gx-xl-10">
                        <!--begin::Col-->
                        <div class="col-sm-12 mb-5 mb-xl-10">
                            <!--begin::List widget 1-->
                            <div class="card card-flush h-xl-100"
                                style="border-top: 2px #00ABD8 solid; border-left: 1px #c5d5da solid;">
                                <!--begin::Header-->
                                <div class="card-header pt-5">
                                    <!--begin::Title-->
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="card-label fw-bolder text-dark"><b>Ünvan Bazlı Öğretim Türündeki
                                                Ders
                                                Sayıları</b></span>
                                        <!-- <span class="text-gray-400 mt-1 fw-bold fs-6">İstatislikler ({{yil.getFullYear()}} yılı)</span> -->
                                    </h3>
                                    <!--end::Title-->
                                    <!--begin::Toolbar-->
                                    <div class="card-toolbar">
                                        <span class="svg-icon svg-icon-2">
                                            <i class="fas fa-book text-success" style="font-size: x-large;"></i>
                                        </span>
                                    </div>
                                    <!--end::Toolbar-->
                                </div>
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body pt-0 mt-2">
                                    <!--begin::Tab content-->
                                    <div style="overflow:auto;width: 100%;">
                                        <table class="table table-striped" id="bl_subscriptions_table">
                                            <thead>
                                                <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 "
                                                    style="color:#009ef7;">
                                                    <th class="min-w-125px">Ünvan</th>
                                                    <th class="min-w-100px text-center">(1.Ö)</th>
                                                    <th class="min-w-100px text-center">(2.Ö)</th>
                                                    <th class="min-w-100px text-center">(2.Ö)(TEZSİZ)</th>
                                                    <th class="min-w-100px text-center">(2.Ö)(TEZLİ)</th>
                                                </tr>
                                            </thead>
                                            <tbody class="text-gray-600 fw-bold">
                                                <tr *ngFor="let item of cizelgeRapor.unvanlist"
                                                    [ngStyle]="{'font-weight': item.tanim_adi == 'TOPLAM' ?'bold':'none', color: '#000'}">
                                                    <td>
                                                        {{ item.tanim_adi }}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ item.ders_saat_1}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ item.ders_saat_2}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ item.ders_saat_3}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ item.ders_saat_4}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                    <!--end::Tab content-->
                                </div>
                                <!--end::Body-->
                            </div>
                            <!--end::LIst widget 1-->
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->

                        <!--end::Col-->
                    </div>

                    <div class="row gx-5 gx-xl-10">
                        <!--begin::Col-->
                        <div class="col-sm-12 mb-5 mb-xl-10">
                            <!--begin::List widget 1-->
                            <div class="card card-flush h-lg-100" style="border-left: 1px #c5d5da solid;">
                                <!--begin::Header-->
                                <div class="card-header pt-5" style="border-top: 2px rgb(0, 183, 255) solid; ">
                                    <!--begin::Title-->
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="card-label fw-bolder text-dark"> <b> Birim Bazlı Öğretim Türündeki
                                                Ders
                                                Sayıları</b></span>
                                    </h3>
                                    <!--end::Title-->
                                    <!--begin::Toolbar-->
                                    <div class="card-toolbar">
                                        <span class="svg-icon svg-icon-2">
                                            <i class="fas fa-book text-success me-2" style="font-size: x-large;"></i>
                                        </span>
                                    </div>
                                    <!--end::Toolbar-->
                                </div>
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body pt-0 mt-2">
                                    <!--begin::Tab content-->
                                    <div style="overflow:auto;width: 100%;">
                                        <table class="table table-striped" id="bl_subscriptions_table">
                                            <thead>
                                                <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 "
                                                    style="color:#009ef7;">
                                                    <th class="min-w-125px">Birim</th>
                                                    <th class="min-w-75px text-center">(1.Ö)</th>
                                                    <th class="min-w-75px text-center">(2.Ö)</th>
                                                    <th class="min-w-100px text-center">(2.Ö)(TEZSİZ)</th>
                                                    <th class="min-w-100px text-center">(2.Ö)(TEZLİ)</th>
                                                </tr>
                                            </thead>
                                            <tbody class="text-gray-600 fw-bold">
                                                <tr *ngFor="let item of cizelgeRapor.birimlist"
                                                    [ngStyle]="{'font-weight': item.tanim_adi == 'TOPLAM' ?'bold':'none', color: '#000'}">
                                                    <td>
                                                        {{ item.tanim_adi }}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ item.ders_saat_1}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ item.ders_saat_2}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ item.ders_saat_3}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ item.ders_saat_4}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                    <!--end::Tab content-->
                                </div>
                                <!--end::Body-->
                            </div>
                            <!--end::LIst widget 1-->
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->

                        <!--end::Col-->
                    </div>

                </div>
                <div class="col-xxl-6">
                    <!--begin::Row-->
                    <div class="row gx-5 gx-xl-10">
                        <!--begin::Col-->
                        <div class="col-sm-12 mb-5 mb-xl-10">
                            <div class="card card-flush "
                                style="border-left: 1px #c5d5da solid;border-bottom: 1px #c5d5da solid;">
                                <!--begin::Header-->
                                <div class="card-header pt-5" style="border-top: 2px blue solid;">
                                    <!--begin::Title-->
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="card-label fw-bolder text-dark"> <b> Ücret Tipi Bazlı Ders
                                                Tipindeki
                                                Ders Sayıları</b></span>

                                    </h3>
                                    <!--end::Title-->
                                    <!--begin::Toolbar-->
                                    <div class="card-toolbar">
                                        <span class="svg-icon svg-icon-2">
                                            <i class="fas fa-book text-success me-2" style="font-size: x-large;"></i>
                                        </span>
                                    </div>
                                    <!--end::Toolbar-->
                                </div>
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body pt-0 mt-2">
                                    <!--begin::Tab content-->
                                    <div style="overflow:auto;width: 100%;">
                                        <table class="table table-striped" id="bl_subscriptions_table">
                                            <thead>
                                                <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 "
                                                    style="color:#009ef7;">
                                                    <th class="min-w-125px">Ücret Tipi</th>
                                                    <th class="min-w-100px text-center">Teorik</th>
                                                    <th class="min-w-100px text-center">Uygulama</th>
                                                </tr>
                                            </thead>
                                            <tbody class="text-gray-600 fw-bold">
                                                <tr *ngFor="let item of cizelgeRapor.ucrettiplist"
                                                    [ngStyle]="{'font-weight': item.tanim_adi == 'TOPLAM' ?'bold':'none', color: '#000'}">
                                                    <td>
                                                        {{ item.tanim_adi }}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ item.ders_saat_1}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ item.ders_saat_2}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!--end::Tab content-->
                                </div>
                                <!--end::Body-->
                            </div>
                        </div> <!--end::Col-->
                    </div> <!--end::Row-->

                    <div class="row gx-5 gx-xl-10">
                        <!--begin::Col-->
                        <div class="col-sm-12 mb-5 mb-xl-10">
                            <!--begin::List widget 1-->
                            <div class="card card-flush h-lg-100" style="border-left: 1px #c5d5da solid;">
                                <!--begin::Header-->
                                <div class="card-header pt-5" style="border-top: 2px blue solid; ">
                                    <!--begin::Title-->
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="card-label fw-bolder text-dark"> <b> Haftalık Gün Bazlı Ders
                                                Tipindeki Ders
                                                Sayıları</b></span>
                                    </h3>
                                    <!--end::Title-->
                                    <!--begin::Toolbar-->
                                    <div class="card-toolbar">
                                        <span class="svg-icon svg-icon-2">
                                            <i class="fas fa-book text-success me-2" style="font-size: x-large;"></i>
                                        </span>
                                    </div>
                                    <!--end::Toolbar-->
                                </div>
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body pt-0 mt-2">
                                    <!--begin::Tab content-->
                                    <div style="overflow:auto;width: 100%;">
                                        <table class="table table-striped" id="bl_subscriptions_table">
                                            <thead>
                                                <tr class="text-start fw-bolder fs-7 text-uppercase gs-0 "
                                                    style="color:#009ef7;">
                                                    <th class="min-w-125px">Gün</th>
                                                    <th class="min-w-100px text-center">Teorik</th>
                                                    <th class="min-w-100px text-center">Uygulama</th>
                                                </tr>
                                            </thead>
                                            <tbody class="text-gray-600 fw-bold">
                                                <tr *ngFor="let item of cizelgeRapor.gunlist"
                                                    [ngStyle]="{'font-weight': item.tanim_adi == 'TOPLAM' ?'bold':'none', color: '#000'}">
                                                    <td>
                                                        {{ item.tanim_adi }}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ item.ders_saat_1}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{ item.ders_saat_2}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <!--end::Tab content-->
                                </div>
                                <!--end::Body-->
                            </div>
                            <!--end::LIst widget 1-->
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->

                        <!--end::Col-->
                    </div>
                </div>
            </div>
        </loading>
    </div>