import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OgrencilerDto, OgrenciAkademisyenlerDto } from '../models/Ogrenciler.model';
import { Response } from '../models/response.model';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class OgrenciService {
  apiUrl = '/api/genel'

  constructor(private http: HttpClient) { }
  
  /* getAll(query: string = '', page: number = 1, rowCount: number = 10): Observable<Response<OgrencilerDto[]>> {
    return this.http.get<Response<OgrencilerDto[]>>(this.apiUrl + '/birimlerList', {
      params: {        
        'aramastr': query,
        'sayfasayisi': page,
        'sayfadakisatirsayisi': rowCount
      }
    });      
  } */

  getAllOgrenciList(birimid: number=0, adsoyad: string ='', personelid: number=0, personeltc: string =''): Observable<Response<OgrencilerDto[]>> {
    return this.http.get<Response<OgrencilerDto[]>>(this.apiUrl + '/ogrencilerlist', {
      params: {
        'birimid': birimid,    
        'adsoyad': adsoyad,
        'personelid': personelid,
        'personeltc': personeltc
      }
    });
    
  }  

  getAllOgrenciAkademisyenList(birimid: number=0, adsoyad: string ='', personelid: number=0, personeltc: string =''): Observable<Response<OgrenciAkademisyenlerDto[]>> {
    return this.http.get<Response<OgrenciAkademisyenlerDto[]>>(this.apiUrl + '/ogrenciakademisyenlist', {
      params: {
        'birimid': birimid,    
        'adsoyad': adsoyad,
        'personelid': personelid,
        'personeltc': personeltc
      }
    });
    
  }  
 
  
}
