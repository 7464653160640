<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="far fa-file-alt me-2 text-success" style="font-size: large;"></i>Dersini Şubeye Bölen Akademisyenler Listesi
                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporDersSube.ExcelAktar'])" class="col-md-6" style="text-align: right;">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('Dersini Şubeye Bölenler Raporu.xlsx','bl_subscriptions_table')" style="color: green;">
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button>

            </div>
        </div>
    </div>
    <form [formGroup]="listForm">
        <div class="post d-flex flex-column-fluid" id="bl_post">
            <div id="bl_content_container" class="container-fluid">
                <div class="card" style="border-top: 3px #77c2e9 solid; background-color:white;">                 
                    <loading [loader]="yukleniyor">
                        <div style="overflow:auto;width: 100%;">
                            <table class="table table-striped" id="bl_subscriptions_table">
                                <tr class="textsatir">
                                    <td colspan="6" class="textsatir">
                                        DERSİNİ ŞUBEYE BÖLEN AKADEMİSYENLER LİSTESİ                                          
                                    </td>
                                </tr>
                                <tr class="textsatir">
                                    <td colspan="4">AKADEMİSYEN BİLGİLERİ</td>
                                    <td colspan="2">ŞUBE BİLGİLERİ</td>                                    
                                </tr>
                                <tr class="textsatir text-center">
                                    <td>TC Kimlik No</td>
                                    <td>Ünvan</td>
                                    <td class="min-w-125px">Adı Soyadı</td>
                                    <td class="min-w-125px">Kadro Birimi</td>                                                               
                                    <td class="min-w-125px">Şube Adı</td>
                                    <td class="min-w-125px">Şube Birimi</td>                                   
                                    
                                </tr>

                                <tbody class="text-gray-600 fw-bold">
                                    <tr *ngFor="let item of raporSubeDersList">
                                        <td>
                                            {{ item.akademisyen_tc}}
                                        </td>
                                        <td>
                                            {{ item.unvan_adi}}
                                        </td>
                                        <td>
                                            {{ item.akademisyen_adsoyad}}
                                        </td>
                                        <td>
                                            {{ item.kadro_birim_adi }}
                                        </td>                              
                                        <td>
                                            {{ item.sube_adi}}
                                        </td>
                                        <td>
                                            {{ item.birim_adi}}
                                        </td>   
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </loading>
                </div>
            </div>
        </div>
    </form>
</div>