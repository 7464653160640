<div class="content d-flex flex-column flex-column-fluid">
    <div class="toolbar mb-5">
        <div class="container-fluid d-flex flex-stack">
            <div class="page-title d-flex flex-column align-items-start me-3 mb-5 mb-lg-0">
                <h1 class="d-flex text-dark fw-bolder fs-3 flex-column mb-0">
                    <span>
                        <span class="me-4"></span>
                        <i class="far fa-file-alt me-2 text-success" style="font-size: large;"></i>Sınav Yükü Raporu
                    </span>
                </h1>
            </div>
            <div *ngIf="yetkiService.yetkiVar(['EkDers.RaporSinav.ExcelAktar'])" class="col-md-6" style="text-align: right;">
                <button class="btn btn-sm  btn-shadow-hover btn-secondary fs-6 border border-primary me-4"
                    (click)="exportexcel('Sınav Yuku Raporu.xlsx','bl_subscriptions_table')" style="color: green;">
                    <i class="fas fa-file-excel " style="color: green;"></i>
                    Excel
                </button>

            </div>
        </div>
    </div>
    <form [formGroup]="listForm">
        <div class="post d-flex flex-column-fluid" id="bl_post">
            <div id="bl_content_container" class="container-fluid">
                <div class="card" style="border-top: 3px #77c2e9 solid; ">
                    <div class="card-body" style="padding:1.5rem">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="birimID" [(ngModel)]="secilibirim" name="birimID"
                                        [ngModelOptions]="{standalone: true}" (ngModelChange)="BolumGetir($event)">
                                        <option [ngValue]="undefined">Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of birimDto" [ngValue]="item">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="birimID">Birim Adı</label>
                                    <span class="form-text text-danger fs-6" *ngIf="birimHata">{{birimHata}}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="bolumID" [ngModelOptions]="{standalone: true}"
                                        name="bolumID" [(ngModel)]="bolumID">
                                        <option [ngValue]=0>Lütfen Seçiniz... </option>
                                        <option *ngFor="let item of bolumDto" [ngValue]="item.birimid">
                                            {{item.birimadi}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="bolumID">Bölüm Adı</label>
                                </div>
                            </div>
                            <div class="col-md-4 text-center">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <input type="date" class="form-control" id="tarih1" [(ngModel)]="tarih1"
                                        placeholder="baslangicTarihi" name="tarih1"
                                        [ngModelOptions]="{standalone: true}" style="border-color: #8fb9d5;" />
                                    <label style="color: #009ef7;" class="fw-bold" for="tarih1">Başlangıç Tarihi</label>
                                    <span class="form-text text-danger fs-6" *ngIf="tarih1Hata">{{tarih1Hata}}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <input type="date" class="form-control" id="tarih2" [(ngModel)]="tarih2"
                                        placeholder="bitisTarihi" name="tarih2" [ngModelOptions]="{standalone: true}"
                                        style="border-color: #8fb9d5;" />
                                    <label style="color: #009ef7;" class="fw-bold" for="tarih2">Bitiş Tarihi</label>
                                    <span class="form-text text-danger fs-6" *ngIf="tarih2Hata">{{tarih2Hata}}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-floating mb-5">
                                    <select class="form-select" id="ogrenimTur" [ngModelOptions]="{standalone: true}"
                                        name="ogrenimTur" [(ngModel)]="ogrenimTur">
                                        <option [value]=''></option>
                                        <option *ngFor="let item of ogrenimTurDto" [value]="item.baslik">
                                            {{item.baslik}}
                                        </option>
                                    </select>
                                    <label style="color: #009ef7;" class="fw-bold" for="ogrenimTur">Öğrenim Türü</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="row"  >
                                    <div class="col-lg-6" style="color: black;">
                                        <label
                                            class="form-check form-check-custom form-check-solid form-switch mb-5">
                                            <input type="radio" value="V" [(ngModel)]="sinavTipi"
                                                id="sinav_tipi1" name="sinav_tipi" [ngModelOptions]="{standalone: true}"
                                                class="form-check-input" [checked]="this.sinavTipi == 'V'">
                                            <span class="form-check-label fs-6">Vize
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-lg-6" style="color: black;">
                                        <label
                                            class="form-check form-check-custom form-check-solid form-switch mb-5">
                                            <input type="radio" value="F" [(ngModel)]="sinavTipi"
                                                id="sinav_tipi2" name="sinav_tipi" [ngModelOptions]="{standalone: true}"
                                                class="form-check-input" [checked]="this.sinavTipi == 'F'">
                                            <span class="form-check-label fs-6">Final
                                            </span>
                                        </label>
                                    </div>
                                </div>                                
                            </div>
                            <div class="col-md-4">                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-floating mb-5" style="vertical-align: middle;">
                                    <button
                                        class="btn btn-sm text-white btn-hover-rise btn-shadow-hover btn-primary fs-6 border border-primary me-4"
                                        (click)="GetRaporSinav()" style="color: #009ef7; width: 70%;">
                                        <i class="fas fa-list text-white"></i>
                                        Sınav Raporu Hazırla
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="card-body py-4" style="border-top: #f5c6c6 1px solid; background-color:white; ">
                    <loading [loader]="yukleniyor">
                        <div style="overflow:auto;width: 100%;">
                            <table class="table table-striped" id="bl_subscriptions_table">
                                <tr class="textsatir">
                                    <td colspan="13" class="textsatir">SINAV RAPOR LİSTESİ 
                                        ({{tarih1 | date:'dd.MM.yyyy':'IST' }} - {{tarih2 | date:'dd.MM.yyyy':'IST' }})
                                    </td>
                                </tr>
                                <tr class="textsatir">
                                    <td colspan="4">AKADEMİSYEN BİLGİLERİ</td>
                                    <td colspan="3">SINAV YAPILAN DERS BİLGİLERİ</td>
                                    <td colspan="6" class="textsatir">SINAV(VİZE-FİNAL) BİLGİLERİ</td>
                                </tr>
                                <tr class="textsatir text-center">
                                    <td>TC Kimlik No</td>
                                    <td>Ünvan</td>
                                    <td class="min-w-125px">Adı Soyadı</td>
                                    <td class="min-w-125px">Kadro Birimi</td>
                                    <td>Öğr. Türü</td>
                                    <td class="min-w-125px">Dersin Adı</td>
                                    <td class="min-w-125px">Birimi</td>
                                    <td>Sınav Tipi</td>
                                    <td>Sınav Tarihi</td>
                                    <td>Sınav Saati</td>
                                    <td>Öğrenci Sayısı</td>
                                    <td>Sınav Yükü</td>
                                    <td>İmza</td>
                                </tr>

                                <tbody class="text-gray-600 fw-bold">
                                    <tr *ngFor="let item of raporSinavList">
                                        <td>
                                            {{ item.akademisyen_tc}}
                                        </td>
                                        <td>
                                            {{ item.unvan_adi}}
                                        </td>
                                        <td>
                                            {{ item.akademisyen_adsoyad}}
                                        </td>
                                        <td>
                                            {{ item.kadro_birim_adi }}
                                        </td>
                                        <td>
                                            {{ item.ogrenim_turu }}
                                        </td>
                                        <td>
                                            {{ item.ders_adi}}
                                        </td>
                                        <td>
                                            {{ item.birim_adi}} / {{ item.bolum_adi}}
                                        </td>
                                        <td>
                                            {{ item.sinav_tipi == 'V'? 'Vize' : 'Final' }}
                                        </td> 
                                        <td>{{ item.sinav_tarihi | date:'dd.MM.yyyy':'IST' }}
                                        </td>
                                        <td>
                                            {{ item.sinav_baslama_saati}}
                                        </td>
                                        <td>
                                            {{ item.ogrenci_sayisi}}
                                        </td>
                                        <td>
                                            {{ item.sinav_yuku}}
                                        </td>
                                        <td>
                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </loading>
                </div>
            </div>
        </div>
    </form>
</div>